import classNames from "classnames";
import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "../../../mymodule/ztech-vm";

import Modal from "../../containers/modal.jsx";
import { setjrMode, setNormalMode } from "../../reducers/jrchange";
import { setDestroyJr, setDestroyNormal } from "../../reducers/game-destroy";
import deviceChange, {
    setDeviceTrue,
    setDeviceFalse,
} from "../../reducers/device-change";
import {selectLocale} from '../../reducers/locales';
import {truenewProject,falsenewProject} from '../../reducers/newproject.js';
import {
    activateTargetTab,
    KUKLALAR_TAB_INDEX,
    CIHAZ_TAB_INDEX
} from '../../reducers/target-tab';
import Divider from "../divider/divider.jsx";

import styles from "../library/library.css";

import { FormattedMessage } from "react-intl";
import styleLoder from "./loader.css";
import styleLoder2 from "./horizontal.css";

import topBlock from "./top-block.svg";
import middleBlock from "./middle-block.svg";
import bottomBlock from "./bottom-block.svg";
import robot from "./robot.png";
import robotyan from "./robotyan.png";
import yatayblok from "./yatayblok.svg";
import yataybutton from "./buttonyatay.png";
import beyazyatay from "./beyazyatay.png";
import arrowRightImage from "./arrow-right.svg";
import verticalLargeBlocks from "./vertical-large.svg";
import verticalSmallBlocks from "./vertical-small.svg";
import verticalWhiteBlocks from "./vertical-white.svg";
import zcodelogo from "./logo.png";
import MyWorkspaceClass from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass";
const ReadDeviceData = require('../../../mymodule/ztech-vm/src/util/deviceDataRead');

class VerticalOrHorizontalComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClose",
            "onAddBtnClickVertical",
            "onAddBtnClickHorizontal",
        ]);
        this.state = {
            loaded: false,
        };
    }
    componentDidMount() {
        // Allow the spinner to display before loading the content
        setTimeout(() => {
             this.props.vm.stopAll();
             ReadDeviceData.setRunStep(false);

            this.setState({ loaded: true });
        });
    }
    componentDidUpdate(prevProps, prevState) {}

    handleClose() {
        console.log(this.props);

        this.props.onRequestClose();
    }

      onAddBtnClickVertical() {
      //  alert("vertical");
       // this.props.vm.setEditingTarget(Object.values(this.props.sprites).filter(x => x.isDevice==true)[0].id);
        this.props.activateTargetTab(0);

        ReadDeviceData.setRunStep(false);

        
        var ws = MyWorkspaceClass.getWorkspace();
         ws.clear();

         setTimeout(() => {
          
            this.props.onSetDeviceFalse();
            this.props.onSetNormalchange();
            this.props.onSetDestroyNormal();
            if(this.props.newProject === true){
                this.props.onFalsenewProject();
            }else{
                this.props.onTruenewProject();
            }
           // this.props.onChangeLanguage(document.documentElement.lang);
        }, 100);
        this.props.vm.runtime.clearMonitor();

        
        this.myDeviceSelected('arduinoEsp32');
        setTimeout(() => {
          
            this.props.onRequestClose();
        }, 400);

    
    }

     onAddBtnClickHorizontal() {
        this.props.activateTargetTab(0);

        var ws = MyWorkspaceClass.getWorkspace();
         ws.clear();


        ReadDeviceData.setRunStep(false);

       
        setTimeout(() => {
            this.props.onSetDeviceFalse();
            this.props.onSetJrchange();           
            this.props.onSetDestroyJr();
           // this.props.onChangeLanguage(document.documentElement.lang);
            if(this.props.newProject === true){
                this.props.onFalsenewProject();
            }else{
                this.props.onTruenewProject();
            }
        }, 100);

        this.myDeviceSelected('arduinoEsp32');


        setTimeout(() => {
            this.props.onRequestClose();
            this.props.vm.runtime.setJrMonitorAdd();

        }, 400);

      
    }

    myDeviceSelected (categoryId) {
        //arduinoEsp32
        //this.setState();

        const data = this.props.deviceData.map(device => ({
            rawURL: device.iconURL || deviceIcon,
            ...device
        }));
        
        this.requestLoadDevice(data[0]);
    }

    requestLoadDevice (device) {
        const id = device.deviceId;
        const deviceType = device.type;
        const pnpidList = device.pnpidList;
        const deviceExtensions = device.deviceExtensions;

        if (id && !device.disabled) {
            if (this.props.vm.extensionManager.isDeviceLoaded(id)) {
                this.props.onDeviceSelected(id);
            } else {
                this.props.vm.extensionManager.loadDeviceURL(id, deviceType, pnpidList).then(() => {
                    this.props.vm.extensionManager.getDeviceExtensionsList().then(() => {
                        // TODO: Add a event for install device extension
                        // the large extensions will take many times to load
                        // A loading interface should be launched.
                        this.props.vm.installDeviceExtensions(Object.assign([], deviceExtensions));
                    });
                    this.props.onDeviceSelected(id);
                
                });
            }
        }
    }

    render() {
        console.log("deneme", process.env.REACT_APP_NODE_ENV);

        return (
            <Modal
                fullScreen
                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                <div className={classNames(styleLoder.background)}
                >
                    <div className={classNames(styleLoder.arka)}
                    onClick={() => {
                        this.onAddBtnClickHorizontal();
                        }}
                    >
                        <div className={classNames(styleLoder2.flex)}>
                            <div
                                style={{
                                    width: "60%",
                                    height: "auto",
                                    margin: "auto",
                                    position: "relative",
                                    // transform: 'translateY(-50%)',
                                    maxWidth: "400px",
                                    objectFit: "contain",

                                    // top: '50%',
                                    // left: '50%',
                                    // transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <img
                                    className={styleLoder2.robot}
                                    src={robot}
                                />
                            </div>
                            <div
                                className={styleLoder2.container}
                                onMouseUp={this.onAddBtnClickHorizontal}
                            >
                                <div className={styleLoder2.blockAnimation}>
                                    <img
                                        className={styleLoder2.topBlock}
                                        src={yatayblok}
                                    />
                                    <img
                                        className={styleLoder2.middleBlock}
                                        src={yatayblok}
                                    />
                                    <img
                                        className={styleLoder2.bottomBlock}
                                        src={yatayblok}
                                    />
                                    <img
                                        className={styleLoder2.bottomBlock2}
                                        src={yatayblok}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={styleLoder.yatayButton}                            
                        >
                            <div className={classNames(styleLoder.flexButton)}>
                                <div
                                    className={classNames(
                                        styleLoder.yatayButtonBlocksBack,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <img
                                        className={
                                            styleLoder.yatayButtonBlocksBeyaz
                                        }
                                        src={beyazyatay}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        styleLoder.title,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <FormattedMessage
                                        defaultMessage="Symbol Blocks"
                                        description="Button to get to the Horizontal Blocks"
                                        id="gui.loadingPage.horizontalBtn"
                                    />
                                    </div>

                                <div
                                    className={classNames(
                                        styleLoder.arrow,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <img src={arrowRightImage} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classNames(styleLoder.divider)} />

                    <div className={classNames(styleLoder.logo)}>
                        <img src={zcodelogo} />
                    </div>

                    <div className={classNames(styleLoder.arka)}
                    onClick={() => {
                        this.onAddBtnClickVertical();
                    }}
                    >
                        <div className={classNames(styleLoder.flex)}>
                            <div
                                className={styleLoder.container}
                                onMouseUp={this.onAddBtnClickVertical}
                            >
                                <div className={styleLoder.blockAnimation}>
                                    <img
                                        className={styleLoder.topBlock}
                                        src={verticalLargeBlocks}
                                    />
                                    <img
                                        className={styleLoder.middleBlock}
                                        src={verticalSmallBlocks}
                                    />
                                    <img
                                        className={styleLoder.bottomBlock}
                                        src={verticalLargeBlocks}
                                    />
                                </div>
                            </div>
                            <div>
                                <img
                                    className={styleLoder.robot}
                                    src={robotyan}
                                />
                            </div>
                        </div>

                        <div
                            className={styleLoder.yatayButton}                            
                        >
                            <div className={classNames(styleLoder.flexButton)}>
                                <div
                                    className={classNames(
                                        styleLoder.dikeyButtonBlocksBack,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <img
                                        className={
                                            styleLoder.verticalButtonBlocksWhite
                                        }
                                        src={verticalWhiteBlocks}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        styleLoder.title,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <FormattedMessage
                                        defaultMessage="Word Blocks"
                                        description="Button to get to the Vertical Blocks"
                                        id="gui.loadingPage.verticalBtn"
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        styleLoder.arrow,
                                        styleLoder.flexContainer
                                    )}
                                >
                                    <img src={arrowRightImage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

VerticalOrHorizontalComponent.propTypes = {
    autoClose: PropTypes.bool,
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            rawURL: PropTypes.string,
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

VerticalOrHorizontalComponent.defaultProps = {
    autoClose: true,
    isUnloadble: false,
    filterable: true,
    showPlayButton: false,
};
const mapStateToProps = (state, ownProps) => {
    return {
        vm: state.scratchGui.vm,
        newProject: state.scratchGui.newProject,
        isjr: state.scratchGui.isjr,
        gameDestroy: state.scratchGui.destroyGame,
        sprites: state.scratchGui.targets.sprites,

    };
};
const mapDispatchToProps = (dispatch) => ({
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onSetDestroyJr: () => dispatch(setDestroyJr()),
    onSetDestroyNormal: () => dispatch(setDestroyNormal()),
    onSetDeviceFalse: () => dispatch(setDeviceFalse()),
    onTruenewProject: () => dispatch(truenewProject()),
    onFalsenewProject: () => dispatch(falsenewProject()),
    activateTargetTab: tab => dispatch(activateTargetTab(tab)),

    onUpdateToolboxState: (toolboxXML) => {
        dispatch(updateToolbox(toolboxXML));
    },
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(VerticalOrHorizontalComponent);
