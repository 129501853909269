// Do not edit this file; automatically generated by build.py.
'use strict';

let Blockly = require('../ztech-blocks');

var $jscomp=$jscomp||{};$jscomp.scope={};$jscomp.checkStringArgs=function(a,b,c){if(null==a)throw new TypeError("The 'this' value for String.prototype."+c+" must not be null or undefined");if(b instanceof RegExp)throw new TypeError("First argument to String.prototype."+c+" must not be a regular expression");return a+""};$jscomp.ASSUME_ES5=!1;$jscomp.ASSUME_NO_NATIVE_MAP=!1;$jscomp.ASSUME_NO_NATIVE_SET=!1;
$jscomp.defineProperty=$jscomp.ASSUME_ES5||"function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};$jscomp.getGlobal=function(a){return"undefined"!=typeof window&&window===a?a:"undefined"!=typeof global&&null!=global?global:a};$jscomp.global=$jscomp.getGlobal(this);
$jscomp.polyfill=function(a,b,c,d){if(b){c=$jscomp.global;a=a.split(".");for(d=0;d<a.length-1;d++){var e=a[d];e in c||(c[e]={});c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&$jscomp.defineProperty(c,a,{configurable:!0,writable:!0,value:b})}};
$jscomp.polyfill("String.prototype.repeat",function(a){return a?a:function(a){var b=$jscomp.checkStringArgs(this,null,"repeat");if(0>a||1342177279<a)throw new RangeError("Invalid count value");a|=0;for(var d="";a;)if(a&1&&(d+=b),a>>>=1)b+=b;return d}},"es6","es3");Blockly.Python=new Blockly.Generator("Python");Blockly.Python.addReservedWords("and,as,assert,break,class,continue,def,del,elif,else,except,exec,finally,for,from,global,if,import,in,is,lambda,not,or,pass,print,raise,return,try,while,with,yield,True,False,None,NotImplemented,Ellipsis,__debug__,quit,exit,copyright,license,credits,abs,divmod,input,open,staticmethod,all,enumerate,int,ord,str,any,eval,isinstance,pow,sum,basestring,execfile,issubclass,print,super,bin,file,iter,property,tuple,bool,filter,len,range,type,bytearray,float,list,raw_input,unichr,callable,format,locals,reduce,unicode,chr,frozenset,long,reload,vars,classmethod,getattr,map,repr,xrange,cmp,globals,max,reversed,zip,compile,hasattr,memoryview,round,__import__,complex,hash,min,set,apply,delattr,help,next,setattr,buffer,dict,hex,object,slice,coerce,dir,id,oct,sorted,intern,count");
Blockly.Python.ORDER_ATOMIC=0;Blockly.Python.ORDER_COLLECTION=1;Blockly.Python.ORDER_STRING_CONVERSION=1;Blockly.Python.ORDER_MEMBER=2.1;Blockly.Python.ORDER_FUNCTION_CALL=2.2;Blockly.Python.ORDER_EXPONENTIATION=3;Blockly.Python.ORDER_UNARY_SIGN=4;Blockly.Python.ORDER_BITWISE_NOT=4;Blockly.Python.ORDER_MULTIPLICATIVE=5;Blockly.Python.ORDER_ADDITIVE=6;Blockly.Python.ORDER_BITWISE_SHIFT=7;Blockly.Python.ORDER_BITWISE_AND=8;Blockly.Python.ORDER_BITWISE_XOR=9;Blockly.Python.ORDER_BITWISE_OR=10;
Blockly.Python.ORDER_RELATIONAL=11;Blockly.Python.ORDER_LOGICAL_NOT=12;Blockly.Python.ORDER_LOGICAL_AND=13;Blockly.Python.ORDER_LOGICAL_OR=14;Blockly.Python.ORDER_CONDITIONAL=15;Blockly.Python.ORDER_LAMBDA=16;Blockly.Python.ORDER_NONE=99;Blockly.Python.INDENT="  ";Blockly.Python.firstLoop=!0;
Blockly.Python.init=function(a){Blockly.Python.imports_=Object.create(null);Blockly.Python.customFunctions_=Object.create(null);Blockly.Python.customFunctionsArgName_=Object.create(null);Blockly.Python.libraries_=Object.create(null);Blockly.Python.variables_=Object.create(null);Blockly.Python.setups_=Object.create(null);Blockly.Python.loops_=Object.create(null);Blockly.Python.variableDB_?Blockly.Python.variableDB_.reset():Blockly.Python.variableDB_=new Blockly.Names(Blockly.Python.RESERVED_WORDS_);
Blockly.Python.variableDB_.setVariableMap(a.getVariableMap());a=Blockly.Variables.allVariables(a);for(var b=0;b<a.length;b++)Blockly.Python.variables_[b]=a[b].type===Blockly.LIST_VARIABLE_TYPE?Blockly.Python.variableDB_.getName(a[b].name,Blockly.Variables.NAME_TYPE)+" = []":Blockly.Python.variableDB_.getName(a[b].name,Blockly.Variables.NAME_TYPE)+" = 0"};
Blockly.Python.finish=function(a){var b=[];for(g in Blockly.Python.imports_)b.push(Blockly.Python.imports_[g]);var c=[];for(g in Blockly.Python.customFunctions_)c.push(Blockly.Python.customFunctions_[g]);var d=[];for(g in Blockly.Python.libraries_)d.push(Blockly.Python.libraries_[g]);var e=[];for(g in Blockly.Python.variables_)e.push(Blockly.Python.variables_[g]);var f=[];for(g in Blockly.Python.setups_)f.push(Blockly.Python.setups_[g]);var h=[];for(g in Blockly.Python.loops_)h.push(Blockly.Python.loops_[g]);
var g="";0!==b.length&&(g+=b.join("\n")+"\n\n");0!==c.length&&(g+=c.join("\n")+"\n");0!==d.length&&(g+=d.join("\n")+"\n\n");0!==h.length&&(g+="def repeat():\n"+Blockly.Python.INDENT,g+=h.join("\n"+Blockly.Python.INDENT)+"\n\n");0!==e.length&&(g+=e.join("\n")+"\n\n");0!==f.length&&(g+=f.join("\n")+"\n\n");g+=a+"\n";if(0!==h.length)Blockly.Python.firstLoop&&(g+="while (myvariable.mainrunning):\n"+Blockly.Python.INDENT+"repeat()\n\n",g=g.replace(/\npass\n/g,""));else{a=g.match(/:\n *repeat\(\)\n/g);
for(var k in a)b=a[k].match(/\s+/g)[0].length,g=g.replace(/:\n *repeat\(\)\n/,":\n"+" ".repeat(b)+"pass\n");g=g.replace(/ *repeat\(\)\n/g,"")}delete Blockly.Python.imports_;delete Blockly.Python.libraries_;delete Blockly.Python.variables_;delete Blockly.Python.setups_;delete Blockly.Python.loops_;delete Blockly.Python.customFunctions_;delete Blockly.Python.customFunctionsArgName_;Blockly.Python.variableDB_.reset();Blockly.Python.firstLoop=!0;return g};
Blockly.Python.scrub_=function(a,b){if(null===b||!Blockly.Python.check_(a))return"";var c="";if(!a.outputConnection||!a.outputConnection.targetConnection){var d=a.getCommentText();d&&(c+=Blockly.Python.prefixLines(d,"# ")+"\n");for(var e=0;e<a.inputList.length;e++)a.inputList[e].type==Blockly.INPUT_VALUE&&(d=a.inputList[e].connection.targetBlock())&&(d=Blockly.Python.allNestedComments(d))&&(c+=Blockly.Python.prefixLines(d,"# "))}e=b;null===a.getSurroundParent()&&""!==b&&null!==a.previousConnection&&
"event_whenmicrobitbegin"!==a.getTopStackBlock().type&&("procedures_definition"!==a.type&&"procedures_prototype"!==a.type&&(e=Blockly.Arduino.INDENT+e,""!==c&&(c=Blockly.Arduino.INDENT+c)),e=e.replace(/\n/g,"\n"+Blockly.Arduino.INDENT),e=e.slice(0,e.length-2));a=a.nextConnection&&a.nextConnection.targetBlock();a=Blockly.Python.blockToCode(a);return c+e+a};Blockly.Python.scrubNakedValue=function(a){return a+";\n"};
Blockly.Python.quote_=function(a){a=a.replace(/\\/g,"\\\\").replace(/\n/g,"\\\n").replace(/%/g,"\\%").replace(/'/g,"\\'");return"'"+a+"'"};Blockly.Python.check_=function(a){return null===a.getSurroundParent()&&(null!==a.previousConnection&&null!==a.getTopStackBlock().previousConnection||2===a.getOutputShape()||1===a.getOutputShape())?!1:!0};Blockly.Python.control={};Blockly.Python.control_wait=function(a){return"sleep("+Blockly.Python.valueToCode(a,"DURATION",Blockly.Python.ORDER_FUNCTION_CALL)+")\n"};Blockly.Python.control_repeat=function(a){var b=Blockly.Python.valueToCode(a,"TIMES",Blockly.Python.ORDER_FUNCTION_CALL),c=Blockly.Python.statementToCode(a,"SUBSTACK");c=Blockly.Python.addLoopTrap(c,a.id);a="for count in range("+b+"):\n";return a=c?a+c:a+(Blockly.Python.INDENT+"pass\n")};
Blockly.Python.control_forever=function(a){var b=Blockly.Python.statementToCode(a,"SUBSTACK");b=Blockly.Python.addLoopTrap(b,a.id);"event_whenmicrobitbegin"===a.getRootBlock().type&&(Blockly.Python.firstLoop=!1);return"while (myvariable.mainrunning):\n"+b+(Blockly.Python.INDENT+"repeat()\n")};
Blockly.Python.control_if=function(a){var b=Blockly.Python.valueToCode(a,"CONDITION",Blockly.Python.ORDER_NONE)||"False",c=Blockly.Python.statementToCode(a,"SUBSTACK");c=Blockly.Python.addLoopTrap(c,a.id);a="if "+b+":\n";return a=c?a+c:a+(Blockly.Python.INDENT+"pass\n")};
Blockly.Python.control_if_else=function(a){var b=Blockly.Python.valueToCode(a,"CONDITION",Blockly.Python.ORDER_NONE)||"False",c=Blockly.Python.statementToCode(a,"SUBSTACK");c=Blockly.Python.addLoopTrap(c,a.id);var d=Blockly.Python.statementToCode(a,"SUBSTACK2");d=Blockly.Python.addLoopTrap(d,a.id);a="if "+b+":\n";a=c?a+c:a+(Blockly.Python.INDENT+"pass\n");a+="else:\n";return a=d?a+d:a+(Blockly.Python.INDENT+"pass\n")};
Blockly.Python.control_wait_until=function(a){return"while not "+(Blockly.Python.valueToCode(a,"CONDITION",Blockly.Python.ORDER_UNARY_POSTFIX)||"False")+":\n"+(Blockly.Python.INDENT+"repeat()\n")};Blockly.Python.control_repeat_until=function(a){var b=Blockly.Python.valueToCode(a,"CONDITION",Blockly.Python.ORDER_UNARY_POSTFIX)||"False",c=Blockly.Python.statementToCode(a,"SUBSTACK");c=Blockly.Python.addLoopTrap(c,a.id);return a="while not "+b+":\n"+c+(Blockly.Python.INDENT+"repeat()\n")};Blockly.Python.ledmatrix={};
Blockly.Python.ztech_ledmatrix=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";for(var b=1;Blockly.Python.loops_["ztech_ledmatrix"+b];)""===b?b=1:b++;var c=a.getChildren()[0].getFieldValue("MATRIX");console.log(c);var d=c.substring(0,16),e=c.substring(16,32),f=c.substring(32,48),h=c.substring(48,64),g=c.substring(64,
80),k=c.substring(80,96);a=c.substring(96,112);c=c.substring(112,128);console.log(d);console.log(e);d=parseInt(d,2).toString(16).toUpperCase();e=parseInt(e,2).toString(16).toUpperCase();f=parseInt(f,2).toString(16).toUpperCase();h=parseInt(h,2).toString(16).toUpperCase();g=parseInt(g,2).toString(16).toUpperCase();k=parseInt(k,2).toString(16).toUpperCase();a=parseInt(a,2).toString(16).toUpperCase();c=parseInt(c,2).toString(16).toUpperCase();return"matrix"+b+" = [0x"+d+", 0x"+e+", 0x"+f+", 0x"+h+", 0x"+
g+", 0x"+k+", 0x"+a+", 0x"+c+"]\nMatrix.show_draw(matrix"+b+")\n"};Blockly.Python.off=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";return"Matrix.clear_lm()\n"};
Blockly.Python.aa_text=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";return"Matrix.show_scrolling_word("+Blockly.Python.valueToCode(a,"data",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.heart_emotion=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";a=a.getChildren()[0].getFieldValue("value");console.log(a);""==a&&(a=1);return"Matrix.show16("+a+")\n"};
Blockly.Python.zz_led_matrix=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";for(var b=1;Blockly.Python.loops_["ztech_ledmatrix"+b];)""===b?b=1:b++;var c=Blockly.Python.valueToCode(a,"MATRIX",Blockly.Python.ORDER_ATOMIC);console.log(c);var d=c.substring(0,16),e=c.substring(16,32),f=c.substring(32,48),h=c.substring(48,
64),g=c.substring(64,80),k=c.substring(80,96);a=c.substring(96,112);c=c.substring(112,128);console.log(d);console.log(e);d=parseInt(d,2).toString(16).toUpperCase();e=parseInt(e,2).toString(16).toUpperCase();f=parseInt(f,2).toString(16).toUpperCase();h=parseInt(h,2).toString(16).toUpperCase();g=parseInt(g,2).toString(16).toUpperCase();k=parseInt(k,2).toString(16).toUpperCase();a=parseInt(a,2).toString(16).toUpperCase();c=parseInt(c,2).toString(16).toUpperCase();return"matrix"+b+" = [0x"+d+", 0x"+e+
", 0x"+f+", 0x"+h+", 0x"+g+", 0x"+k+", 0x"+a+", 0x"+c+"]\nMatrix.show_draw(matrix"+b+")\n"};Blockly.Python.led_matrix_kapat=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";return"Matrix.clear_lm()\n"};
Blockly.Python["led_matrix_yazd\u0131r"]=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";return"Matrix.show_word("+Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python["led_matrix_kayd\u0131rarak_yazd\u0131r"]=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";return"Matrix.show_scrolling_word("+Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.zz_led_matrix_saniye=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";for(var b=1;Blockly.Python.loops_["ztech_ledmatrix_saniyeli"+b];)""===b?b=1:b++;var c=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC),d=Blockly.Python.valueToCode(a,"MATRIX",Blockly.Python.ORDER_ATOMIC);console.log("matrixBinary");
console.log(d);var e=d.substring(0,16),f=d.substring(16,32),h=d.substring(32,48),g=d.substring(48,64),k=d.substring(64,80),l=d.substring(80,96);a=d.substring(96,112);d=d.substring(112,128);console.log(e);console.log(f);e=parseInt(e,2).toString(16).toUpperCase();f=parseInt(f,2).toString(16).toUpperCase();h=parseInt(h,2).toString(16).toUpperCase();g=parseInt(g,2).toString(16).toUpperCase();k=parseInt(k,2).toString(16).toUpperCase();l=parseInt(l,2).toString(16).toUpperCase();a=parseInt(a,2).toString(16).toUpperCase();
d=parseInt(d,2).toString(16).toUpperCase();return"matrix"+b+" = [0x"+e+", 0x"+f+", 0x"+h+", 0x"+g+", 0x"+k+", 0x"+l+", 0x"+a+", 0x"+d+"]\nMatrix.show_draw(matrix"+b+","+c+")\n"};
Blockly.Python.zz_led_matrix_ekrana_ciz=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";for(var b=1;Blockly.Python.loops_["ztech_ledmatrix_saniyeli"+b];)""===b?b=1:b++;var c=Blockly.Python.valueToCode(a,"MATRIX",Blockly.Python.ORDER_ATOMIC),d=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,
"Y",Blockly.Python.ORDER_ATOMIC);console.log("matrixBinary");console.log(c);var e=c.substring(0,16),f=c.substring(16,32),h=c.substring(32,48),g=c.substring(48,64),k=c.substring(64,80),l=c.substring(80,96),m=c.substring(96,112);c=c.substring(112,128);console.log(e);console.log(f);e=parseInt(e,2).toString(16).toUpperCase();f=parseInt(f,2).toString(16).toUpperCase();h=parseInt(h,2).toString(16).toUpperCase();g=parseInt(g,2).toString(16).toUpperCase();k=parseInt(k,2).toString(16).toUpperCase();l=parseInt(l,
2).toString(16).toUpperCase();m=parseInt(m,2).toString(16).toUpperCase();c=parseInt(c,2).toString(16).toUpperCase();return"matrix"+b+" = [0x"+e+", 0x"+f+", 0x"+h+", 0x"+g+", 0x"+k+", 0x"+l+", 0x"+m+", 0x"+c+"]\nMatrix.show_draw(matrix"+b+",0,"+d+","+a+")\n"};
Blockly.Python.led_yak_x_y=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";var b=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);return"Matrix.light_on("+b+","+a+")\n"};
Blockly.Python.led_kapat_x_y=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";var b=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);return"Matrix.light_off("+b+","+a+")\n"};
Blockly.Python["goz_k\u0131rp_x_y"]=function(a){Blockly.Python.imports_.ztech_ledmatrix="from mylib import ZBotLedMatrix";Blockly.Python.setups_.ztech_ledmatrix="Matrix = ZBotLedMatrix.LedMatrix()";Blockly.Python.setups_.ztech_ledmatrix_init="Matrix.ledm_initialize()";var b=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);return"Matrix.light_on_off("+b+","+a+")\n"};Blockly.Python.operator={};Blockly.Python.operator_arithmetic=function(a){var b={operator_add:[" + ",Blockly.Python.ORDER_ADDITIVE],operator_subtract:[" - ",Blockly.Python.ORDER_ADDITIVE],operator_multiply:[" * ",Blockly.Python.ORDER_MULTIPLICATIVE],operator_divide:[" / ",Blockly.Python.ORDER_MULTIPLICATIVE]}[a.type],c=b[0];b=b[1];var d=Blockly.Python.valueToCode(a,"NUM1",b)||"0";a=Blockly.Python.valueToCode(a,"NUM2",b)||"0";return[d+c+a,b]};Blockly.Python.operator_add=Blockly.Python.operator_arithmetic;
Blockly.Python.operator_subtract=Blockly.Python.operator_arithmetic;Blockly.Python.operator_multiply=Blockly.Python.operator_arithmetic;Blockly.Python.operator_divide=Blockly.Python.operator_arithmetic;
Blockly.Python.operator_random=function(a){Blockly.Python.imports_.operator_random="import random as ztechRandom";var b=Blockly.Python.valueToCode(a,"FROM",Blockly.Python.ORDER_FUNCTION_CALL)||"0";a=Blockly.Python.valueToCode(a,"TO",Blockly.Python.ORDER_FUNCTION_CALL)||"0";return["ztechRandom.randint("+b+", "+a+")",Blockly.Python.ORDER_FUNCTION_CALL]};
Blockly.Python.operator_compare=function(a){var b=Blockly.Python.ORDER_RELATIONAL,c=Blockly.Python.valueToCode(a,"OPERAND1",b),d=Blockly.Python.valueToCode(a,"OPERAND2",b);parseFloat(c.slice(1,-1))==c.slice(1,-1)?c=parseFloat(c.slice(1,-1)).toString():"''"===c&&(c="0");parseFloat(d.slice(1,-1))==d.slice(1,-1)?d=parseFloat(d.slice(1,-1)).toString():"''"===d&&(d="0");return[c+{operator_gt:" > ",operator_equals:" == ",operator_lt:" < "}[a.type]+d,b]};Blockly.Python.operator_gt=Blockly.Python.operator_compare;
Blockly.Python.operator_equals=Blockly.Python.operator_compare;Blockly.Python.operator_lt=Blockly.Python.operator_compare;Blockly.Python.operator_operation=function(a){var b="operator_and"==a.type?Blockly.Python.ORDER_LOGICAL_AND:Blockly.Python.ORDER_LOGICAL_OR,c=Blockly.Python.valueToCode(a,"OPERAND1",b)||"0",d=Blockly.Python.valueToCode(a,"OPERAND2",b)||"0";return[c+{operator_and:" and ",operator_or:" or "}[a.type]+d,b]};Blockly.Python.operator_and=Blockly.Python.operator_operation;
Blockly.Python.operator_or=Blockly.Python.operator_operation;Blockly.Python.operator_not=function(a){var b=Blockly.Python.ORDER_LOGICAL_NOT;return["not "+(Blockly.Python.valueToCode(a,"OPERAND",b)||"false"),b]};Blockly.Python.operator_join=function(a){var b=Blockly.Python.ORDER_UNARY_PREFIX,c=Blockly.Python.valueToCode(a,"STRING1",b)||"''";a=Blockly.Python.valueToCode(a,"STRING2",b)||"''";return["str("+c+") + str("+a+")",Blockly.Python.ORDER_ADDITIVE]};
Blockly.Python.operator_letter_of=function(a){var b=Blockly.Python.valueToCode(a,"STRING",Blockly.Python.ORDER_UNARY_SIGN)||"''";a=Blockly.Python.valueToCode(a,"LETTER",Blockly.Python.ORDER_MEMBER)||"0";a=parseFloat(a)==a?a-1:a+" - 1";return[b+"["+a+"]",Blockly.Python.ORDER_MEMBER]};Blockly.Python.operator_length=function(a){return["len("+(Blockly.Python.valueToCode(a,"STRING",Blockly.Python.ORDER_FUNCTION_CALL)||"''")+")",Blockly.Python.ORDER_FUNCTION_CALL]};
Blockly.Python.operator_contains=function(a){var b=Blockly.Python.ORDER_FUNCTION_CALL,c=Blockly.Python.valueToCode(a,"STRING1",b)||"''";a=Blockly.Python.valueToCode(a,"STRING2",b)||"0";return["str("+c+").find(str("+a+")) > -1",Blockly.Python.ORDER_RELATIONAL]};Blockly.Python.operator_mod=function(a){var b=Blockly.Python.ORDER_MULTIPLICATIVE,c=Blockly.Python.valueToCode(a,"NUM1",b)||"0";a=Blockly.Python.valueToCode(a,"NUM2",b)||"0";return[c+" % "+a,b]};
Blockly.Python.operator_round=function(a){return["round("+(Blockly.Python.valueToCode(a,"NUM",Blockly.Python.ORDER_UNARY_POSTFIX)||"0")+")",Blockly.Python.ORDER_FUNCTION_CALL]};
Blockly.Python.operator_mathop=function(a){var b=a.getFieldValue("OPERATOR");a=Blockly.Python.valueToCode(a,"NUM",Blockly.Python.ORDER_FUNCTION_CALL)||"0";Blockly.Python.imports_.math="import math";var c="",d=Blockly.Python.ORDER_FUNCTION_CALL;switch(b){case "abs":c="math.fabs("+a+")";break;case "floor":c="math.floor("+a+")";break;case "ceiling":c="math.ceil("+a+")";break;case "sqrt":c="math.sqrt("+a+")";break;case "sin":c="math.sin("+a+" / 180.0 * math.pi)";break;case "cos":c="math.cos("+a+" / 180.0 * math.pi)";
break;case "tan":c="math.tan("+a+" / 180.0 * math.pi)";break;case "asin":c="math.asin("+a+") / math.pi * 180";d=Blockly.Python.ORDER_MULTIPLICATIVE;break;case "acos":c="math.acosh("+a+") / math.pi * 180";d=Blockly.Python.ORDER_MULTIPLICATIVE;break;case "atan":c="math.atan("+a+") / math.pi * 180";d=Blockly.Python.ORDER_MULTIPLICATIVE;break;case "ln":c="math.log("+a+")";break;case "log":c="math.log("+a+", 10)";break;case "e ^":c="math.exp("+a+")";break;case "10 ^":c="math.pow(10, "+a+")"}return[c,d]};Blockly.Python.event={};
Blockly.Python.game_when_detect_robot_button=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.button="from mylib import ZBotButton";Blockly.Python.setups_.button="Button = ZBotButton.ZBotButton()";Blockly.Python.imports_.sleep="from time import sleep";var b=a.getFieldValue("KEY_OPTION"),c;for(c="";Blockly.Python.loops_["game_when_detect_robot_button"+b+c];)""===c?c=1:c++;var d="detect_x";"upArrow"==b&&(d="detect_x");"downArrow"==b&&(d="detect_triangle");"leftArrow"==
b&&(d="detect_circle");"rightArrow"==b&&(d="detect_square");"z"==b&&(d="detect_z");Blockly.Python.loops_["game_when_detect_robot_button"+b+c]="if Button."+d+"():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_button_"+b+c+"()";d="def on_button_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+
e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_button_"+b+c]=d;return null};
Blockly.Python.game_when_shake_robot=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lms="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lms="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";var b;for(b="";Blockly.Python.loops_["game_when_shake_robot"+b];)""===b?b=1:b++;Blockly.Python.loops_["game_when_shake_robot"+b]="if LSM.shaking_intensity()> 70:\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_shake_"+b+"()";
var c="def on_shake_"+b+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var d=[],e;for(e in Blockly.Python.variables_)d.push(Blockly.Python.variables_[e].slice(0,Blockly.Python.variables_[e].indexOf("=")-1));0!==d.length&&(c+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");c=Blockly.Python.scrub_(a,c)}else c+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_shake_"+b]=c;return null};
Blockly.Python.game_when_touch_robot=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lms="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lms="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";var b;for(b="";Blockly.Python.loops_["game_when_touch_robot"+b];)""===b?b=1:b++;Blockly.Python.loops_["game_when_touch_robot"+b]="if LSM.shaking_intensity()> 14:\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_touch_"+b+"()";
var c="def on_touch_"+b+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var d=[],e;for(e in Blockly.Python.variables_)d.push(Blockly.Python.variables_[e].slice(0,Blockly.Python.variables_[e].indexOf("=")-1));0!==d.length&&(c+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");c=Blockly.Python.scrub_(a,c)}else c+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_touch_"+b]=c;return null};
Blockly.Python.game_when_detect_barrier=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.barrier="from mylib import ZBotBarrier";Blockly.Python.setups_.barrier="Barrier = ZBotBarrier.ZBotBarrier()";Blockly.Python.imports_.sleep="from time import sleep";for(var b=a.getChildren()[0].getFieldValue("detect_barrier"),c="";Blockly.Python.loops_["game_when_detect_barrier"+b+c];)""===c?c=1:c++;var d="";"barrier"==b&&(d="not");"no_barrier"==b&&(d="");Blockly.Python.loops_["game_when_detect_barrier"+
b+c]="if "+d+" Barrier.detect_barrier():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_"+b+c+"()";d="def on_detect_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_"+
b+c]=d;return null};
Blockly.Python.game_when_detect_barrier_vertical=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.barrier="from mylib import ZBotBarrier";Blockly.Python.setups_.barrier="Barrier = ZBotBarrier.ZBotBarrier()";Blockly.Python.imports_.sleep="from time import sleep";var b=a.getFieldValue("KEY_OPTION"),c;for(c="";Blockly.Python.loops_["game_when_detect_barrier_vertical"+b+c];)""===c?c=1:c++;var d="";"yes"==b&&(d="");"no"==b&&(d=" not");Blockly.Python.loops_["game_when_detect_barrier_vertical"+b+
c]="if"+d+" Barrier.detect_barrier():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_barrier_"+b+c+"()";d="def on_detect_barrier_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_barrier_"+
b+c]=d;return null};
Blockly.Python.game_when_keypress_game=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.button="from mylib import ZBotButton";Blockly.Python.setups_.button="Button = ZBotButton.ZBotButton()";Blockly.Python.imports_.sleep="from time import sleep";var b=a.getChildren()[0].getFieldValue("click"),c;for(c="";Blockly.Python.loops_["game_when_keypress_game"+b+c];)""===c?c=1:c++;var d="detect_x";"upArrow"==b&&(d="detect_x");"downArrow"==b&&(d="detect_triangle");"leftArrow"==
b&&(d="detect_circle");"rightArrow"==b&&(d="detect_square");"zButton"==b&&(d="detect_z");Blockly.Python.loops_["game_when_keypress_game"+b+c]="if Button."+d+"():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_button_"+b+c+"()";d="def on_button_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+
e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_button_"+b+c]=d;return null};
Blockly.Python.game_detect_sound_intensity=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotSound";Blockly.Python.setups_.sound="Sound = ZBotSound.ZBotSound()";Blockly.Python.imports_.sleep="from time import sleep";var b=a.getChildren()[0].getFieldValue("sound_intensity"),c;for(c="";Blockly.Python.loops_["game_detect_sound_intensity"+c];)""===c?c=1:c++;Blockly.Python.loops_["game_detect_sound_intensity"+c]="if Sound.detect_sound() > "+
b+": \n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_sound_"+c+"()";b="def on_detect_sound_"+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var d=[],e;for(e in Blockly.Python.variables_)d.push(Blockly.Python.variables_[e].slice(0,Blockly.Python.variables_[e].indexOf("=")-1));0!==d.length&&(b+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");b=Blockly.Python.scrub_(a,b)}else b+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_sound_"+c]=b;return null};
Blockly.Python["game_when_detect_color-eski"]=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.color="from mylib import ZBotColor";Blockly.Python.setups_.colordetect="Color = ZBotColor.ColorDetector()";Blockly.Python.setups_.colorinit="Color.initialize_color()";Blockly.Python.setups_.colorcalibrate="Color.calibrate_colors()";Blockly.Python.imports_.sleep="from time import sleep";for(var b="x",c="";Blockly.Python.loops_["game_when_detect_color"+b+c];)""===
c?c=1:c++;var d=a.getChildren()[0].getFieldValue("detect_color"),e="if Color.detect_green():\n";b="green";"blue"==d&&(e="if Color.detect_blue():\n",b="blue");"green"==d&&(e="if Color.detect_green():\n",b="green");"red"==d&&(e="if Color.detect_red():\n",b="red");"white"==d&&(e="if Color.detect_white():\n",b="white");"turquoise"==d&&(e="if Color.detect_turquoise():\n",b="turquoise");Blockly.Python.loops_["when_detect_color"+b+c]=e+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_color_"+b+c+"()";
e="def on_detect_color_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){d=[];for(var f in Blockly.Python.variables_)d.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==d.length&&(e+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");e=Blockly.Python.scrub_(a,e)}else e+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_color_"+b+c]=e;return null};
Blockly.Python.game_when_detect_color=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.color="from mylib import ZBotColor";Blockly.Python.setups_.colordetect="Color = ZBotColor.ColorDetector()";Blockly.Python.setups_.colorinit="Color.initialize_color()";Blockly.Python.setups_.colorcalibrate="Color.calibrate_colors()";var b=a.getChildren()[0].getFieldValue("detect_color"),c;for(c="";Blockly.Python.loops_["game_when_detect_color"+b+c];)""===c?c=1:c++;var d=
"getColor == 5";"blue"==b&&(d="getColor == 7");"green"==b&&(d="getColor == 4");"turkuaz"==b&&(d="getColor == 3");"red"==b&&(d="getColor == 5");"yellow"==b&&(d="getColor == 6");"purple"==b&&(d="getColor == 8");"white"==b&&(d="getColor == 2");"black"==b&&(d="getColor == 1");Blockly.Python.loops_.game_when_detect_color="getColor =Color.detect_color()\n";Blockly.Python.loops_["game_when_detect_color"+b+c]="if "+d+":\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_color_"+b+c+"()";d="def on_detect_color_"+
b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_color_"+b+c]=d;return null};
Blockly.Python.game_when_detect_color_vertical=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.color="from mylib import ZBotColor";Blockly.Python.setups_.colordetect="Color = ZBotColor.ColorDetector()";Blockly.Python.setups_.colorinit="Color.initialize_color()";Blockly.Python.setups_.colorcalibrate="Color.calibrate_colors()";Blockly.Python.imports_.sleep="from time import sleep";for(var b="x",c="";Blockly.Python.loops_["game_when_detect_color_vertical"+b+
c];)""===c?c=1:c++;var d=a.getFieldValue("KEY_OPTION"),e="if Color.detect_green():\n";b="green";"blue"==d&&(e="if Color.detect_blue():\n",b="blue");"green"==d&&(e="if Color.detect_green():\n",b="green");"red"==d&&(e="if Color.detect_red():\n",b="red");"white"==d&&(e="if Color.detect_white():\n",b="white");"turquoise"==d&&(e="if Color.detect_turquoise():\n",b="turquoise");Blockly.Python.loops_["game_when_detect_color_vertical"+b+c]=e+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_color_"+b+
c+"()";e="def on_detect_color_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){d=[];for(var f in Blockly.Python.variables_)d.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==d.length&&(e+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");e=Blockly.Python.scrub_(a,e)}else e+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_color_"+b+c]=e;return null};
Blockly.Python.game_when_detect_light=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.light="from mylib import ZBotLightIntensity";Blockly.Python.setups_.light="Light = ZBotLightIntensity.ZBotLightIntensity()";Blockly.Python.imports_.sleep="from time import sleep";for(var b=a.getChildren()[0].getFieldValue("light_intensity"),c="";Blockly.Python.loops_["game_when_detect_light"+c];)""===c?c=1:c++;Blockly.Python.loops_["game_when_detect_light"+c]="if Light.detect_intensity() > "+
b+": \n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_light_"+c+"()";b="def on_detect_light_"+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var d=[],e;for(e in Blockly.Python.variables_)d.push(Blockly.Python.variables_[e].slice(0,Blockly.Python.variables_[e].indexOf("=")-1));0!==d.length&&(b+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");b=Blockly.Python.scrub_(a,b)}else b+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_light_"+c]=b;return null};
Blockly.Python.game_when_detect_light_vertical=function(a){Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.myvariable="import myvariable";var b=a.getFieldValue("KEY_OPTION"),c=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ATOMIC)||"0",d;for(d="";Blockly.Python.loops_["game_when_detect_light_vertical"+b+d];)""===d?d=1:d++;var e="if Sound.detect_sound() > "+c+" :\n";"light"==b&&(Blockly.Python.imports_.light="from mylib import ZBotLightIntensity",Blockly.Python.setups_.light=
"Light = ZBotLightIntensity.ZBotLightIntensity()",e="if Light.detect_intensity() > "+c+" :\n");"shake"==b&&(Blockly.Python.imports_.lms="from mylib import ZBotLSM6DS3",Blockly.Python.setups_.lms="LSM = ZBotLSM6DS3.LSM6DS3()",e="if LSM.shaking_intensity() > "+c+" :\n");"sound"==b&&(Blockly.Python.imports_.sound="from mylib import ZBotSound",Blockly.Python.setups_.sound="Sound = ZBotSound.ZBotSound()",e="if Sound.detect_sound() > "+c+" :\n");Blockly.Python.loops_["game_when_detect_light_vertical"+b+
d]=e+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_"+b+d+"()";c="def on_"+b+d+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){e=[];for(var f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(c+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");c=Blockly.Python.scrub_(a,c)}else c+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_"+b+d]=c;return null};
Blockly.Python.game_when_detect_tilted_vertical=function(a){Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.myvariable="import myvariable";for(var b=a.getFieldValue("KEY_OPTION"),c=2,d="left",e="";Blockly.Python.loops_["game_when_detect_tilted_vertical"+d+e];)""===e?e=1:e++;"left"==b&&(c=2,d="left");"right"==b&&(c=1,d="right");"forward"==b&&(c=4,d="forward");"backward"==b&&(c=8,d="backward");"straight"==b&&(c=32,d="straight");Blockly.Python.imports_.lms="from mylib import ZBotLSM6DS3";
Blockly.Python.setups_.lms="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.loops_["game_when_detect_tilted_vertical"+d+e]="if LSM.detect_6d_orientation() == "+c+": \n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_detect_"+d+e+"()";b="def on_detect_"+d+e+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){c=[];for(var f in Blockly.Python.variables_)c.push(Blockly.Python.variables_[f].slice(0,Blockly.Python.variables_[f].indexOf("=")-1));0!==c.length&&(b+=Blockly.Python.INDENT+"global "+c.join(", ")+
"\n");b=Blockly.Python.scrub_(a,b)}else b+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_detect_"+d+e]=b;return null};Blockly.Python.game_event_whenflagclicked=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sleep="from time import sleep";return"if True:\n"};
Blockly.Python.game_when_robot_run=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sleep="from time import sleep";var b="";return b=a.nextConnection&&a.nextConnection.targetBlock()?b+"if True:\n":b+"pass\n"};
Blockly.Python.event_whenmicrobitbegin=function(a){Blockly.Python.imports_.microbit_time="import time";Blockly.Python.imports_.sleep="from time import sleep";var b="";a.nextConnection&&a.nextConnection.targetBlock()||(b+="count = 0\n while (1): \n   count = count +1\n   time.sleep(1)\n   print('hello:'+str(count))\n");return b};
Blockly.Python.event_whenmicrobitbuttonpressed=function(a){Blockly.Python.imports_.microbit="from microbit import *";for(var b=a.getFieldValue("KEY_OPTION"),c="";Blockly.Python.loops_["event_whenmicrobitbegin"+b+c];)""===c?c=1:c++;Blockly.Python.loops_["event_whenmicrobitbegin"+b+c]="if button_"+b+".is_pressed():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_button_"+b+c+"()";var d="def on_button_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,
Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_button_"+b+c]=d;return null};
Blockly.Python.event_whenmicrobitpinbeingtouched=function(a){Blockly.Python.imports_.microbit="from microbit import *";for(var b=a.getFieldValue("PIN_OPTION"),c="";Blockly.Python.loops_["event_whenmicrobitpinbeingtouched"+b+c];)""===c?c=1:c++;Blockly.Python.loops_["event_whenmicrobitpinbeingtouched"+b+c]="if pin"+b+".is_pressed():\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_pin"+b+c+"()";var d="def on_pin"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,
Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_pin"+b+c]=d;return null};
Blockly.Python.event_whenmicrobitgesture=function(a){Blockly.Python.imports_.microbit="from microbit import *";for(var b=a.getFieldValue("GESTURE_OPTION"),c="";Blockly.Python.loops_["event_whenmicrobitgesture"+b+c];)""===c?c=1:c++;Blockly.Python.loops_["event_whenmicrobitgesture"+b+c]="if accelerometer.was_gesture('"+b+"'):\n"+Blockly.Python.INDENT+Blockly.Python.INDENT+"on_"+b+c+"()";var d="def on_"+b+c+"():\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var e=[],f;for(f in Blockly.Python.variables_)e.push(Blockly.Python.variables_[f].slice(0,
Blockly.Python.variables_[f].indexOf("=")-1));0!==e.length&&(d+=Blockly.Python.INDENT+"global "+e.join(", ")+"\n");d=Blockly.Python.scrub_(a,d)}else d+=Blockly.Python.INDENT+"pass\n";Blockly.Python.libraries_["def on_"+b+c]=d;return null};Blockly.Python.matrix={};Blockly.Python.matrix=function(a){a=a.getFieldValue("MATRIX");isNaN(a)&&(a=0);return[a,Blockly.Python.ORDER_ATOMIC]};Blockly.Python.leds={};Blockly.Python.lights_off=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";return"Leds.leds_off()\n"};
Blockly.Python.rgb_lights=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";a=a.getChildren()[0].getFieldValue("COLOR");var b="";"red"==a&&(b="Leds.leds_light(1)\n");"green"==a&&(b="Leds.leds_light(2)\n");"blue"==a&&(b="Leds.leds_light(3)\n");"magenta"==a&&(b="Leds.leds_light(4)\n");"cyan"==a&&(b="Leds.leds_light(5)\n");"yellow"==a&&(b="Leds.leds_light(6)\n");"white"==a&&(b="Leds.leds_light(7)\n");return b};
Blockly.Python.light_onoff=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.light_onoff="Led = ZBotLeds.OneLed()";a=a.getChildren()[0].getFieldValue("ICON");var b="Led.led_light(1)\n";"red"==a&&(b="Led.led_light(1)\n");"green"==a&&(b="Led.led_light(2)\n");"blue"==a&&(b="Led.led_light(3)\n");"magenta"==a&&(b="Led.led_light(4)\n");"cyan"==a&&(b="Led.led_light(5)\n");"yellow"==a&&(b="Led.led_light(6)\n");"white"==a&&(b="Led.led_light(7)\n");return b};
Blockly.Python.on_light_off=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.light_onoff="Led = ZBotLeds.OneLed()";return"Led.led_off()\n"};
Blockly.Python.on_led_yak=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.light_onoff="Led = ZBotLeds.OneLed()";a=a.getFieldValue("VALUE");var b="Led.led_light(1)\n";"red"==a&&(b="Led.led_light(1)\n");"green"==a&&(b="Led.led_light(2)\n");"blue"==a&&(b="Led.led_light(3)\n");"magenta"==a&&(b="Led.led_light(4)\n");"cyan"==a&&(b="Led.led_light(5)\n");"yellow"==a&&(b="Led.led_light(6)\n");"white"==a&&(b="Led.led_light(7)\n");return b};
Blockly.Python.on_led_kapat=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.light_onoff="Led = ZBotLeds.OneLed()";return"Led.led_off()\n"};Blockly.Python.arka_led_kapat=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";return"Leds.leds_off()\n"};
Blockly.Python.arka_ledleri_yak=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";a=a.getFieldValue("VALUE");var b="";"red"==a&&(b="Leds.leds_light(1)\n");"green"==a&&(b="Leds.leds_light(2)\n");"blue"==a&&(b="Leds.leds_light(3)\n");"magenta"==a&&(b="Leds.leds_light(4)\n");"cyan"==a&&(b="Leds.leds_light(5)\n");"yellow"==a&&(b="Leds.leds_light(6)\n");"white"==a&&(b="Leds.leds_light(7)\n");return b};
Blockly.Python.arka_led_saniyeli=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";var b=Blockly.Python.valueToCode(a,"COLOR",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ATOMIC);var c=/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(b);b=parseInt(c[1],16);var d=parseInt(c[2],16);c=parseInt(c[3],16);return"Leds.leds_light_by_value("+b+", "+d+", "+c+", "+a+")\n"};
Blockly.Python.arka_led_rgb_yap=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";var b=Blockly.Python.valueToCode(a,"R",Blockly.Python.ORDER_ATOMIC),c=Blockly.Python.valueToCode(a,"G",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"B",Blockly.Python.ORDER_ATOMIC);return"Leds.leds_light_by_value("+b+", "+c+", "+a+")\n"};
Blockly.Python.arka_led_255=function(a){Blockly.Python.imports_.leds="from mylib import ZBotLeds";Blockly.Python.setups_.leds="Leds = ZBotLeds.LedStrip()";var b=a.getFieldValue("VALUE"),c=1;"red"==b&&(c=1);"green"==b&&(c=2);"blue"==b&&(c=3);"magenta"==b&&(c=4);"cyan"==b&&(c=5);"yellow"==b&&(c=6);"white"==b&&(c=7);a=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ATOMIC);return"Leds.leds_light_brightness("+c+", "+a+")\n"};Blockly.Python.SoundZbot={};
Blockly.Python.sound_melodi_list=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.isota="import isOta";a=a.getChildren()[0].getFieldValue("data");var b="262";""==a&&(b="262");"do"==a&&(b="262");"re"==a&&(b="294");"mi"==a&&(b="330");"fa"==a&&(b="349");"so"==a&&(b="392");"la"==a&&(b="440");"si"==a&&(b="494");return"isOta.x.Note.play_note_for_time("+b+", 500)\n"};
Blockly.Python.sound_off=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";return"isOta.x.Note.stop_note()\n"};
Blockly.Python.custom_sound=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";a=a.getChildren()[0].getFieldValue("value");""==a&&(a="hiphop");return'isOta.x.Plyr.play_wav("'+a+'")\n'};
Blockly.Python.play_sound=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";a=a.getChildren()[0].getFieldValue("SOUND");console.log(a);return"isOta.x.Plyr.level = "+("vol1"==a?33:"vol2"==a?70:100)+"\n"};
Blockly.Python.zbot_ses_cal=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";return'isOta.x.Plyr.play_wav("'+a.getFieldValue("ADDR")+'")\n'};
Blockly.Python.ses_cal_bitene_kadar=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";return'isOta.x.Plyr.play_wav("'+a.getFieldValue("ADDR")+'")\n'};
Blockly.Python.zbot_ses_seviyesi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";a=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);console.log(a);return"isOta.x.Plyr.level = "+a+"\n"};
Blockly.Python.ses_seviyesi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";return["isOta.x.Plyr.show_level()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.zbot_ses_seviyesi_degistir=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";return"isOta.x.Plyr.change_level("+Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.nota_cal=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";var b=a.getFieldValue("PIN");a=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);console.log(a);a=parseFloat(a);return"isOta.x.Note.play_note_for_time(notes."+b+", "+1E3*a+")\n"};
Blockly.Python.tum_sesleri_durdur=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotPlayNote, ZBotWavPlayer, notes";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.imports_.isota="import isOta";Blockly.Python.setups_.soundNote="Note = ZBotPlayNote.ZBotPlayNote()\n";return"isOta.x.Note.stop_note()\n"};Blockly.Python.SensorZbot={};
Blockly.Python.dugme_basildimi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_._keypress_game="from mylib import ZBotButton";Blockly.Python.imports_.sleep="from time import sleep";Blockly.Python.setups_.button="Button = ZBotButton.ZBotButton()";a=a.getFieldValue("KEY_OPTION");var b="Button.detect_x()";"upArrow"==a&&(b="Button.detect_x()");"downArrow"==a&&(b="Button.detect_triangle()");"leftArrow"==a&&(b="Button.detect_circle()");"rightArrow"==a&&(b="Button.detect_square()");
"z"==a&&(b="Button.detect_z()");return[b,Blockly.Python.ORDER_ATOMIC]};Blockly.Python.cihaz_sallaniyormu=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lms="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lms="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";return["LSM.detect_single_tap()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.algilanan_renk=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.color="from mylib import ZBotColor";Blockly.Python.setups_.colordetect="Color = ZBotColor.ColorDetector()";Blockly.Python.setups_.colorinit="Color.initialize_color()";Blockly.Python.setups_.colorcalibrate="Color.calibrate_colors()";Blockly.Python.loops_.game_when_detect_color="Color.detect_color()\n";a=a.getFieldValue("KEY_OPTION");var b="Color.color == 7";"blue"==a&&(b="Color.color == 7");
"green"==a&&(b="Color.color == 4");"turkuaz"==a&&(b="Color.color == 3");"red"==a&&(b="Color.color == 5");"yellow"==a&&(b="Color.color == 6");"purple"==a&&(b="Color.color == 8");"white"==a&&(b="Color.color == 2");"black"==a&&(b="Color.color == 1");return[b,Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.onundeki_engeller=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.barrier="from mylib import ZBotBarrier";Blockly.Python.setups_.barrier="Barrier = ZBotBarrier.ZBotBarrier()";Blockly.Python.imports_.sleep="from time import sleep";a.getFieldValue("KEY_OPTION");return["Barrier.detect_barrier()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.ses_siddeti=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotSound";Blockly.Python.setups_.sound="Sound = ZBotSound.ZBotSound()";Blockly.Python.imports_.sleep="from time import sleep";return["Sound.detect_sound()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.sallanma_siddeti=function(a){return null};
Blockly.Python.ses_siddeti=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.sound="from mylib import ZBotSound";Blockly.Python.setups_.sound="Sound = ZBotSound.ZBotSound()";Blockly.Python.imports_.sleep="from time import sleep";return["Sound.detect_sound()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.ortam_isik_siddeti=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.light="from mylib import ZBotLightIntensity";Blockly.Python.setups_.light="Light = ZBotLightIntensity.ZBotLightIntensity()";Blockly.Python.imports_.sleep="from time import sleep";return["Light.detect_intenstiy()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.sallanma_siddeti=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lsm="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lsm="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";return["LSM.shaking_intensity()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.yatis_acisi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lsm="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lsm="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";return["LSM.side_tilt()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.egim_acisi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.lsm="from mylib import ZBotLSM6DS3";Blockly.Python.setups_.lsm="LSM = ZBotLSM6DS3.LSM6DS3()";Blockly.Python.imports_.sleep="from time import sleep";return["LSM.direct_tilt()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.pil_seviyesi=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.battery="from mylib import ZBotBattery";Blockly.Python.setups_.battery="Battery = ZBotBattery.ZBotBattery()";Blockly.Python.imports_.sleep="from time import sleep";return["Battery.get_percent()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.cizgi_algilama_renk=function(a){Blockly.Python.imports_.myvariable="import myvariable";Blockly.Python.imports_.line="from mylib import ZBotLine";Blockly.Python.setups_.line="Line  = ZBotLine.ZBotLine()";Blockly.Python.imports_.sleep="from time import sleep";var b=a.getFieldValue("DIRECTION");a=a.getFieldValue("VALUE");var c="Line.is_right_black()";"right"==b&&"black"==a&&(c="Line.is_right_black()");"right"==b&&"white"==a&&(c="Line.is_right_white()");"left"==b&&"black"==a&&(c="Line.is_left_black()");
"left"==b&&"white"==a&&(c="Line.is_left_white()");return[c,Blockly.Python.ORDER_ATOMIC]};Blockly.Python.procedures={};
Blockly.Python.procedures_definition=function(a){var b=Blockly.Python.statementToCode(a,"custom_block");b=b.slice(2);var c=b+":\n";if(a.nextConnection&&a.nextConnection.targetBlock()){var d=[],e;for(e in Blockly.Python.variables_)d.push(Blockly.Python.variables_[e].slice(0,Blockly.Python.variables_[e].indexOf("=")-1));0!==d.length&&(c+=Blockly.Python.INDENT+"global "+d.join(", ")+"\n");c=Blockly.Python.scrub_(a,c)}else c+=Blockly.Python.INDENT+"pass\n";Blockly.Python.customFunctions_[b]=c;return null};
Blockly.Python.procedures_call=function(a){var b=a.getProcCode();b=b.replace(/ /g,"_");b=b.replace(/%n/g,"N");b=b.replace(/%s/g,"S");b=b.replace(/%b/g,"B");b=Blockly.Python.variableDB_.getName(b,Blockly.Procedures.NAME_TYPE);for(var c=[],d=0;d<a.inputList.length;d++)if(a.inputList[d].type==Blockly.INPUT_VALUE)if(a.inputList[d].connection.targetBlock()){var e=a.inputList[d].connection.targetBlock();e=Blockly.Python.blockToCode(e);c.push(e[0])}else c.push("false");return b+"("+c.join(", ")+");\n"};
Blockly.Python.procedures_prototype=function(a){var b=a.getProcCode();a=a.displayNames_;var c=[];b=b.replace(/ /g,"_");for(var d=0;d<a.length;d++){var e=b.charAt(b.indexOf("%")+1),f=Blockly.Python.variableDB_.getName(a[d],Blockly.Procedures.NAME_TYPE);Blockly.Python.customFunctionsArgName_[a[d]]=f;b="n"===e?b.replace("%n","N"):"s"===e?b.replace("%s","S"):b.replace("%b","B");c.push(f)}b=Blockly.Python.variableDB_.getName(b,Blockly.Procedures.NAME_TYPE);return"def "+b+"("+c.join(", ")+")"};
Blockly.Python.argument_reporter_boolean=function(a){a=a.getFieldValue("VALUE");return[Blockly.Python.customFunctionsArgName_[a],Blockly.Python.ORDER_ATOMIC]};Blockly.Python.argument_reporter_number=function(a){a=a.getFieldValue("VALUE");return[Blockly.Python.customFunctionsArgName_[a],Blockly.Python.ORDER_ATOMIC]};Blockly.Python.argument_reporter_string=function(a){a=a.getFieldValue("VALUE");return[Blockly.Python.customFunctionsArgName_[a],Blockly.Python.ORDER_ATOMIC]};Blockly.Python.math={};Blockly.Python.math_number=function(a){a=parseFloat(a.getFieldValue("NUM"));isNaN(a)&&(a=0);return[a,0>a?Blockly.Python.ORDER_UNARY_SIGN:Blockly.Python.ORDER_ATOMIC]};Blockly.Python.math_integer=Blockly.Python.math_number;Blockly.Python.math_whole_number=Blockly.Python.math_number;Blockly.Python.math_positive_number=Blockly.Python.math_number;Blockly.Python.math_angle=Blockly.Python.math_number;Blockly.Python.math_int8_number=Blockly.Python.math_number;
Blockly.Python.math_uint8_number=Blockly.Python.math_number;Blockly.Python.math_int9_number=Blockly.Python.math_number;Blockly.Python.math_uint10_number=Blockly.Python.math_number;Blockly.Python.math_int11_number=Blockly.Python.math_number;Blockly.Python.math_0to100_number=Blockly.Python.math_number;Blockly.Python.math_half_angle=Blockly.Python.math_number;Blockly.Python.data={};Blockly.Python.data_variable=function(a){return[Blockly.Python.variableDB_.getName(a.getFieldValue("VARIABLE"),Blockly.Variables.NAME_TYPE),Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.data_setvariableto=function(a){var b=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("VARIABLE"),Blockly.Variables.NAME_TYPE);if("unnamed"===a)return"";parseFloat(b.slice(1,-1))==b.slice(1,-1)&&(b=parseFloat(b.slice(1,-1)).toString());return a+" = "+b+"\n"};
Blockly.Python.data_changevariableby=function(a){var b=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("VARIABLE"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":a+" += "+b+"\n"};Blockly.Python.data_showvariable=function(){return""};Blockly.Python.data_hidevariable=function(){return""};
Blockly.Python.data_listcontents=function(a){return[Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE),Blockly.Python.ORDER_ATOMIC]};Blockly.Python.data_addtolist=function(a){var b=Blockly.Python.valueToCode(a,"ITEM",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":a+".append("+b+")\n"};
Blockly.Python.data_deleteoflist=function(a){var b=Blockly.Python.valueToCode(a,"INDEX",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":"del "+a+"["+b+" - 1]\n"};Blockly.Python.data_deletealloflist=function(a){a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":"del "+a+"[0:]\n"};
Blockly.Python.data_insertatlist=function(a){var b=Blockly.Python.valueToCode(a,"ITEM",Blockly.Python.ORDER_ADDITIVE)||"0",c=Blockly.Python.valueToCode(a,"INDEX",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":a+".insert("+c+" - 1, "+b+")\n"};
Blockly.Python.data_replaceitemoflist=function(a){var b=Blockly.Python.valueToCode(a,"ITEM",Blockly.Python.ORDER_ADDITIVE)||"0",c=Blockly.Python.valueToCode(a,"INDEX",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return"unnamed"===a?"":a+"["+c+" - 1] = "+b+"\n"};
Blockly.Python.data_itemoflist=function(a){var b=Blockly.Python.valueToCode(a,"INDEX",Blockly.Python.ORDER_ADDITIVE)||"0";return[Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE)+"["+b+" - 1]",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.data_itemnumoflist=function(a){var b=Blockly.Python.valueToCode(a,"ITEM",Blockly.Python.ORDER_ADDITIVE)||"0";return[Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE)+".index("+b+") + 1",Blockly.Python.ORDER_UNARY_SIGN]};
Blockly.Python.data_lengthoflist=function(a){return["len("+Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE)+")",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.data_listcontainsitem=function(a){var b=Blockly.Python.valueToCode(a,"ITEM",Blockly.Python.ORDER_ADDITIVE)||"0";a=Blockly.Python.variableDB_.getName(a.getFieldValue("LIST"),Blockly.Variables.NAME_TYPE);return[""+b+" in "+a,Blockly.Python.ORDER_RELATIONAL]};Blockly.Python.data_showlist=function(){return""};
Blockly.Python.data_hidelist=function(){return""};Blockly.Python.colour={};Blockly.Python.colour_picker=function(a){return[a.getFieldValue("COLOUR"),Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit={};Blockly.Python.microbit_pin_setDigitalOutput=function(a){var b=a.getFieldValue("PIN");a=Blockly.Python.valueToCode(a,"LEVEL",Blockly.Python.ORDER_FUNCTION_CALL)||"LOW";return"pin"+b+".write_digital("+a+")\n"};Blockly.Python.microbit_pin_menu_level=function(a){return[a.getFieldValue("level")||"0",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_pin_setPwmOutput=function(a){var b=a.getFieldValue("PIN");a=Blockly.Python.valueToCode(a,"OUT",Blockly.Python.ORDER_FUNCTION_CALL)||"0";return"pin"+b+".write_analog("+a+")\n"};Blockly.Python.microbit_pin_readDigitalPin=function(a){return["pin"+(a.getFieldValue("PIN")||"0")+".read_digital()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_pin_readAnalogPin=function(a){return["pin"+(a.getFieldValue("PIN")||"0")+".read_analog()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_pin_pinTouched=function(a){return["pin"+(a.getFieldValue("PIN")||"0")+".is_touched()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_display_showImage=function(a){a=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ATOMIC)||"0";a=a.replace(/1/g,"9");a=a.slice(0,5)+":"+a.slice(5,10)+":"+a.slice(10,15)+":"+a.slice(15,20)+":"+a.slice(20,25);return"display.show(Image('"+a+"'))\n"};
Blockly.Python.microbit_display_showImageUntil=function(a){var b=Blockly.Python.valueToCode(a,"VALUE",Blockly.Python.ORDER_ATOMIC)||"0";a=Blockly.Python.valueToCode(a,"TIME",Blockly.Python.ORDER_ATOMIC)||"0";b=b.replace(/1/g,"9");b=b.slice(0,5)+":"+b.slice(5,10)+":"+b.slice(10,15)+":"+b.slice(15,20)+":"+b.slice(20,25);return"display.show(Image('"+b+"'))\nsleep(float("+a+") * 1000)\ndisplay.clear()\n"};
Blockly.Python.microbit_display_show=function(a){return"display.scroll(str("+(Blockly.Python.valueToCode(a,"TEXT",Blockly.Python.ORDER_FUNCTION_CALL)||"")+"), wait=False, loop=False)\n"};Blockly.Python.microbit_display_showUntilScrollDone=function(a){return"display.scroll(str("+(Blockly.Python.valueToCode(a,"TEXT",Blockly.Python.ORDER_FUNCTION_CALL)||"")+"), wait=True, loop=False)\n"};Blockly.Python.microbit_display_clearDisplay=function(){return"display.clear()\n"};
Blockly.Python.microbit_display_lightPixelAt=function(a){var b=a.getFieldValue("STATE"),c=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_FUNCTION_CALL)||"";a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_FUNCTION_CALL)||"";return"display.set_pixel(int("+c+"), int("+a+"), "+("off"===b?0:9)+")\n"};
Blockly.Python.microbit_display_showOnPiexlbrightness=function(a){var b=Blockly.Python.valueToCode(a,"BRT",Blockly.Python.ORDER_FUNCTION_CALL)||"9",c=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_FUNCTION_CALL)||"";a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_FUNCTION_CALL)||"";return"display.set_pixel(int("+c+"), int("+a+"), "+b+")\n"};Blockly.Python.microbit_display_menu_ledBrightness=function(a){return[a.getFieldValue("ledBrightness")||"9",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_sensor_buttonIsPressed=function(a){return["button_"+a.getFieldValue("KEY")+".is_pressed()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_gestureIsX=function(a){return["accelerometer.is_gesture('"+a.getFieldValue("STA")+"')",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_axisAcceleration=function(a){return["accelerometer.get_"+a.getFieldValue("AXIS")+"()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_sensor_compassAngle=function(){return["compass.heading()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_compassMagneticDensity=function(){return["compass.get_field_strength()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_calibrateCompass=function(){return["compass.calibrate()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_lightLevel=function(){return["display.read_light_level()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_sensor_temperature=function(){return["temperature()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_sensor_runningTime=function(){return["running_time()",Blockly.Python.ORDER_ATOMIC]};Blockly.Python.microbit_wireless_openWirelessCommunication=function(){Blockly.Python.imports_.radio="import radio";return"radio.on()\n"};Blockly.Python.microbit_wireless_closeWirelessCommunication=function(){Blockly.Python.imports_.radio="import radio";return"radio.off()\n"};
Blockly.Python.microbit_wireless_resetWirelessCommunication=function(){Blockly.Python.imports_.radio="import radio";return"radio.reset()\n"};Blockly.Python.microbit_wireless_sendWirelessMessage=function(a){Blockly.Python.imports_.radio="import radio";return"radio.send(str("+(Blockly.Python.valueToCode(a,"TEXT",Blockly.Python.ORDER_FUNCTION_CALL)||"")+"))\n"};Blockly.Python.microbit_wireless_receiveWirelessMessage=function(){Blockly.Python.imports_.radio="import radio";return["radio.receive()",Blockly.Python.ORDER_ATOMIC]};
Blockly.Python.microbit_wireless_setWirelessCommunicationChannel=function(a){Blockly.Python.imports_.radio="import radio";return"radio.config(channel = "+a.getFieldValue("CH")+")\n"};Blockly.Python.microbit_console_consolePrint=function(a){return"print("+(Blockly.Python.valueToCode(a,"TEXT",Blockly.Python.ORDER_FUNCTION_CALL)||"")+")\n"};Blockly.Python.engine={};Blockly.Python.left_right_clock_second=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=a.getFieldValue("DIRECTION"),c=a.getFieldValue("CLOCK");a=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);return"Engine.motor_direction("+("left"==b?0:1)+", "+("clockwise"==c?0:1)+", "+a+")\n"};
Blockly.Python.left_right_clock_power=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=a.getFieldValue("DIRECTION"),c=a.getFieldValue("CLOCK");a=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);b="left"==b?0:1;c="clockwise"==c?0:1;a=a.replace("(","");a=a.replace(")","");a=parseInt(a);100<a&&(a=100);0>a&&(a=0);return"Engine.motor_power("+b+", "+c+", "+a+")\n"};
Blockly.Python.forward_second=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=Blockly.Python.valueToCode(a,"power",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);console.log(b);b=b.replace("(","");b=b.replace(")","");b=parseInt(b);console.log(b);0>b?(b=Math.abs(b),100<b&&(b=100),b="Engine.motor_backward_at_power("+b+","+a+")\n"):(100<b&&(b=100),b="Engine.motor_forward_at_power("+
b+","+a+")\n");return b};
Blockly.Python.backward_second=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);b=b.replace("(","");b=b.replace(")","");b=parseInt(b);0>b?(b=Math.abs(b),100<b&&(b=100),b="Engine.motor_forward_at_power("+b+","+a+")\n"):(100<b&&(b=100),b="Engine.motor_backward_at_power("+b+","+a+")\n");
return b};Blockly.Python.turnleft_until=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";return"Engine.turn(0,"+Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.turnright_until=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";return"Engine.turn(1,"+Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.hareket_menu_git=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=a.getFieldValue("VALUE");a=Blockly.Python.valueToCode(a,"SPEED",Blockly.Python.ORDER_ATOMIC);a=a.replace("(","");a=a.replace(")","");a=parseInt(a);"forward"==b?(100<a&&(a=100),0>a?(a=Math.abs(a),100<a&&(a=100),b="Engine.motor_backward_at_power("+a+")\n"):(100<a&&(a=100),b="Engine.motor_forward_at_power("+a+")\n")):(100<a&&(a=100),
0>a?(a=Math.abs(a),100<a&&(a=100),b="Engine.motor_forward_at_power("+a+")\n"):b="Engine.motor_backward_at_power("+a+")\n");return b};
Blockly.Python.hareket_sol_sag_guc=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";var b=Blockly.Python.valueToCode(a,"X",Blockly.Python.ORDER_ATOMIC);a=Blockly.Python.valueToCode(a,"Y",Blockly.Python.ORDER_ATOMIC);b=b.replace("(","");b=b.replace(")","");b=parseInt(b);a=a.replace("(","");a=a.replace(")","");a=parseInt(a);0>b&&(b=Math.abs(b),100<b&&(b=100));0>a&&(a=Math.abs(a),100<a&&(a=100));100<b&&(b=100);100<a&&
(a=100);return"Engine.seperated_motor_control(1,"+a+","+b+")\n"};Blockly.Python.hareket_durdur=function(a){Blockly.Python.imports_.motors="from mylib import ZBotMotors";Blockly.Python.setups_.motors="Engine = ZBotMotors.Motor()";return"Engine.stop_motors()\n"};Blockly.Python.hareket_cizgi_izleme=function(a){Blockly.Python.imports_.line_follower="from mylib import ZBotLineFollower";Blockly.Python.setups_.line_follower="LF = ZBotLineFollower.LineFollower()";return"LF.follow_line()\n"};Blockly.Python.feeling={};
Blockly.Python.feeling=function(a){Blockly.Python.setups_.feeling="Emotions = ZBotEmotions.Emotions()";Blockly.Python.setups_.feelingList="your_emotion_list = ['bilgin', 'bilgin2', 'havali', 'kizgin', 'korku', 'merakli', 'merakli2', 'mutlu', 'mutlu2', 'neseli', 'sevgi', 'sevgi2', 'saskin', 'utangac', 'uykulu', '\u00fczg\u00fcn']";Blockly.Python.imports_.feeling="from mylib import ZBotEmotions";var b="feeling";a=a.getFieldValue("VALUE");"bilgin"==a&&(b="bilgin");"havali"==a&&(b="havali");"merakli"==
a&&(b="merakli");"mutlu"==a&&(b="mutlu");"sevgi"==a&&(b="sevgi");"uykulu"==a&&(b="uykulu");"kizgin"==a&&(b="kizgin");"korku"==a&&(b="korku");"neseli"==a&&(b="neseli");"saskin"==a&&(b="saskin");"utangac"==a&&(b="utangac");"\u00fczg\u00fcn"==a&&(b="\u00fczg\u00fcn");return'Emotions.show_emotions("'+b+'")\n'};
Blockly.Python.dance=function(a){Blockly.Python.setups_.dance="Dancer = ZBotDance_asynch.Dance()";Blockly.Python.imports_.dance="from mylib import ZBotDance_asynch";a=a.getFieldValue("VALUE");var b="Dancer.tango()\n";"tango"==a&&(b="Dancer.tango()\n");"samba"==a&&(b="Dancer.samba()\n");"hip_hop"==a&&(b="Dancer.hip_hop()\n");"slow"==a&&(b="Dancer.slow()\n");return b};
Blockly.Python.songshow=function(a){Blockly.Python.setups_.sound2="Plyr = ZBotWavPlayer.WavPlayer()";Blockly.Python.imports_.sound2="from mylib import ZBotWavPlayer";return'Plyr.play_wav("'+a.getFieldValue("ADDR")+'")\n'};
Blockly.Python.lightshow=function(a){Blockly.Python.setups_.lightshow="LS = ZBotLightShow.LightShow()";Blockly.Python.imports_.lightshow="from mylib import ZBotLightShow";a=a.getFieldValue("VALUE");var b="LS.camp_fire()\n";"rainbow"==a&&(b="LS.rainbow()\n");"northern_lights"==a&&(b="LS.northern_lights()\n");"comet"==a&&(b="LS.comet()\n");"meteor_shower"==a&&(b="LS.meteor_shower()\n");"police_car"==a&&(b="LS.police_car()\n");"traffic_light"==a&&(b="LS.traffic_light()\n");"show_light"==a&&(b="LS.show_light()\n");
"camp_fire"==a&&(b="LS.camp_fire()\n");return b};Blockly.Python.move={};Blockly.Python.hareket_speed=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";a=a.getChildren()[0].getFieldValue("VALUE");var b="\n";console.log(a);1==a&&(b="Motors.set_speed(1)\n");2==a&&(b="Motors.set_speed(2)\n");3==a&&(b="Motors.set_speed(3)\n");4==a&&(b="Motors.set_speed(4)\n");return b};
Blockly.Python.hareket_speed_back=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";a=a.getChildren()[0].getFieldValue("VALUE");var b="\n";console.log(a);1==a&&(b="Motors.set_speed(1)\n");2==a&&(b="Motors.set_speed(2)\n");3==a&&(b="Motors.set_speed(3)\n");4==a&&(b="Motors.set_speed(4)\n");return b};
Blockly.Python.hareket_ileri=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.forward_motors(150,"+Blockly.Python.valueToCode(a,"SPEED",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.hareket_geri=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.backward_motors(150,"+Blockly.Python.valueToCode(a,"repeat",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.hareket_saga_don=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.turn_x_times(1,"+Blockly.Python.valueToCode(a,"repeat",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.hareket_sola_don=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.turn_x_times(0,"+Blockly.Python.valueToCode(a,"repeat",Blockly.Python.ORDER_ATOMIC)+")\n"};
Blockly.Python.hareket_dur=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.stop_motors()\n"};
Blockly.Python.hareket_ileri_sonsuz=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.forward_motors()\n"};
Blockly.Python.hareket_geri_sonsuz=function(a){Blockly.Python.imports_.ztech_motors="from mylib import ZBotMotors";Blockly.Python.setups_.ztech_motors="Motors = ZBotMotors.Motor()";Blockly.Python.setups_.ztech_motors_init="Motors.initialize()";return"Motors.backward_motors()\n"};Blockly.Python.texts={};Blockly.Python.text=function(a){return[Blockly.Python.quote_(a.getFieldValue("TEXT")),Blockly.Python.ORDER_ATOMIC]};
