
import PropTypes from 'prop-types';
import styles from './monitor.css';
import classNames from "classnames";
import omit from "lodash.omit";
import React, { useEffect, useState } from "react";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import tabStyles from "react-tabs/style/react-tabs.css";
import VM from "../../../mymodule/ztech-vm";
import Renderer from "scratch-render";
import Box from "../box/box.jsx";

import OpcodeLabels from '../../lib/opcode-labels';



const MonitorDataComponent = (props) => {
    const {
        label,
        value,
        vm,
        isRealtimeMode,
        onSetJrchange,
        isjr,
        ...componentProps
    } = omit(props, "dispatch");
   
   
   
    

    OpcodeLabels.setTranslatorFunction(props.intl.formatMessage);
    


    return (
        <Box>
        {props.monitors.valueSeq()
            .map(monitorData => {
                
                return <div className={styles.defaultMonitor}>
                    <div className={styles.row}>
                        <div className={styles.label}>
                        {OpcodeLabels.getLabel(monitorData.opcode).labelFn()}
                        </div>
                        <div className={styles.value}>
                            {monitorData.value}
                            
                        </div>
                    </div>
                </div>
        })}
            </Box>
       
    );
};




const mapStateToProps = (state) => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    peripheralName: state.scratchGui.connectionModal.peripheralName,
    monitors: state.scratchGui.monitors

});

export default injectIntl(connect(mapStateToProps)(MonitorDataComponent));
