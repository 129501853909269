
let isconnect = 0;
let stage = true;


class DeviceConnectGui {

    static setConnect (data) {
        isconnect = data;
    }
    
    static getConnect () {
        return isconnect; 
    }

    static setStage (data) {
        stage = data;
    }
    
    static getStage () {
        return stage; 
    }

    
}

module.exports = DeviceConnectGui;
