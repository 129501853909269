// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import moment from 'moment';

import {
    getDatabase,
    onValue,
    increment,
    ref,
    remove,
    set,
    update,
    getKey,
    push,
    child
  } from "firebase/database";

  import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export const serverStamp = firebase.firestore.Timestamp

  import {
    getStorage,
    getDownloadURL,
    ref as storageRef,
    uploadBytes,
  } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAkl-YoWWz-DBnz8bCQXodZ6qiCPE0lcR0",
  authDomain: "ztech-gui.firebaseapp.com",
  databaseURL: "https://ztech-gui-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ztech-gui",
  storageBucket: "ztech-gui.appspot.com",
  messagingSenderId: "1047225430434",
  appId: "1:1047225430434:web:24d4e453f9aaa7defa82fb",
  measurementId: "G-W7ZGDYR8L1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);


export const useFetch = async (name=null, email=null,feedback=null, image=null) => {
   
  const db = getDatabase();
  const newPostKey = push(child(ref(db), 'feedback')).key;

  const postRef = ref(db, 'feedback/'+newPostKey);
  var imageUrl = null;

  if(image != ''){
    imageUrl = await uploadFile(image,newPostKey);
  }


  await set(postRef, {
    username: name,
    email: email,
    feedback:feedback,
    image : image != '' ? imageUrl : "",
    date: new Date(serverStamp.now().seconds*1000).toLocaleString()
    
  });

    return true;
  };


  const uploadFile = async (imageUpload,newPostKey) => {
   
    const storage = getStorage();
    const imageRef = storageRef(storage, `feedImage/${newPostKey}`);
    var snapshot = await uploadBytes(imageRef, imageUpload);
    var url = await getDownloadURL(snapshot.ref);
   
    return url;
  };