import React from "react";
import classNames from "classnames";
import styles from "../stage-modal.css";

const KidsSubmenu = ({ kidsSayfa, changeStage, closeModal, isActive, category }) => {
    console.log("test", kidsSayfa, category)
    
 if (category) return (
        <div
            className={classNames(
                styles.container,
                styles.lastMenu
            )}
        >
            <div
                className={classNames(styles.selectBtn, styles.btnSm, { [styles.btnDisabled]: !isActive((kidsSayfa * 5) + 1, category)})}
                onClick={() => {
                    if (isActive((kidsSayfa * 5) + 1, category)) {
                        changeStage(category + "_" + ((kidsSayfa * 5) + 1));
                        closeModal();
                    }
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {kidsSayfa * 5 + 1}
                </p>
            </div>
            {(kidsSayfa * 5) + 5 < 12 && <div
                className={classNames(styles.selectBtn, styles.btnSm, { [styles.btnDisabled]: !isActive((kidsSayfa * 5) + 2, category)})}
                onClick={() => {
                    if (isActive((kidsSayfa * 5) + 2, category)) {
                        changeStage(category + "_" + ((kidsSayfa * 5) + 2));
                        closeModal();
                    }
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {kidsSayfa * 5 + 2}
                </p>
            </div>}
            {(kidsSayfa * 5) + 5 < 12 && <div
                className={classNames(styles.selectBtn, styles.btnSm, { [styles.btnDisabled]: !isActive((kidsSayfa * 5) + 3, category)})}
                onClick={() => {
                    if (isActive((kidsSayfa * 5) + 3, category)) {
                        changeStage(category + "_" + ((kidsSayfa * 5) + 3));
                        closeModal();
                    }
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {kidsSayfa * 5 + 3}
                </p>
            </div>}
            {(kidsSayfa * 5) + 5 < 12 && <div
                className={classNames(styles.selectBtn, styles.btnSm, { [styles.btnDisabled]: !isActive((kidsSayfa * 5) + 4, category)})}
                onClick={() => {
                    if (isActive((kidsSayfa * 5) + 4, category)) {
                        changeStage(category + "_" + ((kidsSayfa * 5) + 4));
                        closeModal();
                    }
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {kidsSayfa * 5 + 4}
                </p>
            </div>}
            {(kidsSayfa * 5) + 5 < 12 && <div
                className={classNames(styles.selectBtn, styles.btnSm, { [styles.btnDisabled]: !isActive((kidsSayfa * 5) + 5, category)})}
                onClick={() => {
                    if (isActive((kidsSayfa * 5) + 5, category)) {
                        changeStage(category + "_" + ((kidsSayfa * 5) + 5));
                        closeModal();
                    }
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {kidsSayfa * 5 + 5}
                </p>
            </div>}

            {/* <p className={styles.title}>
            Şekiller {kidsSayfa + 1}
            </p> */}
        </div>
    );
};

export default KidsSubmenu;
