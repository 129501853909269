import React from "react";
import { urlContains } from "selenium-webdriver/lib/until";
import DeviceSensorData from '../../../mymodule/ztech-vm/src/util/deviceSensorData';
import ReadDeviceData from '../../../mymodule/ztech-vm/src/util/deviceDataRead';

import styles from './oyun.css';
import classNames from 'classnames';
import {connect} from 'react-redux';


const Slider = ({vm,peripheralName,  range, setRange, title }) => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <div
            className={classNames(styles.sliderContainer)}                
            >
               <input
                    type="range"
                    min="0"
                    max="100"
                    value={range}
                    onChange={(e) => {
                      setRange(e.target.value);
                      if (title === "Light Level") {
                       // console.log(vm);
                        if(peripheralName == null){
                        vm.runtime.startHats('game_when_detect_light', {
                        light_intensity: e.target.value
                        });
                        }
                      }
                      if (title === "Sound Level") {
                        //console.log("Sound Level changed"+e.target.value);
                        //console.log(peripheralName);

                        if(peripheralName == null){
                          vm.runtime.startHats('game_detect_sound_intensity', {
                            sound_intensity: e.target.value
                          });
                        }  

                       // ReadDeviceData.setSoundLevel(parseInt(e.target.value));
                       // DeviceSensorData.setSoundLevel(parseInt(e.target.value));
                      }
                      if (title === "Shake Level") {
                       
                        console.log("Shake Level"+e.target.value);
                       // DeviceSensorData.setShakeLevel(parseInt(e.target.value));
                      }
                    }}
                    id="myRange"
                    // orient="vertical"
                    step={1}
                    color="red"
                    className={classNames(styles.slider)}
                    style={{
                        background: `linear-gradient(to right, #008e2f 0%, #008e2f ${range}%, #ffffff ${range}%, #ffffff 100%)`,
                    }}
                />
            </div>
                <span className={classNames(styles.sliderValue)}>{range}</span>
        </div>
    );
};


const mapStateToProps = (state, ownProps) => {
  
  return {
    peripheralName: state.scratchGui.connectionModal.peripheralName,

   
     
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
     
  }
}

const SliderConnect =  connect(
  mapStateToProps,
  mapDispatchToProps
)(Slider);
export {
  SliderConnect as default
};