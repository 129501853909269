const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameLedBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameLedMatrixBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            ztech_ledmatrix:this.ztech_ledmatrix,
            heart_emotion:this.heart_emotion,
            aa_text:this.aa_text,
            off:this.off,
            zz_led_matrix_saniye:this.zz_led_matrix_saniye,
            zz_led_matrix:this.zz_led_matrix,
            zz_led_matrix_ekrana_ciz:this.zz_led_matrix_ekrana_ciz,
            led_matrix_kapat:this.led_matrix_kapat,
            led_matrix_yazdır:this.led_matrix_yazdır,
            led_matrix_kaydırarak_yazdır:this.led_matrix_kaydırarak_yazdır,
            led_yak_x_y:this.led_yak_x_y,
            led_kapat_x_y:this.led_kapat_x_y,
            goz_kırp_x_y:this.goz_kırp_x_y


        };
    }

    async ztech_ledmatrix(args,util){
        console.log(args);
        const matrixBinary = args.MATRIX;
        var device = ConnectedDeviceClass.getDevice();  
        var satir1 = matrixBinary.substring(0, 16);
        var satir2 = matrixBinary.substring(16, 32);
        var satir3 = matrixBinary.substring(32, 48);
        var satir4 = matrixBinary.substring(48, 64);
        var satir5 = matrixBinary.substring(64, 80);
        var satir6 = matrixBinary.substring(80, 96);
        var satir7 = matrixBinary.substring(96, 112);
        var satir8 = matrixBinary.substring(112, 128);

      
        var hexa1 = parseInt(satir1, 2).toString(16).toUpperCase();
        var hexa2 = parseInt(satir2, 2).toString(16).toUpperCase();
        var hexa3 = parseInt(satir3, 2).toString(16).toUpperCase();
        var hexa4 = parseInt(satir4, 2).toString(16).toUpperCase();
        var hexa5 = parseInt(satir5, 2).toString(16).toUpperCase();
        var hexa6 = parseInt(satir6, 2).toString(16).toUpperCase();
        var hexa7 = parseInt(satir7, 2).toString(16).toUpperCase();
        var hexa8 = parseInt(satir8, 2).toString(16).toUpperCase();   
        console.log(hexa1);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);

        await device.write(`<< 4 d 113 0x${hexa1} 0x${hexa2} 0x${hexa3} 0x${hexa4} 0x${hexa5} 0x${hexa6} 0x${hexa7} 0x${hexa8} >>\n`);
    }
    async heart_emotion(args,util){
        console.log(args);
        var data = args.value;
        var device = ConnectedDeviceClass.getDevice();   
        console.log(data);  
        if(data==''){
            data="1";
        }
        await device.write(`<< 4 d 112 ${data} >>\n`);
        

    }
    async aa_text(args,util){
        console.log(args);
        const data = args.data;
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 4 d 107 ${data} 1 >>\n`);

        var currentClass =  Oyun.getClass();
        await currentClass.changeUpperText(data);


    }
    async off(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 4 d 115 >>\n");
        var currentClass =  Oyun.getClass();
        await currentClass.changeUpperText("");
    }

    async zz_led_matrix_saniye(args,util){
        console.log(args);
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
        const matrixBinary = args.MATRIX;
        var device = ConnectedDeviceClass.getDevice();  
        var satir1 = matrixBinary.substring(0, 16);
        var satir2 = matrixBinary.substring(16, 32);
        var satir3 = matrixBinary.substring(32, 48);
        var satir4 = matrixBinary.substring(48, 64);
        var satir5 = matrixBinary.substring(64, 80);
        var satir6 = matrixBinary.substring(80, 96);
        var satir7 = matrixBinary.substring(96, 112);
        var satir8 = matrixBinary.substring(112, 128);

      
        var hexa1 = parseInt(satir1, 2).toString(16).toUpperCase();
        var hexa2 = parseInt(satir2, 2).toString(16).toUpperCase();
        var hexa3 = parseInt(satir3, 2).toString(16).toUpperCase();
        var hexa4 = parseInt(satir4, 2).toString(16).toUpperCase();
        var hexa5 = parseInt(satir5, 2).toString(16).toUpperCase();
        var hexa6 = parseInt(satir6, 2).toString(16).toUpperCase();
        var hexa7 = parseInt(satir7, 2).toString(16).toUpperCase();
        var hexa8 = parseInt(satir8, 2).toString(16).toUpperCase();   
        console.log(hexa1);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);

        await device.write(`<< 4 d 113 0x${hexa1} 0x${hexa2} 0x${hexa3} 0x${hexa4} 0x${hexa5} 0x${hexa6} 0x${hexa7} 0x${hexa8} ${args.X} >>\n`);
    }

    async zz_led_matrix(args,util){
        console.log(args);
        const matrixBinary = args.MATRIX;
        var device = ConnectedDeviceClass.getDevice();  
        var satir1 = matrixBinary.substring(0, 16);
        var satir2 = matrixBinary.substring(16, 32);
        var satir3 = matrixBinary.substring(32, 48);
        var satir4 = matrixBinary.substring(48, 64);
        var satir5 = matrixBinary.substring(64, 80);
        var satir6 = matrixBinary.substring(80, 96);
        var satir7 = matrixBinary.substring(96, 112);
        var satir8 = matrixBinary.substring(112, 128);

      
        var hexa1 = parseInt(satir1, 2).toString(16).toUpperCase();
        var hexa2 = parseInt(satir2, 2).toString(16).toUpperCase();
        var hexa3 = parseInt(satir3, 2).toString(16).toUpperCase();
        var hexa4 = parseInt(satir4, 2).toString(16).toUpperCase();
        var hexa5 = parseInt(satir5, 2).toString(16).toUpperCase();
        var hexa6 = parseInt(satir6, 2).toString(16).toUpperCase();
        var hexa7 = parseInt(satir7, 2).toString(16).toUpperCase();
        var hexa8 = parseInt(satir8, 2).toString(16).toUpperCase();   
        console.log(hexa1);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);

        await device.write(`<< 4 d 113 0x${hexa1} 0x${hexa2} 0x${hexa3} 0x${hexa4} 0x${hexa5} 0x${hexa6} 0x${hexa7} 0x${hexa8} >>\n`);
    }

    async zz_led_matrix_ekrana_ciz(args,util){
        console.log(args);
        const matrixBinary = args.MATRIX;
        var device = ConnectedDeviceClass.getDevice();  
        var satir1 = matrixBinary.substring(0, 16);
        var satir2 = matrixBinary.substring(16, 32);
        var satir3 = matrixBinary.substring(32, 48);
        var satir4 = matrixBinary.substring(48, 64);
        var satir5 = matrixBinary.substring(64, 80);
        var satir6 = matrixBinary.substring(80, 96);
        var satir7 = matrixBinary.substring(96, 112);
        var satir8 = matrixBinary.substring(112, 128);

      
        var hexa1 = parseInt(satir1, 2).toString(16).toUpperCase();
        var hexa2 = parseInt(satir2, 2).toString(16).toUpperCase();
        var hexa3 = parseInt(satir3, 2).toString(16).toUpperCase();
        var hexa4 = parseInt(satir4, 2).toString(16).toUpperCase();
        var hexa5 = parseInt(satir5, 2).toString(16).toUpperCase();
        var hexa6 = parseInt(satir6, 2).toString(16).toUpperCase();
        var hexa7 = parseInt(satir7, 2).toString(16).toUpperCase();
        var hexa8 = parseInt(satir8, 2).toString(16).toUpperCase();   
        console.log(hexa1);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);
        console.log(hexa2);

        await device.write(`<< 4 d 113 0x${hexa1} 0x${hexa2} 0x${hexa3} 0x${hexa4} 0x${hexa5} 0x${hexa6} 0x${hexa7} 0x${hexa8} 0 ${args.X} ${args.Y} >>\n`);
    }

    async led_matrix_kapat(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 4 d 115 >>\n");
    }

    async led_matrix_yazdır(args,util){
        console.log(args);
        var data =Cast.toString(args.X);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 4 d 106 ${data} >>\n`);
    }

    async led_matrix_kaydırarak_yazdır(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice(); 
        var data =Cast.toString(args.X);

        await device.write(`<< 4 d 107 ${data} 1 >>\n`);
    }

    async led_yak_x_y(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        var x = Cast.toNumber(args.X);
        var y = Cast.toNumber(args.Y);
        if(x>15){
            x=15;
        }
        if(x<0){
            x=0;
        }
        if(y>7){
            y=7;
        }
        if(y<0){
            y=0;
        }
        await device.write(`<< 4 d 102 ${x} ${y} >>\n`);
    }

    async led_kapat_x_y(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        var x = Cast.toNumber(args.X);
        var y = Cast.toNumber(args.Y); 
        if(x>15){
            x=15;
        }
        if(x<0){
            x=0;
        }
        if(y>7){
            y=7;
        }
        if(y<0){
            y=0;
        } 
        await device.write(`<< 4 d 103 ${x} ${y} >>\n`);
    }

    async goz_kırp_x_y(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice(); 
        var x = Cast.toNumber(args.X);
        var y = Cast.toNumber(args.Y); 
        if(x>15){
            x=15;
        }
        if(x<0){
            x=0;
        }
        if(y>7){
            y=7;
        }
        if(y<0){
            y=0;
        }     
        await device.write(`<< 4 d 105 ${x} ${y} >>\n`);
    }

   
}

module.exports = GameLedMatrixBlocks;
