import Modal from "react-modal";
import React, { useEffect } from "react";

import classNames from "classnames";
import styles from "./projects-modal.css";

import { FormattedMessage } from "react-intl";
import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import zBot from './zbot.png';
import deleteIcon from './icon--delete.svg';
import editIcon from './replace.png';


import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import log from '../../lib/log';
import sharedMessages from '../../lib/shared-messages.js';
import MessageBoxType from '../../lib/message-box.js';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../../reducers/project-state';




import {setProjectTitle} from '../../reducers/project-title';
import {
    openLoadingProject,
    closeLoadingProject
} from '../../reducers/modals';
import {
    closeFileMenu
} from '../../reducers/menus';
import { event } from "react-ga";
import { deactivateCustomProcedures } from "../../reducers/custom-procedures";


Modal.setAppElement("body");

const messages = defineMessages({
    title: {
        defaultMessage: 'Record Sound',
        description: 'Recording modal title',
        id: 'gui.recordModal.title'
    }
});


var fileToUpload=null;
var inputElement=null;
var fileReader=null;

function StageModalComponent({
    isOpen,
    onRequestClose,
    isRtl,
    userData,
    intl,
    isShowingWithoutId,
    loadingState,
    projectChanged,
    userOwnsProject,
    onLoadingStarted,
    vm,
    onShowMessageBox,
    onLoadingClose,
 
}) {
    const [filterQuery, setFilterQuery] = React.useState("");
    const [projects, setProjects] = React.useState([]);
    const [filteredProjects, setFilteredProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [page, setPage] = React.useState(1);
    

    const getProjects = () => {
        setLoading(true);
        setError(null);
        fetch(`https://api.ztech.ist/z-code/file?userId=${userData.id}`, {
            method: 'GET',
            headers: {
                'x-auth': userData.token,
            },
        }).then(res => res.json()).then(res => {
            console.log("responsss",res);
            setProjects(res);
            setLoading(false);
        }
        ).catch(err => {
            console.log("responsss",err);
            setError(err);
            setLoading(false);
        }
        );
    }

    useEffect(() => {
        if (userData?.id && isOpen === true) getProjects();
        return () => {
            setProjects([]);
            setFilteredProjects([]);
            setPage(1);
            setFilterQuery("");
        }
    }, [userData?.id, isOpen]);

    useEffect(() => {
       // alert("güncellendi");
       // handleFinishedLoadingUpload();
         console.log("use effect");
       },[getIsLoadingUpload(loadingState)]);

    useEffect(() => {
        setFilteredProjects(projects?.filter(project => project.file.key.split("_")[1].split(".")[0].toLowerCase().includes(filterQuery)));
    }, [filterQuery, projects]);

    console.log("projects",projects, userData);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundImage: `url(${getBgImage(subMenuShow, kidsSubMenu)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 20,
            backgroundColor: "#fff",
            border: "none",
            boxSizing: "border-box",
            width: 950,
            height: 700,
            overflow: "hidden",
        },
        overlay: {
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
    };

    const handleFilterChange = (event) => {
        setFilterQuery(event.target.value);
        setPage(1);
    }


    const handleDelete = (id) => {
        setLoading(true);
        setError(null);
        fetch(`https://api.ztech.ist/z-code/file/${id}`, {
            method: 'Delete',
            headers: {
                'x-auth': userData.token,
            },
        }).then(res => res.json()).then(res => {
            console.log("responsss",res);
            getProjects();
        }
        ).catch(err => {
            console.log("responsss",err);
            setError(err);
            setLoading(false);
        }
        );
    }

   
    const handleClick = (fileUrl) => {
        console.log("fileUrl",fileUrl);
        myhandleChange(fileUrl);
    }


    const createFileObjects = (url) => {
        // redo step 7, in case it got skipped last time and its objects are
        // still in memory
        removeFileObjects();
        // create fileReader
        fileReader = new FileReader();
        
        fileReader.onload = onload();
        myhandleChange(url);
        // create <input> element and add it to DOM
       
       // inputElement.onchange = myhandleChange(url); // connects to step 3
       // document.body.appendChild(inputElement);
        // simulate a click to open file chooser dialog
      //  inputElement.click();
    }

   const myhandleChange = async (url) => {
    removeFileObjects();
    fileReader = new FileReader();
            //console.log(onRequestClose);
            let zcodeFile = url;
            let blob = await fetch(zcodeFile)
            .then(r => r.blob());
            console.log("blob",blob)
            let metadata = {
                type: 'archive/zip'
            };
            let file = new File([blob], "zcode.zcode", metadata);
            console.log("file",file);
            fileToUpload = file; // burada "thisFileInput.files[0]" yerine üst satırdaki "file" yazıldığında serverdaki dosyayı açar

           
            // create fileReader
            fileReader.onload = onload;

            fileReader.readAsArrayBuffer(fileToUpload);
            
            console.log("this.fileReader.result",fileReader)

         
            //handleFinishedLoadingUpload();
            // If user owns the project, or user has changed the project,
            // we must confirm with the user that they really intend to
            // replace it. (If they don't own the project and haven't
            // changed it, no need to confirm.)
            let uploadAllowed = true;
            /*
            if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                uploadAllowed = onShowMessageBox(MessageBoxType.confirm,
                    intl.formatMessage(sharedMessages.replaceProjectWarning));
            }
            */
            if (uploadAllowed) {
                // cues step 4
                requestProjectUpload(loadingState);
            } else {
                // skips ahead to step 7
                removeFileObjects();
            }
            closeFileMenu();
    }



    const onload = async () => {

        if (fileReader) {
            onRequestClose();

            onLoadingStarted();         
            const filename = fileToUpload && fileToUpload.name;
            let loadingSuccess = false;
            console.log("this.fileReader.result",fileReader)
            vm.loadProject(fileReader.result)
                .then(() => {
                    if (filename) {
                        const uploadedProjectTitle = getProjectTitleFromFilename(filename);
                        setProjectTitle(uploadedProjectTitle);
                    }
                    loadingSuccess = true;
                })
                .catch(error => {
                    log.warn(error);
                    onShowMessageBox(MessageBoxType.alert,
                        `${intl.formatMessage(messages.loadError)}\n${error}`);
                })
                .then(() => {
                    console.log("///////////////////////////////*******************EMREALI");
                    console.log(loadingState);
                 
                    console.log("--------------------------------------------------------------");
                   // onLoadedProject('LOADING_VM_FILE_UPLOAD',true, true);
                   onLoadingClose();
                    // go back to step 7: whether project loading succeeded
                    // or failed, reset file objects
                    
                    removeFileObjects();
                });
        }
    }
    console.log("filteredProjects",filteredProjects.length, page, loading, error);

   const getProjectTitleFromFilename = (fileInputFilename) => {
        if (!fileInputFilename) return '';
        // only parse title with valid scratch project extensions
        // (.zcode .sb, .sb2, and .sb3)
        const matches = fileInputFilename.match(/^(.*)\.((zcode)|(sb[23]))?$/);
        if (!matches) return '';
        return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }

    const handleFinishedLoadingUpload = () => {
        if (fileToUpload && fileReader) {
            // begin to read data from the file. When finished,
            // cues step 6 using the reader's onload callback
            fileReader.readAsArrayBuffer(fileToUpload);
        } else {
            onLoadedProject(loadingState, false, false);
            // skip ahead to step 7
            onRequestClose();
            removeFileObjects();
        }
    }

    const removeFileObjects = ()  => {
        if (inputElement) {
            inputElement.value = null;
            document.body.removeChild(inputElement);
        }
        inputElement = null;
        fileReader = null;
        fileToUpload = null;
    }

    
   
   return  <div>
    <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel={messages.title}
    >
        <div className={styles.filterBar}>
                    <input
                        className={classNames(styles.filterInput, styles.filter)}
                        placeholder="Arama"
                        type="text"
                        value={filterQuery}
                        onChange={handleFilterChange}
                    />
                    <div
            className={classNames(
                styles.headerItem,
                styles.headerItemClose
            )}
        >
                <Button
                    className={styles.backButton}
                    // iconSrc={backIcon}
                    onClick={onRequestClose}
                >
                    <FormattedMessage
                        defaultMessage="Back"
                        description="Back button in modal"
                        id="gui.modal.back"
                    />
                </Button>
        </div>
            </div>
               <Box
    dir={isRtl ? 'rtl' : 'ltr'}
    direction="column"
    grow={1}
    
>
   
    {loading && <div  className={styles.message}>
        <FormattedMessage
            defaultMessage="Loading..."
            description="Loading projects"
            id="gui.menuBar.loadingProjects"
        />
        </div>}
    
    {error && <div className={styles.message}>
        <FormattedMessage
            defaultMessage="Error"
            description="Error"
            id="gui.menuBar.errorProjects"
        />
        </div>}
    {(!loading && error === null) ? filteredProjects?.length > 0 ? <div className={styles.body}>
        <div 
        className={classNames(styles.projectsScrollGrid)}
        >
            {filteredProjects.slice((page - 1) * 10, page * 10).map((project, index) => (
                <div key={index} className={styles.projectItem}>
                    <img src={zBot} alt={project.file.key} 
                    onClick={() => {
                        handleClick(project.file.url);
                    }
                    }
                    />
                    <div className={styles.projectItemDesc}>
                    <div className={styles.projectItemTitle}>
                        {project.file.key.split("_")[1].split(".")[0]}
                    </div>
                    <div className={styles.projectItemBtns}>
                        
                        <button
                            className={styles.projectItemButton}
                            onClick={() => handleDelete(project._id)}
                            title="Sil"
                        >
                            <img src={deleteIcon} alt="delete" width={20} height={20} />
                        </button>
                        {/* <button
                            className={styles.projectItemButton}
                            // onClick={() => handleRename(project._id)}
                            title="Yeniden Adlandır"
                        >
                            <img src={editIcon} alt="edit" width={20} height={20} color="black"/>
                        </button> */}
                    </div>
                    {/* <div className={styles.projectItemActions}>
                        <a
                            className={styles.projectItemButton}
                            href={project.file.url}
                        >
                            <FormattedMessage
                                defaultMessage="Download"
                                description="Download button in modal"
                                id="gui.modal.download"
                            />
                        </a>
                        <Button
                            className={styles.projectItemButton}
                            onClick={() => handleDelete(project._id)}
                        >
                            <FormattedMessage
                                defaultMessage="Delete"
                                description="Delete button in modal"
                                id="gui.modal.delete"
                            />
                        </Button>
                    </div> */}
                </div>
                </div>
            ))}
        </div>
    </div> : <div  className={styles.message}>
        <FormattedMessage
            defaultMessage="No projects found."
            description="No projects found"
            id="gui.menuBar.noProjectFound"
        />
    </div> : null}
    {filteredProjects?.length > 0 && <div className={styles.pagination}>
        <button
            className={classNames(styles.paginationItem, {[styles.disabled]: page === 1})}
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
        >{"<"}</button>
        {filteredProjects.length > 0 && Array.from(Array(Math.ceil(filteredProjects.length / 10)).keys()).map((item, index) => (
            <button 
                key={index}
                className={classNames(styles.paginationItem, {[styles.active]: page === index + 1})}
                onClick={() => setPage(index + 1)}
                disabled={page === index + 1}
            >{index + 1}</button>
        ))}
                
        <button 
            className={classNames(styles.paginationItem, {[styles.disabled]: filteredProjects.length < page * 10})}
            onClick={() => setPage(page + 1)}
            disabled={filteredProjects.length < page * 10}
        >{">"}</button>
    </div>}
    {/* {props.children} */}
</Box>
    </Modal>
</div>
   
    
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
       
     
       
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onLoadingClose: () => dispatch(closeLoadingProject()),
   
        onLoadingFinished: () => {
           
            dispatch(closeLoadingProject());
            dispatch(closeFileMenu());
        },
    }
  }

 const StageModalConnect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(StageModalComponent);
export {
    StageModalConnect as default
};



