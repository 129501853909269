import classNames from "classnames";
import omit from "lodash.omit";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import tabStyles from "react-tabs/style/react-tabs.css";
import VM from "../../../mymodule/ztech-vm";
import Renderer from "scratch-render";
// import {getDeviceId} from '../../reducers/device';

import Blocks from "../../containers/blocks.jsx";
import CostumeTab from "../../containers/costume-tab.jsx";
import TargetPane from "../../containers/target-pane.jsx";
import TargetPaneDevice from "../../containers/target-pane-device.jsx";

import SoundTab from "../../containers/sound-tab.jsx";
import StageWrapper from "../../containers/stage-wrapper.jsx";
import Loader from "../loader/loader.jsx";
import Box from "../box/box.jsx";
import OyunWrapper from "../oyun-wrapper/oyun-wrapper.jsx";
import MenuBar from "../menu-bar/menu-bar.jsx";
import CostumeLibrary from "../../containers/costume-library.jsx";
import ZcodeDevicesLibrary from "../../containers/zcodedevice-library.jsx";

//import ZcodeDeviceLibrary from "../../containers/zcode-device-library.jsx";

import BackdropLibrary from "../../containers/backdrop-library.jsx";
// import Watermark from "../../containers/watermark.jsx";
import Hardware from "../../containers/hardware.jsx";
import HardwareHeader from "../../containers/hardware-header.jsx";
import CodeUploadButton from "../../containers/code-upload-button.jsx";
import MonitorDataComponent from "../monitor-data/monitor.jsx";
import ReadDeviceData from "../../../mymodule/ztech-vm/src/util/deviceDataRead.js";

// eslint-disable-next-line no-unused-vars
// import Backpack from "../../containers/backpack.jsx";
import WebGlModal from "../../containers/webgl-modal.jsx";
import TipsLibrary from "../../containers/tips-library.jsx";
import Cards from "../../containers/cards.jsx";
import Alerts from "../../containers/alerts.jsx";
import DragLayer from "../../containers/drag-layer.jsx";
import ConnectionModal from "../../containers/connection-modal.jsx";
import UploadProgress from "../../containers/upload-progress.jsx";
import TelemetryModal from "../telemetry-modal/telemetry-modal.jsx";
import UpdateModal from "../../containers/update-modal.jsx";
import SwitchModeButton from "../switch-mode/switch-mode.jsx";

import layout, { STAGE_SIZE_MODES } from "../../lib/layout-constants";
import { resolveStageSize } from "../../lib/screen-utils";
import Monitor from '../../containers/monitor.jsx';

import styles from "./gui.css";
import addExtensionIcon from "./icon--extensions.svg";
import codeIcon from "./icon--code.svg";
import costumesIcon from "./icon--costumes.svg";
import soundsIcon from "./icon--sounds.svg";
import expandIcon from "./expand-icon.svg";
import MyWorkspaceClass from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass";
import Watermark from '../../containers/watermark.jsx';
import StageSelector from '../../containers/stage-selector.jsx';

// import { openConnectionModal } from "../../reducers/modals";
import { PlayBtn, StopBtn } from "./playStopBtn";
import SpriteBlocks from "../../containers/sprite-blocks.jsx";
import {
    
} from '../../reducers/editor-tab';

import { spriteTargetMode, deviceTargetMode } from "../../reducers/vertical-target-tab.js";

const messages = defineMessages({
    addExtension: {
        id: "gui.gui.addExtension",
        description: "Button to add an extension in the target pane",
        defaultMessage: "Add Extension",
    },
});

// Cache this value to only retrieve it once the first time.
// Assume that it doesn't change for a session.
let isRendererSupported = null;
let deviceAndSpriteTabsCount = 0;



const GUIComponent = (props) => {
    const {
        accountNavOpen,
        activeTabIndex,
        activeTargetTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        // eslint-disable-next-line no-unused-vars
        backpackHost,
        // eslint-disable-next-line no-unused-vars
        backpackVisible,
        blocksTabVisible,
        spritesTabVisible,
        cardsVisible,
        canChangeLanguage,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        uploadProgressVisible,
        costumeLibraryVisible,
        zcodeDeviceLibraryVisible,
        zcodeDevicesLibraryVisible,
        costumesTabVisible,
        updateModalVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onAbortUpdate,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onActivateTab,
        activateTargetTab,
        onOpenDeviceTabs,
        onClickLogo,
        onClickCheckUpdate,
        onClickUpdate,
        onClickClearCache,
        onClickInstallDriver,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseZcodeDeviceLibrary,
        onRequestCloseZcodeDevicesLibrary,
        onRequestCloseTelemetryModal,
        onSetVerticalModeSpritechange,
        onSetVerticalModeDevicechange,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onShowMessageBox,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        stageSizeMode,
        peripheralName,
        targetIsStage,
        telemetryModalVisible,
        tipsLibraryVisible,
        vm,
        isRealtimeMode,
        onSetJrchange,
        isjr,
        sprites,
        verticalTargetTab,
        gameDestroy,
        newProject,
        deviceChange,
        device,
        ...componentProps
    } = omit(props, "dispatch");
    if (children) {
        return <Box {...componentProps}>{children}</Box>;
    }

    const initialStageSize = () => {
        if (window.innerWidth < 1610) {
            return 550;
        } else {
            return 1100;                       
        }
    };
    const [stageWidth, setStageWidth] = useState(initialStageSize());
    const [showLeftBox, setShowLeftBox] = useState(false);

    const tabClassNames = {
        tabs: styles.tabs,
        tab: classNames(tabStyles.reactTabsTab, styles.tab),
        tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
        tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
        tabPanelSelected: classNames(
            tabStyles.reactTabsTabPanelSelected,
            styles.isSelected
        ),
        tabSelected: classNames(
            tabStyles.reactTabsTabSelected,
            styles.isSelected
        ),
    };

    if (isRendererSupported === null) {
        isRendererSupported = Renderer.isSupported();
    }

    
    if(activeTargetTabIndex == 0){
        if(Object.values(sprites).filter(x => x.isDevice==true).length == 1){
            vm.setEditingTarget(Object.values(sprites).filter(x => x.isDevice==true)[0].id);
        }
    }
   

    return (
        <MediaQuery minWidth={layout.fullSizeMinWidth}>
            {(isFullSize) => {
             
                const stageSize = resolveStageSize(stageSizeMode, isFullSize);
                console.log(
                    "components gui içindeki  return çalıştı isPlayerOnly: " +
                        isPlayerOnly
                );

                //    console.log('components gui içindeki  return çalıştı : '+isjr);
                return isPlayerOnly ? (
                    <StageWrapper
                        isFullScreen={isFullScreen}
                        isRendererSupported={isRendererSupported}
                        isRtl={isRtl}
                        loading={loading}
                        stageSize={STAGE_SIZE_MODES.large}
                        vm={vm}
                    >
                        {alertsVisible ? (
                            <Alerts
                                vm={vm}
                                className={styles.alertsContainer}
                            />
                        ) : null}
                    </StageWrapper>
                ) : (
                    <Box
                        className={styles.pageWrapper}
                        dir={isRtl ? "rtl" : "ltr"}
                        {...componentProps}
                    >
                        {telemetryModalVisible ? (
                            <TelemetryModal
                                isRtl={isRtl}
                                isTelemetryEnabled={isTelemetryEnabled}
                                onCancel={onTelemetryModalCancel}
                                onOptIn={onTelemetryModalOptIn}
                                onOptOut={onTelemetryModalOptOut}
                                onRequestClose={onRequestCloseTelemetryModal}
                                onShowPrivacyPolicy={onShowPrivacyPolicy}
                            />
                        ) : null}
                        {loading ? <Loader /> : null}
                        {isCreating ? (
                            <Loader messageId="gui.loader.creating" />
                        ) : null}
                        {isRendererSupported ? null : (
                            <WebGlModal isRtl={isRtl} />
                        )}
                        {tipsLibraryVisible ? <TipsLibrary /> : null}
                        {cardsVisible ? <Cards /> : null}
                        {alertsVisible ? (
                            <Alerts
                                vm={vm}
                                className={styles.alertsContainer}
                            />
                        ) : null}
                        {connectionModalVisible ? (
                            <ConnectionModal vm={vm} />
                        ) : null}
                        {uploadProgressVisible ? (
                            <UploadProgress vm={vm} />
                        ) : null}
                        {
                            costumeLibraryVisible ? (
                            <CostumeLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseCostumeLibrary}
                            />
                        ) : null }
                       
                        {zcodeDevicesLibraryVisible ? (
                            <ZcodeDevicesLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseZcodeDevicesLibrary}
                            />
                        ) : null}
                        {backdropLibraryVisible ? (
                            <BackdropLibrary
                                vm={vm}
                                onRequestClose={onRequestCloseBackdropLibrary}
                            />
                        ) : null}
                        {updateModalVisible ? (
                            <UpdateModal
                                vm={vm}
                                onAbortUpdate={onAbortUpdate}
                                onClickUpdate={onClickUpdate}
                                onShowMessageBox={onShowMessageBox}
                            />
                        ) : null}
                        <MenuBar
                            accountNavOpen={accountNavOpen}
                            authorId={authorId}
                            authorThumbnailUrl={authorThumbnailUrl}
                            authorUsername={authorUsername}
                            canChangeLanguage={canChangeLanguage}
                            canCreateCopy={canCreateCopy}
                            canCreateNew={canCreateNew}
                            canEditTitle={canEditTitle}
                            canManageFiles={canManageFiles}
                            canRemix={canRemix}
                            canSave={canSave}
                            canShare={canShare}
                            className={styles.menuBarPosition}
                            enableCommunity={enableCommunity}
                            isShared={isShared}
                            logo={logo}
                            renderLogin={renderLogin}
                            showComingSoon={showComingSoon}
                            onClickAbout={onClickAbout}
                            onClickAccountNav={onClickAccountNav}
                            onClickLogo={onClickLogo}
                            onCloseAccountNav={onCloseAccountNav}
                            onLogOut={onLogOut}
                            onOpenRegistration={onOpenRegistration}
                            onProjectTelemetryEvent={onProjectTelemetryEvent}
                            onSeeCommunity={onSeeCommunity}
                            onShare={onShare}
                            onStartSelectingFileUpload={
                                onStartSelectingFileUpload
                            }
                            onShowMessageBox={onShowMessageBox}
                            onToggleLoginOpen={onToggleLoginOpen}
                            onClickCheckUpdate={onClickCheckUpdate}
                            onClickClearCache={onClickClearCache}
                            onClickInstallDriver={onClickInstallDriver}
                            onSetJrchange={onSetJrchange}
                        />


  
                        <Box className={styles.bodyWrapper}>
                            <Box className={styles.flexWrapper}>
                            {isjr == true && isRealtimeMode != false ? 
                            <div className={classNames(styles.leftBox, {
                                                [styles.showLeftBox]: showLeftBox,
                                            })}>
                                        <div className={styles.leftBoxHeader}>
                                            <p> <FormattedMessage
                                                    defaultMessage="Code"
                                                    description="Button to get to the code panel"
                                                    id="gui.gui.MontiorData"
                                                /></p>
                                        <button
                                        className={styles.leftBoxButton}
                                        onClick={() => {
                                                    setShowLeftBox(!showLeftBox);
                                                }}
                                        >
                                            <img
                                                draggable={false}
                                                src={expandIcon}
                                                className={classNames(styles.leftBoxButtonIcon, {
                                                    [styles.leftBoxButtonIconRotate]: showLeftBox}
                                                )}
                                            />
                                        </button>
                                        </div>
                                        <div className={styles.leftBoxContent}>
                                                  <MonitorDataComponent
                                                    vm={vm}
                                                    isjr={isjr}
                                                    value={ReadDeviceData.getTiltAngle()}
                                                    label={"Data1: "}
                                                    />
                                           
                                    </div>
                                    </div> : null }
                                <Box
                                    className={styles.editorWrapper}
                                >

                                {
                                    blocksTabVisible ?
                                device.deviceId ?        
                                activeTargetTabIndex==0?
                                            <Box
                                                className={
                                                        isjr
                                                        ? styles.switchButtonContainerJr
                                                        : styles.switchButtonContainer
                                                    }
                                            >
                                                <SwitchModeButton/>
                                            </Box> : null : null: null
                                }                     
                                           

                                            
                                    <Tabs
                                        forceRenderTabPanel
                                        className={tabClassNames.tabs}
                                        selectedIndex={activeTabIndex}
                                        selectedTabClassName={
                                            tabClassNames.tabSelected
                                        }
                                        selectedTabPanelClassName={
                                            tabClassNames.tabPanelSelected
                                        }
                                        onSelect={onActivateTab}
                                    >
                                        <TabList
                                            className={tabClassNames.tabList}
                                        >
                                            <Tab className={tabClassNames.tab}>
                                                <img
                                                    draggable={false}
                                                    src={codeIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Code"
                                                    description="Button to get to the code panel"
                                                    id="gui.gui.codeTab"
                                                />
                                            </Tab>
                                            {
                                                /*
                                                <Tab className={tabClassNames.tab}>
                                                <img
                                                    draggable={false}
                                                    src={codeIcon}
                                                />
                                                <FormattedMessage
                                                    defaultMessage="Code"
                                                    description="Button to get to the code panel"
                                                    id="gui.gui.codeTab"
                                                />
                                            </Tab>
                                            */
                                            }
                                            
                                            {(isjr || activeTargetTabIndex==0) ? null : (
                                                <>
                                                    <Tab
                                                        className={classNames(
                                                            tabClassNames.tab,
                                                            isRealtimeMode
                                                                ? styles.hideCustomAndSoundTab
                                                                : styles.showCustomAndSoundTab
                                                        )}
                                                        onClick={
                                                            onActivateCostumesTab
                                                        }
                                                    >
                                                        <img
                                                            draggable={false}
                                                            src={costumesIcon}
                                                        />
                                                        {targetIsStage ? (
                                                            <FormattedMessage
                                                                defaultMessage="Backdrops"
                                                                description="Button to get to the backdrops panel"
                                                                id="gui.gui.backdropsTab"
                                                            />
                                                        ) : (
                                                            <FormattedMessage
                                                                defaultMessage="Costumes"
                                                                description="Button to get to the costumes panel"
                                                                id="gui.gui.costumesTab"
                                                            />
                                                        )}
                                                    </Tab>
                                                    <Tab
                                                        className={classNames(
                                                            tabClassNames.tab,
                                                            isRealtimeMode
                                                                ? styles.hideCustomAndSoundTab
                                                                : styles.showCustomAndSoundTab
                                                        )}
                                                        onClick={
                                                            onActivateSoundsTab
                                                        }
                                                    >
                                                        <img
                                                            draggable={false}
                                                            src={soundsIcon}
                                                        />
                                                        <FormattedMessage
                                                            defaultMessage="Sounds"
                                                            description="Button to get to the sounds panel"
                                                            id="gui.gui.soundsTab"
                                                        />
                                                    </Tab>
                                                </>
                                            ) }
                                        </TabList>




                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                        
                                             <Box>
                                                    <Blocks
                                                        canUseCloud={
                                                            canUseCloud
                                                        }
                                                        grow={1}
                                                        isVisible={
                                                            blocksTabVisible
                                                        }
                                                        options={{
                                                            media: `${basePath}static/blocks-media/`,
                                                        }}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                        isjr={isjr}
                                                        verticalTargetTab={verticalTargetTab}
                                                        gameDestroy={gameDestroy}
                                                        {...componentProps}
                                                    />
                                                </Box> 

                                               
                                                {isjr ? null :  <Box
                                                className={
                                                    styles.extensionButtonContainer
                                                }
                                            >
                                                <button
                                                    className={
                                                        styles.extensionButton
                                                    }
                                                    title={intl.formatMessage(
                                                        messages.addExtension
                                                    )}
                                                    onClick={
                                                        onExtensionButtonClick
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.extensionButtonIcon
                                                        }
                                                        draggable={false}
                                                        src={addExtensionIcon}
                                                    />
                                                </button>
                                            </Box>}

                                            { !blocksTabVisible ? null :

                                        <Box
                                                 className={
                                                     isjr
                                                         ? styles.downloadButtonContainer
                                                         : styles.downloadButtonContainerNormal
                                                 }
                                             >
                                        {isRealtimeMode === false ? (
                                            (!device.deviceId && isjr==false)   ? null : 
                                                <CodeUploadButton vm={vm} isjr={isjr} />
                                            
                                        ) : null}
                                           
                                        </Box> 
                                        }
                                            
                                          
                                                {(!device.deviceId && isjr==false)   ? null : 
                                                <PlayBtn isjr={isjr} vm={vm} />
                                         
                                        }


                                            {(!device.deviceId && isjr==false)   ? null : 
                                            <StopBtn isjr={isjr} vm={vm} />
                                            
                                        }

                                       
                                          {  isjr
                                            ? null :
                                            <Box className={styles.watermark}>
                                                {<Watermark />}
                                            </Box>
                                          }
                                        </TabPanel>

                                      {
                                        /*
                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                        
                                             <Box>
                                                    <SpriteBlocks
                                                        canUseCloud={
                                                            canUseCloud
                                                        }
                                                        grow={1}
                                                        isVisible={
                                                            spritesTabVisible
                                                        }
                                                        options={{
                                                            media: `${basePath}static/blocks-media/`,
                                                        }}
                                                        stageSize={stageSize}
                                                        vm={vm}
                                                        isjr={isjr}
                                                        gameDestroy={gameDestroy}
                                                        {...componentProps}
                                                    />
                                                </Box> 

                                               
                                              

                                         
                                            
                                          
                                                {(!device.deviceId && isjr==false)   ? null : 
                                                <PlayBtn isjr={isjr} vm={vm} />
                                         
                                        }


                                            {(!device.deviceId && isjr==false)   ? null : 
                                            <StopBtn isjr={isjr} vm={vm} />
                                            
                                        }

                                       
                                          {  isjr
                                            ? null :
                                            <Box className={styles.watermark}>
                                                {<Watermark />}
                                            </Box>
                                          }
                                        </TabPanel>
                                            */
                                      } 
                                        
                                        {(isjr) ? null : (
                                            <>
                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    {costumesTabVisible ? (
                                                        <CostumeTab vm={vm} />
                                                    ) : null}
                                                </TabPanel>
                                                <TabPanel
                                                    className={
                                                        tabClassNames.tabPanel
                                                    }
                                                >
                                                    {soundsTabVisible ? (
                                                        <SoundTab
                                                            vm={vm}
                                                            onShowMessageBox={
                                                                onShowMessageBox
                                                            }
                                                        />
                                                    ) : null}
                                                </TabPanel>
                                            </>
                                        )}
                                    </Tabs>
                                    {/*
                                    backpackVisible ? (
                                        <Backpack host={backpackHost} />
                                    ) : null
                                */}
                                </Box>

                                {(isjr==false)   ? (
                                    <Box
                                        className={classNames(
                                            styles.stageAndTargetWrapper,
                                            styles[stageSize],
                                            isRealtimeMode
                                                ? styles.showStage
                                                : styles.hideStage
                                        )}
                                    >
                                        <StageWrapper
                                            isFullScreen={isFullScreen}
                                            isRendererSupported={
                                                isRendererSupported
                                            }
                                            isRtl={isRtl}
                                            stageSize={stageSize}
                                            vm={vm}
                                        />



                                        <Box className={styles.targetWrapper}>
                                        <Tabs
                                        forceRenderTabPanel
                                        className={tabClassNames.tabs}
                                        selectedIndex={activeTargetTabIndex}

                                        selectedTabClassName={
                                            tabClassNames.tabSelected
                                        }
                                        selectedTabPanelClassName={
                                            tabClassNames.tabPanelSelected
                                        }
                                        onSelect={(index)=>{   
                                            if(index==0){
                                                activateTargetTab(index);

                                               props.onSetVerticalModeDevicechange();
                                                if(Object.values(sprites).filter(x => x.isDevice==true).length >0){
                                                    vm.setEditingTarget(Object.values(sprites).filter(x => x.isDevice==true)[0].id);
                                                }
                                         

                                            } 
                                            if(index==1){
                                                activateTargetTab(index);
                                                
                                               if(Object.values(sprites).filter(x => x.isDevice==false).length >0){
                                                    vm.setEditingTarget(Object.values(sprites).filter(x => x.isDevice==false)[0].id);
                                                }

                                                props.onSetVerticalModeSpritechange();
                                               
                                            }                        
                                            }
                                        }
                                    >
                                        <TabList
                                            className={tabClassNames.tabList}
                                        >
                                            
                                            

                                            <Tab className={tabClassNames.tab}>
                                                   
                                                    <FormattedMessage
                                                                defaultMessage="Aygıtlar"
                                                                description="Button to get to the backdrops panel"
                                                                id="gui.gui.targetpanezcode2"
                                                            />
                                            </Tab>

                                            <Tab
                                                        className={classNames(
                                                            tabClassNames.tab,
                                                           
                                                        )}
                                                       
                                                    >
                                                        
                                                         <FormattedMessage
                                                                defaultMessage="Kuklalar"
                                                                description="Button to get to the backdrops panel"
                                                                id="gui.gui.targetpanezcode"
                                                            />
                                            </Tab>

                                        </TabList>




                                       

                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                        
                                       
                                       <TargetPaneDevice
                                                stageSize={stageSize}
                                                vm={vm}
                                       />

                                               
                                        </TabPanel>



                                        <TabPanel
                                            className={tabClassNames.tabPanel}
                                        >
                                        
                                        <TargetPane
                                                stageSize={stageSize}
                                                vm={vm}
                                            />

                                               
                                        </TabPanel>



                                        </Tabs>

                                        </Box>
                                    </Box>
                                ) : (
                                    //Burası oyun alanı tanımlanan yer içerisine vm
                                    <Box
                                        className={classNames(
                                            styles.stageAndTargetWrapper,
                                            styles[stageSize],
                                            isRealtimeMode
                                                ? styles.showStage
                                                : styles.hideStage,
                                            device?.deviceId ? styles.showGame : styles.showCat,
                                        )}
                                    >
                                        <OyunWrapper
                                            isFullScreen={isFullScreen}
                                            isRendererSupported={
                                                isRendererSupported
                                            }
                                            isRtl={isRtl}
                                            stageSize={stageSize}
                                            vm={vm}
                                            stageWidth={stageWidth}
                                            setStageWidth={setStageWidth}
                                            isjr={isjr}
                                            gameDestroy={gameDestroy}
                                            deviceChange={deviceChange}
                                            style={{ width: stageWidth }}
                                            initialStageSize={initialStageSize}
                                            device={device}
                                            peripheralName={peripheralName}
                                        />
                                    </Box>
                                )}

                                {isRealtimeMode === false ? (
                                    <HardwareHeader vm={vm} />
                                ) : null}
                                {isRealtimeMode === false &&
                                stageSizeMode !== STAGE_SIZE_MODES.hide ? (
                                    <Hardware vm={vm} />
                                ) : null}
                            </Box>
                            <DragLayer />
                        </Box>

                        
                    </Box>
                );
            }}
        </MediaQuery>
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickCheckUpdate: PropTypes.func,
    onAbortUpdate: PropTypes.func,
    onClickUpdate: PropTypes.func,
    onClickClearCache: PropTypes.func,
    onClickInstallDriver: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onShowMessageBox: PropTypes.func.isRequired,
    onTabSelect: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    isRealtimeMode: PropTypes.bool,
    onSetJrchange: PropTypes.func,
    isjr: PropTypes.bool,
    gameDestroy: PropTypes.bool,
    deviceChange: PropTypes.bool,
    deviceId: PropTypes.bool
};
GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: "./",
    canChangeLanguage: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large,
};

const mapStateToProps = (state) => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    peripheralName: state.scratchGui.connectionModal.peripheralName,
    verticalTargetTab:state.scratchGui.verticalTargetTab,
    device: state.scratchGui.device,
    sprites: state.scratchGui.targets.sprites,

});

const mapDispatchToProps = dispatch => ({
   
    onSetVerticalModeSpritechange: () => dispatch(spriteTargetMode()),
    onSetVerticalModeDevicechange: () => dispatch(deviceTargetMode()),

});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GUIComponent));
