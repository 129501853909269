import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from '../../mymodule/ztech-vm';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import analytics from '../lib/analytics';

import DownloadZconnectComponent from '../components/download-zconnect/downloadZconnect.jsx';


const messages = defineMessages({
    Title: {
        defaultMessage: 'zConnect İndir',
        description: 'Heading for the device library',
        id: 'gui.deviceLibrary.downloadModalTitle'
    },
    text: {
        defaultMessage: "Kod yüklemek ve cihazları bağlamak için zConnect'i şimdi indirip yükleyin.",
        description: "Kod yüklemek ve cihazları bağlamak için zConnect'i şimdi indirip yükleyin.",
        id: "gui.deviceLibrary.downloadModalText"
    },
    downloadtext: {
        defaultMessage: "İndir",
        description: "Kod yüklemek ve cihazları bağlamak için zConnect'i şimdi indirip yükleyin.",
        id: "gui.deviceLibrary.downloadModalButton"
    }
});


class DownloadZconnectModal extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
        ]);
    }
    componentDidMount () {
       
    }

    handleItemSelect (item) {
        this.props.onRequestClose();
    }

    render () {
      
        return (
            <DownloadZconnectComponent
            id="downloadZconnect"
            title={this.props.intl.formatMessage(messages.Title)}
            onRequestClose={this.props.onRequestClose}
            onSetNormalchange={this.props.onSetNormalchange}
            gameDestroy={this.props.gameDestroy}
            onUpdateToolboxState={this.props.onUpdateToolboxState}
            vm={this.props.vm}
            deviceData={this.props.deviceData}
            onDeviceSelected={this.props.onDeviceSelected}
            downloadtext={this.props.intl.formatMessage(messages.downloadtext)}
            text={this.props.intl.formatMessage(messages.text)}
        />
        );
    }
}

DownloadZconnectModal.propTypes = {
    deviceData: PropTypes.instanceOf(Array).isRequired,
    intl: intlShape.isRequired,
    onDeviceSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = state => ({
    deviceData: state.scratchGui.deviceData.deviceData
});

const mapDispatchToProps = dispatch => ({
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onUpdateToolboxState: toolboxXML => {
      
        dispatch(updateToolbox(toolboxXML));
    },
});

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DownloadZconnectModal);
