import React from 'react';
import {FormattedMessage} from 'react-intl';
import log from '../../log';


import pybotcontrolboardBaseToolBoxVertical from './baseToolbox/pybotcontrolboard';
import zBotBaseToolBoxVertical from './baseToolbox/zbotVertical';

import ZbotBaseToolBox from './baseToolbox/zbot';
import ZtronicJuniorBaseToolBox from './baseToolbox/ztronicjr';


// import {DeviceType} from '../../device';
// import defaultsDeep from 'lodash.defaultsdeep';
// import arduinoBaseToolBox from './baseToolbox/arduino';
// import microbitBaseToolBox from './baseToolbox/microbit';
// import hefabotBaseToolBox from './baseToolbox/hefabot';
// import pybotcontrolboardBaseToolBoxHorizontal from './baseToolbox/zbot';
// import unselectDeviceIconURL from './unselectDevice/unselectDevice.png';
// import catIconURL from './unselectDevice/cat.svg';


// import arduinoUnoIconURL from './arduinoUno/arduinoUno.png';
// import arduinoUnoConnectionIconURLL from './arduinoUno/arduinoUno-illustration.svg';
// import arduinoUnoConnectionSmallIconURL from './arduinoUno/arduinoUno-small.svg';

// import arduinoNanoIconURL from './arduinoNano/arduinoNano.png';
// import arduinoNanoConnectionIconURLL from './arduinoNano/arduinoNano-illustration.svg';
// import arduinoNanoConnectionSmallIconURL from './arduinoNano/arduinoNano-small.svg';

// import arduinoLeonardoIconURL from './arduinoLeonardo/arduinoLeonardo.png';
// import arduinoLeonardoConnectionIconURLL from './arduinoLeonardo/arduinoLeonardo-illustration.svg';
// import arduinoLeonardoConnectionSmallIconURL from './arduinoLeonardo/arduinoLeonardo-small.svg';

// import arduinoMega2560IconURL from './arduinoMega2560/arduinoMega2560.png';
// import arduinoMega2560ConnectionIconURLL from './arduinoMega2560/arduinoMega2560-illustration.svg';
// import arduinoMega2560ConnectionSmallIconURL from './arduinoMega2560/arduinoMega2560-small.svg';

// import microbitIconURL from './microbit/microbit.png';
// import microbitConnectionIconURLL from './microbit/microbit-illustration.svg';
// import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

// import microbitV2IconURL from './microbitV2/microbitV2.png';
// import microbitV2ConnectionIconURLL from './microbitV2/microbitV2-illustration.svg';
// import microbitV2ConnectionSmallIconURL from './microbitV2/microbitV2-small.svg';

// import esp32ConnectionIconURLL from './esp32/esp32-illustration.svg';
// import esp32ConnectionSmallIconURL from './esp32/esp32-small.svg';

// import esp8266IconURL from './esp8266/esp8266.png';
// import esp8266ConnectionIconURL from './esp8266/esp8266-illustration.svg';
// import esp8266ConnectionSmallIconURL from './esp8266/esp8266-small.svg';

// import makeymakeyIconURL from './makeymakey/makeymakey.png';
// import makeymakeyConnectionIconURL from './makeymakey/makeymakey-illustration.svg';
// import makeymakeyConnectionSmallIconURL from './makeymakey/makeymakey-small.svg';

// import hefabotIconURL from './hefabot/aa.png';
// import hefabotConnectionIconURL from './hefabot/aa109x87.png';
// import hefabotConnectionSmallIconURL from './hefabot/aa25x17.png';


import pybotcontrolboardIconURL from './pybotcontrolboard/108x87.png';
import pybotcontrolboardConnectionIconURL from './pybotcontrolboard/aa.png';
import pybotcontrolboardSmallIconURL from './pybotcontrolboard/40x40.png';
import zbotIconURL from './esp32/esp32.jpg';
import zbotIconURLSmall from './esp32/small.jpg';
import zbotIconURLMedium from './esp32/medium.jpg';
import zbotunselectIconURL from './unselectDevice/zbot.png';



const deviceData = [
    /*
     * Unselect the deivce back to pure scratch mode
     */
   /*
    {
        name: (
            <FormattedMessage
                defaultMessage="Unselect device"
                description="Name for the unselect device"
                id="gui.device.unselectDevice.name"
            />
        ),
        deviceId: 'unselectDevice',
        iconURL: zbotunselectIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Unselect the device, return to pure realtime programming mode."
                description="Description for the unselect device"
                id="gui.device.unselectDevice.description"
            />
        ),
        featured: true,
        hide: false,
        programMode: ['realtime'],
        programLanguage: ['block'],
        tags: ['realtime']
    },
    */
    {
        name: 'zBot',
        deviceId: 'zbotEsp32',
        manufactor: 'ZTECH',
        leanMore: '',
        type: 'microbit',
        iconURL: zbotIconURL,
        description: (''),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: zbotIconURLMedium,
        connectionSmallIconURL: zbotIconURLSmall,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their esp32."
                id="gui.device.arduinoEsp32.connectingMessage"
            />
        ),
        baseToolBoxXml: ZbotBaseToolBox,
        baseToolBoxXmlVertical: zBotBaseToolBoxVertical,
        programMode: ['realtime','upload'],
        programLanguage: ['block','c', 'cpp', 'microPython'],
        tags: ['arduino','microPython'],
        helpLink: ''
    }, 
    
    {
        name: 'zTronic',
        deviceId: 'zboardEsp32',
        manufactor: 'ZTECH',
        leanMore: '',
        type: 'microbit',
        iconURL: pybotcontrolboardConnectionIconURL,
        description: (''),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: pybotcontrolboardIconURL,
        connectionSmallIconURL: pybotcontrolboardSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their esp32."
                id="gui.device.arduinoEsp32.connectingMessage"
            />
        ),
        baseToolBoxXml: ZtronicJuniorBaseToolBox,
        baseToolBoxXmlVertical: pybotcontrolboardBaseToolBoxVertical,
        programMode: ['realtime','upload'],
        programLanguage: ['block','c', 'cpp', 'microPython'],
        tags: ['arduino','microPython'],
        helpLink: ''
    }, 
    
   
];

/**
 * To get real device id. eg: the third party id like ironKit_arduinoUno.
 * @param {string} deviceId - the id of the device.
 * @return {string} deviceId - the real device id.
 */
const analysisRealDeviceId = deviceId => {
    if (deviceId){

        // if the id contain '_' use the string afer the '_'.
        if (deviceId.indexOf('_') !== -1) {
            deviceId = deviceId.split('_')[1];
        }
    }
    return deviceId;
};

/**
 * Make device data from the input data. If it is a buid-in device, return the buid-in
 * data. If it is a third party device, find it's parent device, and overwrite its attributes
 * with the input data.
 * @param {string} deviceList - the list of devices.
 * @return {string} fullData - processed data of devices.
 */
const makeDeviceLibrary = (deviceList = null) => {
    let regeneratedDeviceData = [];

    if (deviceList) {
        deviceList.forEach(dev => {
            // Check if this is a build-in device.
            const matchedDevice = deviceData.find(item => dev.deviceId === item.deviceId);
            if (matchedDevice) {
                return regeneratedDeviceData.push(matchedDevice);
            }

            // This is a third party device. Try to parse it's parent deivce.
           
            log.warn('Cannot find this device or it\'s parent device :', dev.deviceId);
            return null;
        });

        regeneratedDeviceData.unshift(deviceData[0]); // add unselect deive in the head.
    } else {
        regeneratedDeviceData = deviceData;
    }

    return regeneratedDeviceData;
};

export {
    deviceData as default,
    makeDeviceLibrary
};
