const Cast = require('../util/cast');
const Oyun = require('../util/gameArea');
const DeviceSensorData = require('../util/deviceSensorData');
const ReadDeviceDataClass = require('../util/deviceDataRead');
const ConnectedDeviceClass = require('../util/connectedDevice');
const ReadDeviceData = require('../util/deviceDataRead');

const Buffer = require('buffer').Buffer;

class GameEventsBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;

        this.runtime.on('KEY_PRESSED', key => {
            this.runtime.startHats('event_whenkeypressed', {
                KEY_OPTION: key
            });
            this.runtime.startHats('event_whenkeypressed', {
                KEY_OPTION: 'any'
            });
        });
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
           // game_event_whenflagclicked: this.emre,
            game_event_whentouchingobject: this.touchingObject,
            game_event_whengreaterthan: this.hatGreaterThanPredicate,
          //  game_when_shake_robot:this.when_shake_robot,
          //  game_detect_sound_intensity: this.detect_sound_intensity,
           // game_when_detect_light: this.when_detect_light,
            // game_when_detect_barrier: this.when_detect_barrier,
           // game_when_detect_color: this.game_when_detect_color,

          //  game_when_keypress_game: this.when_keypress_game,
            game_when_broadcast_received_game: this.when_broadcast_received_game,
            game_when_broadcast_game: this.when_broadcast_game,
            game_event_broadcast: this.game_event_broadcast,

            game_when_detect_light_vertical:this.game_when_detect_light_vertical,
           // game_when_detect_barrier_vertical:this.game_when_detect_barrier_vertical

            /*
            game_when_robot_run:this.game_when_robot_run,
            game_when_detect_robot_button:this.game_when_detect_robot_button,
            game_when_shake_robot:this.game_when_shake_robot,
            game_when_detect_tilted_vertical:this.game_when_detect_tilted_vertical,
            game_when_detect_light_vertical:this.game_when_detect_light_vertical,
            game_when_detect_color_vertical:this.game_when_detect_color_vertical,
            game_when_detect_barrier_vertical:this.game_when_detect_barrier_vertical
            */
        };
    }


    getHats () {
        console.log('requestedHatOpcode');

        return {
            
            game_event_whenflagclicked: {
              
                restartExistingThreads: true
            },

            game_when_keypress_game: {
                restartExistingThreads: true
            },
            game_event_whenthisspriteclicked: {
                restartExistingThreads: true
            },
            game_event_whentouchingobject: {
                restartExistingThreads: false,
                edgeActivated: true
            },
            game_event_whenstageclicked: {
                restartExistingThreads: true
            },
            game_event_whenbackdropswitchesto: {
                restartExistingThreads: true
            },
            game_event_whengreaterthan: {
                restartExistingThreads: false,
                edgeActivated: true
            },
            game_event_whenbroadcastreceived: {
                restartExistingThreads: true
            },
        
            game_when_detect_color:{
                restartExistingThreads: false,
                edgeActivated: false
            },
            game_when_detect_barrier:{
                restartExistingThreads:true
            },
            game_when_detect_light:{
                restartExistingThreads:true
            },
            game_detect_sound_intensity:{
                restartExistingThreads:true
            },
            game_when_detect_light_off:{
                restartExistingThreads:true
            },
            game_detect_sound_intensity_off:{
                restartExistingThreads:true
            },
            //////////////////////////
            game_when_detect_color_vertical:{
                restartExistingThreads: true,
            },
            game_when_detect_robot_button:{
                restartExistingThreads:true
            },
            game_when_shake_robot:{
                restartExistingThreads:true
            },
            game_when_touch_robot:{
                restartExistingThreads:true
            },
            game_when_detect_tilted_vertical:{
                restartExistingThreads:true
            },
            game_when_robot_run:{
                restartExistingThreads:true
            },
            game_when_detect_light_vertical: {
                restartExistingThreads: false,
                edgeActivated: true
            },
            /*
            game_when_detect_light_vertical:{
                restartExistingThreads:true
            },
            */
            game_when_detect_barrier_vertical:{
                restartExistingThreads: true
            },
            
        };
    }

   async commonFunc(argumentValue){
        var data = DeviceSensorData.getLightLevel();
        console.log('sound gelen data: '+data); 
        var currentClass =  Oyun.getClass();
        const value = Cast.toNumber(argumentValue);
        console.log('sound gelen value: '+value); 
        console.log('device run oldu'); 

       var device = ConnectedDeviceClass.getDevice();
       console.log(device); 

        await device.write("<< 4 d light_on 1 7 >>\n");

       if(value >= data){
         currentClass.actionPermissionTrue();
       }else{
         currentClass.actionPermissionFalse();
       }
    }

    game_when_detect_color (args, util) {
       console.log('event çalıştı greeen ');
       
      // this.runtime.startHats('game_event_whenflagclicked');
    //   this.runtime.toggleScript('6U7pp]#%U%`4Nur^,2gg',{stackClick: true});
    
      // var currentClass =  Oyun.getClass();
      // await currentClass.playPressed();
    }

    touchingObject (args, util) {
        return util.target.isTouchingObject(args.TOUCHINGOBJECTMENU);
    }

    hatGreaterThanPredicate (args, util) {
        //ses yüksekliği büyükse yada zamanlama büyükse
        const option = Cast.toString(args.WHENGREATERTHANMENU).toLowerCase();
        const value = Cast.toNumber(args.VALUE);
        switch (option) {
        case 'timer':
            return util.ioQuery('clock', 'projectTimer') > value;
        case 'loudness':
            return this.runtime.audioEngine && this.runtime.audioEngine.getLoudness() > value;
        }
        return false;
    }

    game_when_detect_light_vertical (args, util) {
        //ses yüksekliği büyükse yada zamanlama büyükse
       
        console.log(args);
        const option = Cast.toString(args.KEY_OPTION).toLowerCase();
        const value = Cast.toNumber(args.VALUE);
      //  console.log(ReadDeviceData.getShakingIntensity());

        switch (option) {
        case 'sound':
            return Cast.toNumber(ReadDeviceData.getSoundIntensity()) > value;
        case 'light':
            return Cast.toNumber(ReadDeviceData.getLightIntensity()) > value;
            return true;
        case 'shake':
            return Cast.toNumber(ReadDeviceData.getShakingIntensity()) > value;
        }
    }

    game_when_detect_barrier_vertical (args, util) {
        const option = Cast.toString(args.KEY_OPTION).toLowerCase();
        console.log(ReadDeviceData.getDetectBarrier());
        switch (option) {
        case 'yes':
            util.startHats('game_when_detect_barrier_vertical', {
                KEY_OPTION: 'yes'
            });
            return ReadDeviceData.getDetectBarrier();
        case 'no':
            util.startHats('game_when_detect_barrier_vertical', {
                KEY_OPTION: 'no'
            });
            return ReadDeviceData.getDetectBarrier();
        }
       
        return false;
    }

    

   
    game_event_broadcast (args, util) {
        const broadcastVar = args.option;
        if (broadcastVar) {
            const broadcastOption = broadcastVar;
            util.startHats('game_event_whenbroadcastreceived', {
                option: broadcastOption
            });
        }
    }

    /*
    when_shake_robot(args, util){
        //robot salldığında büyükse
        console.log(args);
        var data = DeviceSensorData.getIsShake();
        var currentClass =  Oyun.getClass();
        console.log(data);
       // const value = Cast.toNumber(argumentValue);
       if(data == true){
         currentClass.actionPermissionTrue();
         util.startHats('game_when_shake_robot');
       }else{
         currentClass.actionPermissionFalse();
       }

    }
    */

    detect_sound_intensity(args, util){


        this.commonFunc(args.sound_intensity);

        var data = DeviceSensorData.getSoundLevel();
        console.log('sound gelen data: '+data); 
        var currentClass =  Oyun.getClass();
        const value = Cast.toNumber(args.sound_intensity);
        console.log('sound gelen value: '+value); 

       if(value >= data){
         currentClass.actionPermissionTrue();
       }else{
         currentClass.actionPermissionFalse();
       }
    }

    when_detect_light(args, util){
        //robot algılanan ışık büyükse
      // this.commonFunc(args.light_intensity);

       var data = DeviceSensorData.getLightLevel();
        console.log('sound gelen data: '+data); 
        var currentClass =  Oyun.getClass();
        const value = Cast.toNumber(args.light_intensity);
        console.log('sound gelen value: '+value); 
        currentClass.actionPermissionTrue();
      
    }

    when_detect_color(args, util){
        //robot renk algılama
        //Cihazdan algılanacak
        console.log(args);
        console.log("renk çalıştı")
    }

    when_detect_barrier(args, util){
        //robot engel var ise
        //cihazdan algılanacak
        console.log(args);
    }
    
   async when_keypress_game(args, util){
        //robot tuşa basıldığında
        //cihazdan algılanacak
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();
        console.log(device); 
        if(args.click=="upArrow"){
            await device.write("<< 2 r 0 detect_x >>\n");
        }else if(args.click=="downArrow"){
            await device.write("<< 2 r 0 detect_triangle >>\n");
        }
        else if(args.click=="leftArrow"){
            await device.write("<< 2 r 0 detect_circle >>\n");
        }
        else if(args.click=="rightArrow"){
            await device.write("<< 2 r 0 detect_square >>\n");
        }
        else if(args.click=="zButton"){
            await device.write("<< 2 r 0 detect_z >>\n");
        }
       
    }

    when_broadcast_received_game(args, util){
        //robot haber aldığında
        console.log(args);
        console.log(args);
        const broadcastVar = args.click;
        console.log('gelen mesaj: '+broadcastVar);
        if (broadcastVar) {
            console.log('gelen mesaj: '+broadcastVar);
            const broadcastOption = broadcastVar;
            util.startHats('event_whenbroadcastreceived', {
                BROADCAST_OPTION: broadcastOption
            });
        }
    }

    when_broadcast_game(args, util){
        //robot haber geldiğinde
        console.log('util.stackFrame.broadcastVar:');
        console.log(util.stackFrame.broadcastVar);
        if (!util.stackFrame.broadcastVar) {
           // alert('girdi haber almaya');
           // util.stackFrame.broadcastVar = util.runtime.getTargetForStage().lookupBroadcastMsg(args.BROADCAST_OPTION.id, args.BROADCAST_OPTION.name);
        }
        if (util.stackFrame.broadcastVar) {
            const broadcastOption = util.stackFrame.broadcastVar.name;
            // Have we run before, starting threads?
            if (!util.stackFrame.startedThreads) {
                // No - start hats for this broadcast.
                util.stackFrame.startedThreads = util.startHats(
                    'event_whenbroadcastreceived', {
                        BROADCAST_OPTION: broadcastOption
                    }
                );
                if (util.stackFrame.startedThreads.length === 0) {
                    // Nothing was started.
                    return;
                }
            }
            // We've run before; check if the wait is still going on.
            const instance = this;
            // Scratch 2 considers threads to be waiting if they are still in
            // runtime.threads. Threads that have run all their blocks, or are
            // marked done but still in runtime.threads are still considered to
            // be waiting.
            const waiting = util.stackFrame.startedThreads
                .some(thread => instance.runtime.threads.indexOf(thread) !== -1);
            if (waiting) {
                // If all threads are waiting for the next tick or later yield
                // for a tick as well. Otherwise yield until the next loop of
                // the threads.
                if (
                    util.stackFrame.startedThreads
                        .every(thread => instance.runtime.isWaitingThread(thread))
                ) {
                    util.yieldTick();
                } else {
                    util.yield();
                }
            }
        }
    }
}

module.exports = GameEventsBlocks;
