const ACTIVATETARGET_TAB = 'scratch-gui/navigation/ACTIVATETARGET_TAB';

// Constants use numbers to make it easier to work with react-tabs
const CIHAZ_TAB_INDEX = 0;
const KUKLALAR_TAB_INDEX = 1; //Sıralamayı değiştir 1 den başlat


const initialState = {
    activeTargetTabIndex: CIHAZ_TAB_INDEX
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;

    switch (action.type) {
    case ACTIVATETARGET_TAB:
        return Object.assign({}, state, {
            activeTargetTabIndex: action.activeTargetTabIndex
        });
    default:
        return state;
    }
};

const activateTargetTab = function (tab) {
  
    return {
        type: ACTIVATETARGET_TAB,
        activeTargetTabIndex: tab
    };
};

export {
    reducer as default,
    initialState as targetTabInitialState,
    activateTargetTab,
    CIHAZ_TAB_INDEX,
    KUKLALAR_TAB_INDEX
};
