import ScratchBlocks from "../../../../../mymodule/ztech-blocks";

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/* eslint-disable no-unused-vars */

const lights = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'LIGHTS_STAGE_SELECTED',
        'Stage selected: no light blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_LIGHTING}" id="lights" colour="#5dc8f6" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `
     
        <block type="ztronic_rgb_led_yak"> </block>

        <block type="ztronic_rgb_led_yak_saniyeli">
            <value name="COLOR">
                 <shadow type="colour_picker"/>
            </value>

            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>

        </block>

        <block type="ztronic_rgb_led_yak_saniyeli_secmeli">
         <value name="COLOR">
                 <shadow type="colour_picker"/>
            </value>
            <value name="VALUE">
                    <shadow type="field_dropdown">
                        <field name="VALUE">1</field>
                    </shadow>
                </value>
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>

        <block type="ztronic_isik_gosterisi"> </block>

        <block type="ztronic_rgb_led_kapat"> </block>

        

      

        
      
        
`}
        ${categorySeparator}
    </category>
    `;
};

const sound = function (isRealtimeMode,isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">

    <block type="zbot_ses_cal" id="zbot_ses_cal">
    </block>
  

    <block type="zbot_ses_seviyesi" id="zbot_ses_seviyesi">
    <value name="X">
        <shadow type="math_number">
            <field name="NUM">100</field>
        </shadow>
    </value>
    </block> 


    <block type="ses_seviyesi" id="ses_seviyesi">
    </block>


    <block type="zbot_ses_seviyesi_degistir" id="zbot_ses_seviyesi_degistir">
    <value name="X">
        <shadow type="math_number">
            <field name="NUM">-10</field>
        </shadow>
    </value>
    </block> 


    <block type="nota_cal" id="nota_cal">
        <value name="PIN">
            <shadow type="field_dropdown">
                <field name="PIN">B0</field>
            </shadow>
        </value>

        <value name="X">
            <shadow type="math_number">
                <field name="NUM">0.25</field>
            </shadow>
        </value>

    </block> 

    <block type="tum_sesleri_durdur" id="tum_sesleri_durdur">
    </block>

    ${isRealtimeMode ? 
        `
    <block type="my_playDrumForBeats" id="my_playDrumForBeats">
    <value name="X">
    <shadow type="math_float">
    <field name="NUM">0.25</field>
    </shadow>
    </value>
    <value name="PIN">
        <shadow type="field_dropdown">
        <field name="X">1</field>
        </shadow>
    </value>
</block> 

<block type="my_midiPlayDrumForBeats" id="my_midiPlayDrumForBeats">
    <value name="X">
    <shadow type="math_float">
    <field name="NUM">0.25</field>
    </shadow>
    </value>
</block> 


<block type="my_restForBeats" id="my_restForBeats">
    <value name="X">
        <shadow type="note">
        <field name="NOTE">60</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_float">
        <field name="NUM">0.25</field>
        </shadow>
    </value>
</block> 

<block type="my_setInstrument" id="my_setInstrument">

</block> 



<block type="my_setTempo" id="my_setTempo">
<value name="X">
<shadow type="math_number">
<field name="NUM">60</field>
</shadow>
</value>
</block> 


<block type="my_changeTempo" id="my_changeTempo">
<value name="X">
<shadow type="math_number">
<field name="NUM">20</field>
</shadow>
</value>
</block> 


<block type="my_getTempo" id="my_getTempo">

</block> 

` :null}

  
    ${categorySeparator}
    </category>
    `;
};

const motion = function (isInitialSetup, isStage) {
  
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion2" colour="#4C97FF" secondaryColour="#3373CC">
    ${isStage ? `
    ` : `
    
    

    <block type="ztronic_dc_motor_saniye">
           <value name="X">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>   
    </block>


    <block type="ztronic_gucte">
           <value name="X">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   
    </block>

        <block type="ztronic_gucte_saniye">
            <value name="power">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   

            <value name="X">
                <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
            </value>   
        </block>

        <block type="ztronic_dc_durdur">
           
        </block>

        <block type="ztronic_servo_gucte">
            <value name="X">
                <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
            </value>  
        </block>



        <block type="ztronic_servo_surekli">
           
        </block>

        
       

        <block type="ztronic_servo_durdur">
        </block>

      
   
`}
        
        ${categorySeparator}
    </category>
    `;
};

const sensorler = function () {
    return `
    <category
        name="%{BKY_CATEGORY_SENSING}"
        id="Sensorler_CATEGORY"
        colour="#fc4e15"
        secondaryColour="#FF4D6A"
        >

        <block type="flame_sensor_init"/>

         <block type="ztronic_hareket_algilama_sensoru"/>

        <block type="ztronic_alevli_algilama_sensoru"/>
        <block type="ztronic_sallandi"/>
        <block type="ztronic_tusa_basildi"/>


        <block type="ztronic_ses_siddeti"/>

        
        <block type="ztronic_sallanma_siddeti"/>
        <block type="ztronic_isik_siddeti"/>
        <block type="ztronic_pil_seviyesi"/>

        <block type="ztronic_egim_acisi"/>
        <block type="ztronic_yatis_acisi"/>


        <block type="ztronic_sicaklik"/>
        <block type="ztronic_nem"/>
        <block type="ztronic_toprak_nemi"/>
        <block type="ztronic_potansiyometre"/>
        <block type="ztronic_mesafe"/>



    </category>
    `;
};

const ledmatrix = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
    ${isStage ? '' : `

   
    
    <block type="ztronic_zz_led_matrix" id="ztronic_zz_led_matrix">
        <value name="MATRIX"> 
            <shadow type="matrix"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>
    </block>





    
    <block type="ztronic_led_matrix_kaydırarak_yazdır">
        <value name="X">
            <shadow type="text">
                <field name="TEXT">zbot</field>
            </shadow>
        </value>
    </block>    

     <block type="ztronic_led_matrix_kapat">  </block>


   
    `}
     ${categorySeparator}

   


 
</category>
    `;
};





const events = function (isInitialSetup, isStage, targetId, isRealtimeMode) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${
            !isRealtimeMode
                ? `

            <block type="ztronic_when_robot_run"></block>
          
            <block type="ztronic_when_detect_robot_button"></block>


           


            <block type="ztronic_when_detect_light_vertical">
                <value name="VALUE">
                     <shadow type="field_dropdown">
                            <field name="VALUE">ışık</field>
                     </shadow>
                </value>
                <value name="VALUE">
                     <shadow type="math_number">
                        <field name="NUM">10</field>
                    </shadow>
                </value>
            </block>

            <block type="ztronic_when_shake_robot"></block>


            <block type="ztronic_when_detect_tilted_vertical">
                <value name="VALUE">
                    <shadow type="field_dropdown">
                        <field name="VALUE">x</field>
                    </shadow>
                </value>
            </block>

            <block type="ztroic_when_touch_robot"></block>

            <block type="ztronic_detect_burn">
                <value name="detect_barrier">
                    <shadow type="dropdown_detect_barrier">
                        <field name="detect_barrier">barrier</field>
                    </shadow>
                </value>
            </block>


            <block type="ztronic_detect_pir">
                <value name="detect_barrier">
                    <shadow type="dropdown_detect_barrier">
                        <field name="detect_barrier">barrier</field>
                    </shadow>
                </value>
            </block>

            <block type="ztronic_detect_pot">
                <value name="VALUE">
                     <shadow type="math_number">
                        <field name="NUM">10</field>
                    </shadow>
                </value>
            </block>

            <block type="ztronic_detect_ultrasonic">
                <value name="VALUE">
                     <shadow type="math_number">
                        <field name="NUM">10</field>
                    </shadow>
                </value>
            </block>

             <block type="ztronic_detect_flame">
               
            </block>


      
        `
                : `
            <block type="event_whenarduinobegin"/>
        `
        }
        ${categorySeparator}

   
    </category>
    `;
};

const control = function (isInitialSetup, isStage, targetId, isRealtimeMode) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        ${blockSeparator}
        <block type="control_if"/>
        <block type="control_if_else"/>
        <block id="wait_until" type="control_wait_until"/>
        <block id="repeat_until" type="control_repeat_until"/>
        ${isRealtimeMode ? `
            ${blockSeparator}
            <block type="control_stop"/>
            ${blockSeparator}
            ${isStage ? `
                <block type="control_create_clone_of">
                    <value name="CLONE_OPTION">
                        <shadow type="control_create_clone_of_menu"/>
                    </value>
                </block>
                 <block type="control_start_as_clone"/>
                <block type="control_create_clone_of">
                    <value name="CLONE_OPTION">
                        <shadow type="control_create_clone_of_menu"/>
                    </value>
                </block>
                <block type="control_delete_this_clone"/>
            ` : `
               
            `}
        ` : null}
        ${categorySeparator}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate(
        "SENSING_ASK_TEXT",
        "What's your name?"
    );
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${
            isStage
                ? ""
                : `
            <block type="sensing_touchingobject">
                <value name="TOUCHINGOBJECTMENU">
                    <shadow type="sensing_touchingobjectmenu"/>
                </value>
            </block>
            <block type="sensing_touchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_coloristouchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
                <value name="COLOR2">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_distanceto">
                <value name="DISTANCETOMENU">
                    <shadow type="sensing_distancetomenu"/>
                </value>
            </block>
            ${blockSeparator}
        `
        }
        ${
            isInitialSetup
                ? ""
                : `
            <block id="askandwait" type="sensing_askandwait">
                <value name="QUESTION">
                    <shadow type="text">
                        <field name="TEXT">${name}</field>
                    </shadow>
                </value>
            </block>
        `
        }
        <block id="answer" type="sensing_answer"/>
        ${blockSeparator}
        <block type="sensing_keypressed">
            <value name="KEY_OPTION">
                <shadow type="sensing_keyoptions"/>
            </value>
        </block>
        <block type="sensing_mousedown"/>
        <block type="sensing_mousex"/>
        <block type="sensing_mousey"/>
        ${
            isStage
                ? ""
                : `
            ${blockSeparator}
            '<block type="sensing_setdragmode" id="sensing_setdragmode"></block>'+
            ${blockSeparator}
        `
        }
        ${blockSeparator}
        <block id="loudness" type="sensing_loudness"/>
        ${blockSeparator}
        <block id="timer" type="sensing_timer"/>
        <block type="sensing_resettimer"/>
    
        ${blockSeparator}
        <block id="current" type="sensing_current"/>
        <block type="sensing_dayssince2000"/>
        ${blockSeparator}
        <block type="sensing_username"/>
        ${categorySeparator}
    </category>
    `;
};

const operators = function (isInitialSetup) {
    const apple = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_APPLE",
        "apple"
    );
    const banana = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_BANANA",
        "banana"
    );
    const letter = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_LETTEROF_APPLE",
        "a"
    );
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${blockSeparator}
        ${
            isInitialSetup
                ? ""
                : `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
              <value name="STRING1">
                <shadow type="text">
                  <field name="TEXT">${apple}</field>
                </shadow>
              </value>
              <value name="STRING2">
                <shadow type="text">
                  <field name="TEXT">${letter}</field>
                </shadow>
              </value>
            </block>
        `
        }
        ${blockSeparator}
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${categorySeparator}
    </category>
    `;
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};

const hareket = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MOTION}"
        id="hareket_CATEGORY"
        colour="#094fc0"
        secondaryColour="#FF4D6A"
        >

    <block type="sec_git" id="sec_git">
    <value name="Y">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        <block type="sec_git_rpm" id="sec_git_rpm">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block>


        <block type="sol_saga_dereceli_git_power" id="sol_saga_dereceli_git_power">
        <value name="X">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 



        <block type="sol_saga_dereceli_git_rpm" id="sol_saga_dereceli_git_rpm">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        <block type="motor_rotates_at" id="motor_rotates_at">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        
      ///buradan


      <block type="guc_saniye_yon" id="guc_saniye_yon">
      <value name="X">
      <shadow type="math_power_number_positif">
          <field name="NUM">0</field>
      </shadow>
  </value>
  <value name="Y">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>    
      </block> 


      <block type="rpm_saniye_yon" id="rpm_saniye_yon">
      <value name="X">
      <shadow type="math_rpm_number_positif">
          <field name="NUM">0</field>
      </shadow>
  </value>
  <value name="Y">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>    
      </block>

     


  <block type="hareketi_durdur" id="hareketi_durdur">
  </block>



  <block type="motor_rotates_at_time" id="motor_rotates_at_time">
  <value name="X">
  <shadow type="math_rpm_number">
      <field name="NUM">0</field>
  </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
  <field name="NUM">0</field>
</shadow>
</value>
  </block> 


  <block type="motor_rotated_angle" id="motor_rotated_angle">
  <value name="X">
  <shadow type="aci_araligi">
      <field name="NUM">0</field>
  </shadow>
</value>

  </block> 

  

  
  <block type="motor_moves_cm" id="motor_moves_cm">
  <value name="X">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>
  </block>

    </category>
    `;
};

const gorunum = function () {
    return `
    <category
        name="Görünüm"
        id="Görünüm_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="resim_goster_delay" id="resim_goster_delay">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
          </shadow> 
        </value>
        <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
      </block>

      <block type="resim_goster" id="resim_goster">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">0100000</field> 
          </shadow> 
        </value>
      </block>

      <block type="resim_goster_x_y" id="resim_goster_x_y">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">0100000</field> 
          </shadow> 
        </value>
        <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
      </block>
    
      <block type="ekrani_kapat" id="ekrani_kapat">
    </block>

    
    <block type="goster" id="goster">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
    </block> 


    <block type="goster_kaydirilana_kadar" id="goster_kaydirilana_kadar">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
    </block> 


    <block type="goster_x_y" id="goster_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>
<value name="Z">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>
    </block> 

    <block type="ısik_yak_x_y" id="ısik_yak_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 


    <block type="ısik_kapat_x_y" id="ısik_kapat_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 

    <block type="ısik_yak_sondur_x_y" id="ısik_yak_sondur_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 


    </category>
    `;
};

const network = function () {
    return `
    <category
        name="%{BKY_CATEGORY_NETWORK}"
        id="Network_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="Connect2Wifi" id="Connect2Wifi">
             <value name="X">
        <shadow type="text">
            <field name="TEXT">SSID</field>
        </shadow>
            </value>
            <value name="Y">
        <shadow type="text">
            <field name="TEXT">PASSWORD</field>
        </shadow>
            </value>
        </block>


        <block type="CheckConnectionStatus" id="CheckConnectionStatus"></block>
        <block type="Reconnect2Wifi" id="Reconnect2Wifi"></block>
        <block type="DisconnectWifi" id="DisconnectWifi"></block>


        <block type="GetTimePyboard" id="GetTimePyboard"></block>


    </category>
    `;
};

const harici_sensorler = function () {
    return `
    <category
        name="Harici"
        id="Harici_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="toprak_nem_init"/>
        <block type="toprak_nem_oku"/>
        <block type="relative_humidity_init"/>
        <block type="relative_humidity_oku"/>
        
        <block type="su_gecirmez_sicaklik_oku"/>
        <block type="su_gecirmez_sicaklik_init"/>
    </category>
    `;
};
/* eslint-enable no-unused-vars */

const getXML = function (
    isInitialSetup,
    isStage,
    targetId,
    isRealtimeMode,
    costumeName,
    backdropName,
    soundName
) {
    const gap = [categorySeparator];

    const eventsXML = events(isInitialSetup, isStage, targetId, isRealtimeMode);
    const controlXML = control(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const sensingXML = sensing(isInitialSetup, isStage, targetId);
    const operatorsXML = operators(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const variablesXML = variables(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const myBlocksXML = myBlocks(isInitialSetup, isStage, targetId);
    const looksLedMatrix = ledmatrix(isInitialSetup, isStage, targetId);
    const aydinlatmaXML = lights(isInitialSetup, isStage, targetId);
    const soundXML =  sound(isRealtimeMode,isInitialSetup, isStage, targetId, soundName);
    const hareketXML = hareket(isInitialSetup, isStage, targetId);
    const motionGameXML = motion(isInitialSetup, isStage, targetId);

    const sensorlerXML = sensorler(isInitialSetup, isStage, targetId);
    const gorunumXML = gorunum(isInitialSetup, isStage, targetId);
    const networkXML = network(isInitialSetup, isStage, targetId);
   
    let baseToolboxXml = [];

    if (isRealtimeMode === true) {
        baseToolboxXml = [
            eventsXML,gap,
            motionGameXML,gap,
            aydinlatmaXML,gap,
            looksLedMatrix,gap,
            soundXML,gap,
            sensorlerXML,gap,
          
            controlXML, gap,
            operatorsXML,gap,
            variablesXML, gap,
            myBlocksXML
        ];
    } else {
        baseToolboxXml = [
            eventsXML,gap,
            motionGameXML,gap,
            aydinlatmaXML,gap,
            looksLedMatrix,gap,
            soundXML,gap,
            sensorlerXML,gap,
          
            controlXML, gap,
            operatorsXML,gap,
            variablesXML, gap,
            myBlocksXML
        ];
    }

    return baseToolboxXml;
};

export default getXML;
