const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');
const ReadDeviceData = require('../util/deviceDataRead');


/*
Bu sayfada  GameLedBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameMusicBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            sound_melodi_list:this.sound_melodi_list,
            sound_off:this.sound_off,
            custom_sound:this.custom_sound,
            play_sound:this.play_sound,
            zbot_ses_cal:this.zbot_ses_cal,
            ses_cal_bitene_kadar:this.ses_cal_bitene_kadar,
            zbot_ses_seviyesi:this.zbot_ses_seviyesi,
            ses_seviyesi:this.ses_seviyesi,
            zbot_ses_seviyesi_degistir:this.zbot_ses_seviyesi_degistir,
            nota_cal:this.nota_cal,
            tum_sesleri_durdur:this.tum_sesleri_durdur
        };
    }

    async sound_melodi_list(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.data=="")  {
            await device.write("<< 14 d 45 262 0.5 >>\n");
        }
        if(args.data=="do")  {
            await device.write("<< 14 d 45 262 0.5 >>\n");
        }
        if(args.data=="re")  {
            await device.write("<< 14 d 45 294 0.5 >>\n");
        }
        if(args.data=="mi")  {
            await device.write("<< 14 d 45 330 0.5 >>\n");
        }
        if(args.data=="fa")  {
            await device.write("<< 14 d 45 349 0.5 >>\n");
        }
        if(args.data=="so")  {
            await device.write("<< 14 d 45 392 0.5 >>\n");
        }
        if(args.data=="la")  {
            await device.write("<< 14 d 45 440 0.5 >>\n");
        }
        if(args.data=="si")  {
            await device.write("<< 14 d 45 494 0.5 >>\n");
        }
      
    }

    async play_sound(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.SOUND=="")  {
            await device.write(`<< 13 d 52 100 >>\n`);
        }
        if(args.SOUND=="vol1")  {
            await device.write(`<< 13 d 52 35 >>\n`);
        }
        if(args.SOUND=="vol2")  {
            await device.write(`<< 13 d 52 65 >>\n`);
        }
        if(args.SOUND=="vol3")  {
            await device.write(`<< 13 d 52 100 >>\n`);
        }
    }

    async sound_off(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 14 d 47 >>\n");
    }

    async custom_sound(args,util){
        console.log(args);
        var data = args.value;
        var device = ConnectedDeviceClass.getDevice();   
        if(data=="")  {
            await device.write("<< 13 d 48 oyuncak >>\n");
        }
        if(data=="hiphop")  {
            await device.write("<< 13 d 48 hiphop >>\n");
        }
        if(data=="at")  {
            await device.write("<< 13 d 48 at >>\n");
        }
        if(data=="ayak_sesi")  {
            await device.write("<< 13 d 48 ayak_sesi >>\n");
        }
        if(data=="bozuk_para")  {
            await device.write("<< 13 d 48 bozuk_para >>\n");
        }
        if(data=="hav")  {
            await device.write("<< 13 d 48 hav >>\n");
        }
        if(data=="oyuncak")  {
            await device.write("<< 13 d 48 oyuncak >>\n");
        }
        if(data=="puan")  {
            await device.write("<< 13 d 48 puan >>\n");
        }
        if(data=="miyav")  {
            await device.write("<< 13 d 48 miyav >>\n");
        }
        if(data=="siren")  {
            await device.write("<< 13 d 48 siren >>\n");
        }
        if(data=="tango")  {
            await device.write("<< 15 d 62 >>\n");
        }
    }

    async zbot_ses_cal(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getdev();   
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d 49 hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d 49 at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d 49 ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d 49 bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d 49 hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d 49 oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d 49 puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d 49 miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d 49 siren >>\n");
        }
        if(args.ADDR=="tango")  {
            await device.write("<< 15 d 62 >>\n");        
        }
        
    }

    async ses_cal_bitene_kadar(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d 48 hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d 48 at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d 48 ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d 48 bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d 48 hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d 48 oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d 48 puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d 48 miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d 48 siren >>\n");
        }
    }

    async zbot_ses_seviyesi(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getdev();     
        await device.write(`<< 13 d 52 ${args.X} >>\n`);
    }

    async ses_seviyesi(args,util){
       // console.log(args);
        var device = ConnectedDeviceClass.getdev();     
        return ReadDeviceData.getSoundLevel();
    }

    async zbot_ses_seviyesi_degistir(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getdev();     
        await device.write(`<< 13 d 50 ${args.X} >>\n`);
    }

    async nota_cal(args,util){
        console.log(args);
        var note= args.PIN;
        var vurus = args.X;
        var device = ConnectedDeviceClass.getDevice();     

        if(note == 'B0'){
            await device.write(`<< 14 d 45 31 ${vurus} >>\n`);
        }
        if(note == 'C1'){
            await device.write(`<< 14 d 45 33 ${vurus} >>\n`);
        }
        if(note == 'D1'){
            await device.write(`<< 14 d 45 37 ${vurus} >>\n`);
        }
        if(note == 'E1'){
            await device.write(`<< 14 d 45 41 ${vurus} >>\n`);
        }
        if(note == 'F1'){
            await device.write(`<< 14 d 45 44 ${vurus} >>\n`);
        }
        if(note == 'G1'){
            await device.write(`<< 14 d 45 49 ${vurus} >>\n`);
        }
        if(note == 'A1'){
            await device.write(`<< 14 d 45 55 ${vurus} >>\n`);
        }
        if(note == 'B1'){
            await device.write(`<< 14 d 45 62 ${vurus} >>\n`);
        }
        if(note == 'C2'){
            await device.write(`<< 14 d 45 65 ${vurus} >>\n`);
        }
        if(note == 'D2'){
            await device.write(`<< 14 d 45 73 ${vurus} >>\n`);
        }
        if(note == 'E2'){
            await device.write(`<< 14 d 45 82 ${vurus} >>\n`);
        }
        if(note == 'F2'){
            await device.write(`<< 14 d 45 87 ${vurus} >>\n`);
        }
        if(note == 'G2'){
            await device.write(`<< 14 d 45 98 ${vurus} >>\n`);
        }
        if(note == 'A2'){
            await device.write(`<< 14 d 45 110 ${vurus} >>\n`);
        }
        if(note == 'B2'){
            await device.write(`<< 14 d 45 123 ${vurus} >>\n`);
        }
        if(note == 'C3'){
            await device.write(`<< 14 d 45 131 ${vurus} >>\n`);
        }
        if(note == 'D3'){
            await device.write(`<< 14 d 45 147 ${vurus} >>\n`);
        } if(note == 'E3'){
            await device.write(`<< 14 d 45 165 ${vurus} >>\n`);
        } if(note == 'F3'){
            await device.write(`<< 14 d 45 175 ${vurus} >>\n`);
        } if(note == 'G3'){
            await device.write(`<< 14 d 45 196 ${vurus} >>\n`);
        } if(note == 'A3'){
            await device.write(`<< 14 d 45 220 ${vurus} >>\n`);
        } if(note == 'B3'){
            await device.write(`<< 14 d 45 247 ${vurus} >>\n`);
        } if(note == 'C4'){
            await device.write(`<< 14 d 45 262 ${vurus} >>\n`);
        }
        if(note == 'D4'){
            await device.write(`<< 14 d 45 294 ${vurus} >>\n`);
        }
        if(note == 'E4'){
            await device.write(`<< 14 d 45 330 ${vurus} >>\n`);
        }
        if(note == 'F4'){
            await device.write(`<< 14 d 45 349 ${vurus} >>\n`);
        }
        if(note == 'G4'){
            await device.write(`<< 14 d 45 392 ${vurus} >>\n`);
        }
        if(note == 'A4'){
            await device.write(`<< 14 d 45 440 ${vurus} >>\n`);
        }
        if(note == 'B4'){
            await device.write(`<< 14 d 45 494 ${vurus} >>\n`);
        }
        if(note == 'C5'){
            await device.write(`<< 14 d 45 523 ${vurus} >>\n`);
        }
        if(note == 'D5'){
            await device.write(`<< 14 d 45 587 ${vurus} >>\n`);
        }
        if(note == 'E5'){
            await device.write(`<< 14 d 45 659 ${vurus} >>\n`);
        }
        if(note == 'F5'){
            await device.write(`<< 14 d 45 698 ${vurus} >>\n`);
        }
        if(note == 'G5'){
            await device.write(`<< 14 d 45 784 ${vurus} >>\n`);
        }
        if(note == 'A5'){
            await device.write(`<< 14 d 45 880 ${vurus} >>\n`);
        }
        if(note == 'B5'){
            await device.write(`<< 14 d 45 988 ${vurus} >>\n`);
        }
        if(note == 'C6'){
            await device.write(`<< 14 d 45 1047 ${vurus} >>\n`);
        }
        if(note == 'D6'){
            await device.write(`<< 14 d 45 1175 ${vurus} >>\n`);
        }
        if(note == 'E6'){
            await device.write(`<< 14 d 45 1319 ${vurus} >>\n`);
        }
        if(note == 'F6'){
            await device.write(`<< 14 d 45 1397 ${vurus} >>\n`);
        } if(note == 'G6'){
            await device.write(`<< 14 d 45 1568 ${vurus} >>\n`);
        } if(note == 'A6'){
            await device.write(`<< 14 d 45 1760 ${vurus} >>\n`);
        } if(note == 'B6'){
            await device.write(`<< 14 d 45 1976 ${vurus} >>\n`);
        } if(note == 'C7'){
            await device.write(`<< 14 d 45 2093 ${vurus} >>\n`);
        } if(note == 'D7'){
            await device.write(`<< 14 d 45 2349 ${vurus} >>\n`);
        } if(note == 'E7'){
            await device.write(`<< 14 d 45 2637 ${vurus} >>\n`);
        }
        if(note == 'F7'){
            await device.write(`<< 14 d 45 2794 ${vurus} >>\n`);
        }
        if(note == 'G7'){
            await device.write(`<< 14 d 45 3136 ${vurus} >>\n`);
        }
        if(note == 'A7'){
            await device.write(`<< 14 d 45 3520 ${vurus} >>\n`);
        }
        if(note == 'B7'){
            await device.write(`<< 14 d 45 3951 ${vurus} >>\n`);
        }
        if(note == 'C8'){
            await device.write(`<< 14 d 45 4186 ${vurus} >>\n`);
        }
        if(note == 'D8'){
            await device.write(`<< 14 d 45 4699 ${vurus} >>\n`);
        }
    }

    async tum_sesleri_durdur(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 14 d 47 >>\n");
    }
   
   
}

module.exports = GameMusicBlocks;
