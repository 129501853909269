import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {injectIntl, intlShape, defineMessages} from 'react-intl';
import VM from '../../mymodule/ztech-vm';

import zcodeDeviceLibraryContent from '../lib/libraries/devices.json';
import spriteTags from '../lib/libraries/sprite-tags';

import LibraryDeviceComponent from '../components/library-device/library-device.jsx';
import RenderedTarget from '../../mymodule/ztech-vm/src/sprites/rendered-target.js';
import Sprite from '../../mymodule/ztech-vm/src/sprites/sprite.js';
const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Sprite',
        description: 'Heading for the sprite library',
        id: 'gui.zcodeDeviceLibrary.chooseASprite'
    }
});

class ZcodeDevicesLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect (item) {
        // Randomize position of library sprite
       console.log("item12");
       console.log(this.props.sprites);
       console.log("this.props.sprites");
        var aa = this.props.sprites;
        console.log(Object.values(aa).filter(x => x.isDevice==true));

        console.log(Object.values(aa).filter(x => x.isDevice==true).filter(k => k.name =="zbot").length != 0);

       if(Object.values(aa).filter(x => x.isDevice==true).filter(k => k.name ==item.name).length == 0){
        this.props.vm.addSprite(JSON.stringify(item),true).then(() => {
            this.props.onActivateBlocksTab();
        });
       }
       
        
 /*
        const newClone = new RenderedTarget(new Sprite(null,this.props.vm.runtime,true), this.props.vm.runtime,true);
        this.props.vm.runtime.addTarget(newClone);
        console.log(newClone);

        this.props.vm.setEditingTarget(newClone.id);
*/

    }
    render () {
        return (
            <LibraryDeviceComponent
                data={zcodeDeviceLibraryContent}
                id="zcodeDevicesLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

ZcodeDevicesLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    sprites: state.scratchGui.targets.sprites,

});

export default injectIntl(connect(
    mapStateToProps
)(ZcodeDevicesLibrary));
