import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./4e080acae1c3dc65eef30f07c2b0d4a5.mp3';
import backdrop from '!raw-loader!./cd21514d0531fdffb22204e0ec5ed84a.svg';
// import costume1 from '!raw-loader!./5de48e72637db4d7fc7f6bb526354114.svg'; //! orjinal kedi
import costume1 from '!raw-loader!./5de48e72637db4d7fc7f6bb526354114.svg';
import costume2 from '!raw-loader!./48bbc82a10af3d0e0b7d5c677097df0e.svg';
/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
    let _TextEncoder;
    if (typeof TextEncoder === 'undefined') {
        _TextEncoder = require('text-encoding').TextEncoder;
    } else {
        /* global TextEncoder */
        _TextEncoder = TextEncoder;
    }
    const encoder = new _TextEncoder();

    const projectJson = projectData(translator);
    return [{
        id: 0,
        assetType: 'Project',
        dataFormat: 'JSON',
        data: JSON.stringify(projectJson)
    }, 
    // {
    //     id: '83a9787d4cb6f3b7632b4ddfebf74367',
    //     assetType: 'Sound',
    //     dataFormat: 'WAV',
    //     data: new Uint8Array(popWav)
    // }, 
    {
        id: '4e080acae1c3dc65eef30f07c2b0d4a5',
        assetType: 'Sound',
        dataFormat: 'MP3',
        data: new Uint8Array(meowWav)
    }, 
    {
        id: 'cd21514d0531fdffb22204e0ec5ed84a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(backdrop)
    }, 
    {
        id: '5de48e72637db4d7fc7f6bb526354114',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume1),
       // isDevice:false,
        rotationCenterX: 93,
        rotationCenterY: 80
    }, 
    {
        id: '48bbc82a10af3d0e0b7d5c677097df0e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume2)
    }
];
};

export default defaultProject;
