// ML5.js ve gerekli modülleri dahil edin
const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const ml5 = require('ml5');

class TeachableMachineExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.classifier = null;
        this.video = null;
        this.label = '';
        this.confidence = 0;
    }

    getInfo() {
        return {
            id: 'teachableMachine',
            name: 'Teachable Machine',
            blocks: [
                {
                    opcode: 'loadModel',
                    blockType: BlockType.COMMAND,
                    text: 'Modeli Yükle [URL]',
                    arguments: {
                        URL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'https://teachablemachine.withgoogle.com/models/UdRDgfJKw/model.json'
                        }
                    }
                },
                {
                    opcode: 'classify',
                    blockType: BlockType.COMMAND,
                    text: 'Görüntüyü Sınıflandır'
                },
                {
                    opcode: 'getLabel',
                    blockType: BlockType.REPORTER,
                    text: 'Sınıf'
                },
                {
                    opcode: 'getConfidence',
                    blockType: BlockType.REPORTER,
                    text: 'Güven düzeyi'
                }
            ]
        };
    }

    loadModel(args) {
        const modelURL = args.URL;
        
        // Video elemanı oluştur ve sahneye ekle
        if (!this.video) {
            this.video = document.createElement('video');
            this.video.setAttribute('autoplay', true);
            this.video.setAttribute('playsinline', true);
       
           // document.body.appendChild(this.video);
             this.runtime.ioDevices.video.enableVideo();
            const stage = this.runtime.getTargetForStage();
        }

        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(stream => {
                this.video.srcObject = stream;

                // ML5.js model yükleme
                ml5.imageClassifier(modelURL, this.video)
                    .then(classifier => {
                        this.classifier = classifier;
                    })
                    .catch(err => {
                        console.error('Model yükleme hatası:', err);
                    });
            })
            .catch(err => {
                console.error('Kamera hatası:', err);
            });
    }

    classify() {
        if (this.classifier) {
            this.classifier.classify()
                .then(results => {
                    this.label = results[0].label;
                    this.confidence = results[0].confidence;
                })
                .catch(err => {
                    console.error('Sınıflandırma hatası:', err);
                });
        }
    }

    getLabel() {
        return this.label || 'N/A';
    }

    getConfidence() {
        return this.confidence ? (this.confidence * 100).toFixed(2) + '%' : 'N/A';
    }
}

module.exports = TeachableMachineExtension;
