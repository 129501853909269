import React, { useEffect, useState } from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import keyMirror from 'keymirror';

import Ansi from 'ansi-to-react';
import ScrollableFeed from 'react-scrollable-feed';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';
import Dots from '../connection-modal/dots.jsx';

import styles from './upload-progress.css';
import ProgressBar from '@ramonak/react-progress-bar';

const PHASES = keyMirror({
    uploading: null,
    success: null,
    error: null,
    timeout: null
});

const UploadProgressComponent = props => {
    const [time, setTime] = useState(0);
    const [isClosed, setClosed] = useState(false);

    useEffect(() => {

        if (props.phase === PHASES.uploading) {
            setTimeout(() => setTime(time + 1), props.timeupload);

            if(time > 90){
               // setTime('Yükleme Tamamlanıyor');
                setClosed(false);
            }else{
              //  setTimeout(() => setTime(time + 1), props.timeupload);
            }
          } else if (props.phase === PHASES.success) {
            setTime('Yükleme Tamamlandı');
          } else if (props.phase === PHASES.error) {
            setTime('Yükleme Hatası');
          } else if (props.phase === PHASES.timeout) {
            setTime('Yükleme Zaman Aşımı');
          } else {
            setTime("Tamamlanmak üzere...");
            }
        }, [time, props.phase]);

    
return (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="connectionModal"
        // onHelp={props.onHelp}
        shouldCloseOnOverlayClick={false}
        closeButtonVisible={false}
    >
        <Box className={styles.body}>
            <Box className={styles.terminalWarper}>
                <ScrollableFeed
                    className={styles.terminalText}
                    forceScroll
                >
                    <Ansi>
                        {props.text}
                    </Ansi>
                </ScrollableFeed>
            </Box>
            <Dots
                className={styles.bottomAreaItem}
                counter={0}
                total={3}
            />              
            <ProgressBar                
                className={styles.bottomAreaItem}
                // completed={props.phase === PHASES.success ? 100 : 50}
                completed={time}
                bgColor="#0065ff"
                height="25px"
                transitionDuration= {props.timeupload ==  "0.200s"}
                // isLabelVisible={false}
            />
            
            <Box className={styles.bottomArea}>
                <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                    {props.phase === PHASES.uploading ? (
                        <FormattedMessage
                            defaultMessage="Uploading"
                            description="Prompt for upload in progress"
                            id="gui.uploadProgress.uploading"
                        />
                    ) : null}
                    {props.phase === PHASES.success ? (
                        <FormattedMessage
                            defaultMessage="Upload success"
                            description="Prompt for upload success"
                            id="gui.uploadProgress.uploadSuccess"
                        />
                    ) : null}
                    {props.phase === PHASES.error ? (
                        <FormattedMessage
                            defaultMessage="Upload error"
                            description="Prompt for upload error"
                            id="gui.uploadProgress.uploadError"
                        />
                    ) : null}
                    {props.phase === PHASES.timeout ? (
                        <FormattedMessage
                            defaultMessage="Upload timeout"
                            description="Prompt for upload timeout"
                            id="gui.uploadProgress.uploadTimeout"
                        />
                    ) : null}
                </Box>
                <button
                    className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                    onClick={props.onCancel}
                    disabled={ isClosed}
                >
                    <FormattedMessage
                        defaultMessage="Close"
                        description="Button in bottom to close after upload"
                        id="gui.uploadProgress.close"
                    />
                </button>
            </Box>
        </Box>
    </Modal>
);
};

UploadProgressComponent.propTypes = {
    connectionSmallIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired
};

UploadProgressComponent.defaultProps = {
};

export {
    UploadProgressComponent as default,
    PHASES
};
