
let lightLevel = 0;
let soundLevel = 0;
let shakeLevel = 0;
let isShake=false;
let detectedColor = "";

class DeviceSensorData {
    constructor (runtime) {
        
        this.runtime = runtime;

    }

    static setRuntime (runtime) {
        this.runtime = runtime;
    }

    static setLightLevel (data) {
        lightLevel = data;
        }
    
    static getLightLevel () {
        return lightLevel; 
    }

    static setSoundLevel (data) {
        soundLevel = data;
        }
    
    static getSoundLevel () {
        return soundLevel; 
    }

    static setShakeLevel (data) {
        shakeLevel = data;
        }
    
    static getShakeLevel () {
        return shakeLevel; 
    }

    static setIsShake (data) {
            isShake = data;
            console.log(this.runtime);
          //  this.runtime.startHats('game_when_shake_robot');
        }
    
    static getIsShake () {
        return isShake; 
    }

    static setDetectedColor (data) {
        detectedColor = data;
        }

    static getDetectedColor () {
        return detectedColor; 
    }

}

module.exports = DeviceSensorData;
