import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from '../../mymodule/ztech-vm';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import analytics from '../lib/analytics';

import FeedbackModalComponent from '../components/feedback-modal/feedback-modal.jsx';


const messages = defineMessages({
    title: {
        defaultMessage: 'Geribildirim',
        description: 'feedback',
        id: 'gui.toolbox.feedbackTitle'
    },
    mail: {
        defaultMessage: "Email",
        description: "",
        id: 'gui.toolbox.feedbackMail'
    },
    name: {
        defaultMessage: "İsim",
        description: "",
        id: 'gui.toolbox.feedbackName'
    },
    feedback: {
        defaultMessage: "Önerileriniz/geribildirimleriniz",
        description: "",
        id: 'gui.toolbox.feedbackValue'
    },
    uploadButton: {
        defaultMessage: "Hata videosu/ekran görüntüsü yada proje dosyası yükleyiniz",
        description: "",
        id: 'gui.toolbox.feedbackUploadButton'
    },
    sendButton: {
        defaultMessage: "Gönder",
        description: "Kod yüklemek ve cihazları bağlamak için zConnect'i şimdi indirip yükleyin.",
        id: 'gui.toolbox.feedbackSendButton'
    },
    sendLoadingText: {
        defaultMessage: "Geribildiriminiz gönderiliyor lütfen bekleyiniz....",
        description: "Kod yüklemek ve cihazları bağlamak için zConnect'i şimdi indirip yükleyin.",
        id: 'gui.toolbox.feedbacksendLoadingText'
    },

});


class FeedbackModal extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
        ]);
    }
    componentDidMount () {
       
    }

    handleItemSelect (item) {
        this.props.onRequestClose();
    }

    render () {
      
        return (
            <FeedbackModalComponent
            id="feedbackModal"
            title={this.props.intl.formatMessage(messages.title)}
            mail={this.props.intl.formatMessage(messages.mail)}
            name={this.props.intl.formatMessage(messages.name)}
            feedback={this.props.intl.formatMessage(messages.feedback)}
            uploadButton={this.props.intl.formatMessage(messages.uploadButton)}
            sendButton={this.props.intl.formatMessage(messages.sendButton)}
            sendLoadingText={this.props.intl.formatMessage(messages.sendLoadingText)}
            onRequestClose={this.props.onRequestClose}
            onSetNormalchange={this.props.onSetNormalchange}
            gameDestroy={this.props.gameDestroy}
            onUpdateToolboxState={this.props.onUpdateToolboxState}
            vm={this.props.vm}
            deviceData={this.props.deviceData}
            onDeviceSelected={this.props.onDeviceSelected}
          
        />
        );
    }
}

FeedbackModal.propTypes = {
    deviceData: PropTypes.instanceOf(Array).isRequired,
    intl: intlShape.isRequired,
    onDeviceSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = state => ({
    deviceData: state.scratchGui.deviceData.deviceData
});

const mapDispatchToProps = dispatch => ({
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onUpdateToolboxState: toolboxXML => {
      
        dispatch(updateToolbox(toolboxXML));
    },
});

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(FeedbackModal);
