import React from 'react';
import PropTypes from 'prop-types';
import Box from '../box/box.jsx';
import classNames from 'classnames';

import {STAGE_SIZE_MODES} from '../../lib/layout-constants';
import CodeEditor from '../../containers/code-editor.jsx';
import HardwareConsole from '../../containers/hardware-console.jsx';

import styles from './hardware.css';

import lockIcon from './icon--lock.svg';
import unlockIcon from './icon--unlock.svg';
import expandIcon from '../oyun/assets/button-img/expand-icon.svg';

import workspace from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass";

const HardwareComponent = props => {
    const {
        codeEditorLanguage,
        codeEditorOptions,
        codeEditorTheme,
        codeEditorValue,
        isCodeEditorLocked,
        onCodeEditorWillMount,
        onCodeEditorDidMount,
        onCodeEditorChange,
        onClickCodeEditorLock,
        stageSizeMode
    } = props;

    const [closeCE, setCloseCE] = React.useState(true);
    const [closeConsoleCE, setCloseConsoleCE] = React.useState(true);
    const ws = workspace.getWorkspace();
    const handleCollapse = () => {
        setCloseCE(!closeCE);
        setTimeout(() => {
        
        window.dispatchEvent(new Event("resize"));
        }, 300);
    };
    return (
        <Box className={classNames(styles.hardwareWrapper,
            {"hardware-close": closeCE})
        }
        style={{width: closeCE ? 0 : (stageSizeMode === STAGE_SIZE_MODES.large) ? 480 : 240}}
        >
            <button
                className={classNames(styles.collapseBtn)}
                onClick={handleCollapse}
            >
                <img
                    alt="Collapse"
                    className={classNames(styles.collapseIcon, closeCE ? styles.close : styles.open)}
                    src={expandIcon}
                />
            </button>
            <Box className={classNames(styles.codeEditorWrapper)}>
                <button
                    className={classNames(styles.button, styles.lockButton)}
                    onClick={onClickCodeEditorLock}
                >
                    <img
                        alt="Lock"
                        className={classNames(styles.lockIcon)}
                        src={isCodeEditorLocked ? lockIcon : unlockIcon}
                    />
                </button>
                <CodeEditor
                    width={(stageSizeMode === STAGE_SIZE_MODES.large) ? 480 : 240} //* code editor width
                    height={ closeConsoleCE ? `calc(100% + ${(stageSizeMode === STAGE_SIZE_MODES.large) ? "240px" : "120px"})` : "100%" }
                    value={codeEditorValue}
                    language={codeEditorLanguage}
                    editorWillMount={onCodeEditorWillMount}
                    editorDidMount={onCodeEditorDidMount}
                    onChange={onCodeEditorChange}
                    theme={codeEditorTheme}
                    options={codeEditorOptions}
                />
            </Box>
                <button
                    className={classNames(styles.collapseConsoleBtn)}
                    style={{bottom: closeConsoleCE ? 0 : (stageSizeMode === STAGE_SIZE_MODES.large) ? 300 : 160}}
                    onClick={() => setCloseConsoleCE(!closeConsoleCE)}
                >
                    <img
                        alt="Collapse"
                        style={{transform: closeConsoleCE ? "rotate(0deg)" : "rotate(180deg)"}}
                        src={expandIcon}
                    />
                </button>
            <Box
                className={classNames(styles.hardwareConsoleWrapper,
                    (stageSizeMode === STAGE_SIZE_MODES.large) ? styles.wideWrapper : styles.narrowWrapper)}
                style={{height: closeConsoleCE ? 0 : (stageSizeMode === STAGE_SIZE_MODES.large) ? 300 : 160}}
            >
                <HardwareConsole
                    {...props}
                />
            </Box>
        </Box>
    );
};

HardwareComponent.propTypes = {
    codeEditorLanguage: PropTypes.string,
    codeEditorOptions: PropTypes.shape({
        highlightActiveIndentGuide: PropTypes.bool,
        cursorSmoothCaretAnimation: PropTypes.bool,
        readOnly: PropTypes.bool,
        contextmenu: PropTypes.bool,
        minimap: PropTypes.shape({
            enabled: PropTypes.bool
        })
    }),
    codeEditorTheme: PropTypes.string,
    codeEditorValue: PropTypes.string,
    isCodeEditorLocked: PropTypes.bool,
    onCodeEditorWillMount: PropTypes.func,
    onCodeEditorDidMount: PropTypes.func,
    onCodeEditorChange: PropTypes.func,
    onClickCodeEditorLock: PropTypes.func,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES))
};

HardwareComponent.defaultProps = {
    stageSizeMode: STAGE_SIZE_MODES.large
};

export default HardwareComponent;
