const SPRITE_TARGET_TAB = 'scratch-gui/SPRITE_TARGET_TAB';
const DEVICE_TARGET_TAB = 'scratch-gui/DEVICE_TARGET_TAB';


const initialState = false;

const reducer = function (state, action) {
    

    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SPRITE_TARGET_TAB:
        return true;
    case DEVICE_TARGET_TAB:
        return false;
    default:
        return state;
    }
};

const spriteTargetMode = () => ({
    type: SPRITE_TARGET_TAB
});

const deviceTargetMode = () => ({
    type: DEVICE_TARGET_TAB
});



export {
    reducer as default,
    initialState as verticalTargetTabInitialState,
    spriteTargetMode,
    deviceTargetMode
};
