import ScratchBlocks from "../../../../../mymodule/ztech-blocks";


const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/* eslint-disable no-unused-vars */


const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        }
    });
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};


const operators = function (isInitialSetup) {
    const apple = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_APPLE",
        "apple"
    );
    const banana = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_BANANA",
        "banana"
    );
    const letter = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_LETTEROF_APPLE",
        "a"
    );
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${blockSeparator}
        ${
            isInitialSetup
                ? ""
                : `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
              <value name="STRING1">
                <shadow type="text">
                  <field name="TEXT">${apple}</field>
                </shadow>
              </value>
              <value name="STRING2">
                <shadow type="text">
                  <field name="TEXT">${letter}</field>
                </shadow>
              </value>
            </block>
        `
        }
        ${blockSeparator}
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${categorySeparator}
    </category>
    `;
};

const ztech = function () {
    return `
    <category name="ztech" id="ztech" colour="#D65CD6" secondaryColour="#BD42BD">
        
        <block type="ztech_deneme_1"></block>
        <block type="ztech_deneme_2"></block>
        <block type="ztech_deneme_3"></block>
        <block type="ztech_deneme_4"></block>

       



        ${categorySeparator}
        </category>
    `;
};


const eventsRealTime = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">

    <block type="game_event_whenflagclicked"/>

           
        
            <block type="game_when_detect_robot_button">
            <value name="VALUE">
                <shadow type="field_dropdown">
                    <field name="VALUE">x</field>
                </shadow>
            </value>
        </block>
        
        <block type="game_when_shake_robot"></block>

        <block type="game_when_touch_robot"></block>

        <block type="game_when_detect_tilted_vertical">
        <value name="VALUE">
            <shadow type="field_dropdown">
                <field name="VALUE">x</field>
            </shadow>
        </value>
        </block>
        
            <block type="game_when_detect_light_vertical">
            <value name="VALUE">
            <shadow type="field_dropdown">
                <field name="VALUE">ışık</field>
            </shadow>
        </value>
            <value name="VALUE">
            <shadow type="math_number">
              <field name="NUM">10</field>
            </shadow>
          </value>
            </block>
        
         
        

            <block type="event_whenbroadcastreceived">
            </block>
            <block type="event_broadcast">
                <value name="BROADCAST_INPUT">
                    <shadow type="event_broadcast_menu"></shadow>
                </value>
            </block>
            <block type="event_broadcastandwait">
                <value name="BROADCAST_INPUT">
                  <shadow type="event_broadcast_menu"></shadow>
                </value>
            </block>
        
           
        
   
            <block type="game_when_detect_barrier_vertical">
                <value name="detect_barrier">
                    <shadow type="dropdown_detect_barrier">
                        <field name="detect_barrier">barrier</field>
                    </shadow>
                </value>
            </block>

        
</category>
    `;
};


const events = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">

  
    
    <block type="game_when_robot_run"></block>


    <block type="game_when_detect_robot_button">
        <value name="VALUE">
            <shadow type="field_dropdown">
                <field name="KEY_OPTION">x</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_shake_robot"></block>
    <block type="game_when_touch_robot"></block>

    <block type="game_when_detect_tilted_vertical">
        <value name="VALUE">
            <shadow type="field_dropdown">
                <field name="VALUE">x</field>
            </shadow>
        </value>
    </block>


    <block type="game_when_detect_light_vertical">
        <value name="VALUE">
            <shadow type="field_dropdown">
                <field name="VALUE">ışık</field>
            </shadow>
        </value>
        <value name="VALUE">
            <shadow type="math_number">
            <field name="NUM">10</field>
            </shadow>
      </value>
    </block>

   

    <block type="game_when_detect_barrier_vertical">
        <value name="detect_barrier">
            <shadow type="dropdown_detect_barrier">
                <field name="detect_barrier">barrier</field>
            </shadow>
        </value>
    </block>

    
   

        
</category>
    `;
};

const lights = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'LIGHTS_STAGE_SELECTED',
        'Stage selected: no light blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_LIGHTING}" id="lights" colour="#5dc8f6" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `
     
        <block type="on_led_yak"> </block>

        <block type="on_led_kapat"> </block>

        <block type="arka_ledleri_yak"> </block>

        <block type="arka_led_kapat"> </block>

        <block type="arka_led_saniyeli">
            <value name="COLOR">
                 <shadow type="colour_picker"/>
            </value>

            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>

        </block>

        <block type="arka_led_255">
            <value name="VALUE">
                <shadow type="math_uint8_number">
                    <field name="NUM">150</field>
                </shadow>
            </value>
        </block>

        <block type="arka_led_rgb_yap">
        <value name="R">
            <shadow type="math_uint8_number">
                <field name="NUM">150</field>
            </shadow>
        </value>
        <value name="G">
            <shadow type="math_uint8_number">
                <field name="NUM">150</field>
            </shadow>
        </value>
        <value name="B">
            <shadow type="math_uint8_number">
                <field name="NUM">150</field>
            </shadow>
        </value>
    </block>

        
      
        
`}
        ${categorySeparator}
    </category>
    `;
};


const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
    ${isStage ? '' : `

    <block type="zz_led_matrix_saniye" id="zz_led_matrix_saniye">
        <value name="MATRIX"> 
            <shadow type="matrix"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>

        <value name="X">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>

    </block>
    
    <block type="zz_led_matrix" id="zz_led_matrix">
        <value name="MATRIX"> 
            <shadow type="matrix"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>
    </block>


    <block type="zz_led_matrix_ekrana_ciz" id="zz_led_matrix_ekrana_ciz">
        <value name="MATRIX"> 
            <shadow type="matrix"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>

        <value name="X">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>

        <value name="Y">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>

    </block>


    <block type="led_matrix_kapat">  </block>


    <block type="led_matrix_yazdır">
        <value name="X">
            <shadow type="text">
                <field name="TEXT">zbot</field>
            </shadow>
        </value>
    </block>

    
    <block type="led_matrix_kaydırarak_yazdır">
        <value name="X">
            <shadow type="text">
                <field name="TEXT">zbot</field>
            </shadow>
        </value>
    </block>    

    <block type="led_yak_x_y">
               <value name="X">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>

        <value name="Y">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>
    </block>    

    <block type="led_kapat_x_y">
        <value name="X">
        <shadow type="math_number">
            <field name="NUM">0</field>
        </shadow>
    </value>

    <value name="Y">
        <shadow type="math_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
</block>    


    <block type="goz_kırp_x_y">
        <value name="X">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>

        <value name="Y">
            <shadow type="math_number">
                <field name="NUM">0</field>
            </shadow>
        </value>
    </block>    

   
    `}
     ${categorySeparator}

   


 
</category>
    `;
};



const sound = function (isRealtimeMode,isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">

    <block type="zbot_ses_cal" id="zbot_ses_cal">
    </block>
  

    <block type="zbot_ses_seviyesi" id="zbot_ses_seviyesi">
    <value name="X">
        <shadow type="math_number">
            <field name="NUM">100</field>
        </shadow>
    </value>
    </block> 


    <block type="ses_seviyesi" id="ses_seviyesi">
    </block>


    <block type="zbot_ses_seviyesi_degistir" id="zbot_ses_seviyesi_degistir">
    <value name="X">
        <shadow type="math_number">
            <field name="NUM">-10</field>
        </shadow>
    </value>
    </block> 


    <block type="nota_cal" id="nota_cal">
        <value name="PIN">
            <shadow type="field_dropdown">
                <field name="PIN">B0</field>
            </shadow>
        </value>

        <value name="X">
            <shadow type="math_number">
                <field name="NUM">0.25</field>
            </shadow>
        </value>

    </block> 

    <block type="tum_sesleri_durdur" id="tum_sesleri_durdur">
    </block>

    ${isRealtimeMode ? 
        `
    <block type="my_playDrumForBeats" id="my_playDrumForBeats">
    <value name="X">
    <shadow type="math_float">
    <field name="NUM">0.25</field>
    </shadow>
    </value>
    <value name="PIN">
        <shadow type="field_dropdown">
        <field name="X">1</field>
        </shadow>
    </value>
</block> 

<block type="my_midiPlayDrumForBeats" id="my_midiPlayDrumForBeats">
    <value name="X">
    <shadow type="math_float">
    <field name="NUM">0.25</field>
    </shadow>
    </value>
</block> 


<block type="my_restForBeats" id="my_restForBeats">
    <value name="X">
        <shadow type="note">
        <field name="NOTE">60</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_float">
        <field name="NUM">0.25</field>
        </shadow>
    </value>
</block> 

<block type="my_setInstrument" id="my_setInstrument">

</block> 



<block type="my_setTempo" id="my_setTempo">
<value name="X">
<shadow type="math_number">
<field name="NUM">60</field>
</shadow>
</value>
</block> 


<block type="my_changeTempo" id="my_changeTempo">
<value name="X">
<shadow type="math_number">
<field name="NUM">20</field>
</shadow>
</value>
</block> 


<block type="my_getTempo" id="my_getTempo">

</block> 

` :null}

  
    ${categorySeparator}
    </category>
    `;
};





const motion = function (isInitialSetup, isStage) {
  
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion2" colour="#4C97FF" secondaryColour="#3373CC">
    ${isStage ? `
    ` : `
    
    

    <block type="left_right_clock_second">
           <value name="X">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>   
    </block>


    <block type="left_right_clock_power">
           <value name="X">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   
    </block>

        <block type="forward_second">
            <value name="power">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   

            <value name="Y">
                <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
            </value>   
        </block>

        <block type="backward_second">
            <value name="X">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   

            <value name="Y">
                <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
            </value>   
        </block>

        <block type="turnleft_until">
            <value name="X">
                <shadow type="math_angle">
                    <field name="NUM">90</field>
                </shadow>
            </value>   
        </block>



    <block type="turnright_until">
        <value name="X">
            <shadow type="math_angle">
                <field name="NUM">90</field>
            </shadow>
        </value>   
    </block>

        
        <block type="hareket_menu_git">
            <value name="SPEED">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>   
        </block>

        <block type="hareket_sol_sag_guc">
            <value name="X">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow type="math_number">
                    <field name="NUM">50</field>
                </shadow>
            </value>      
        </block>

        <block type="hareket_durdur">
        </block>

        <block type="hareket_cizgi_izleme">
       
        </block>
   
`}
        
        ${categorySeparator}
    </category>
    `;
};

const control = function (isInitialSetup, isStage, targetId, isRealtimeMode) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        ${blockSeparator}
        <block type="control_if"/>
        <block type="control_if_else"/>
        <block id="wait_until" type="control_wait_until"/>
        <block id="repeat_until" type="control_repeat_until"/>
        ${isRealtimeMode ? `
            ${blockSeparator}
            <block type="control_stop"/>
            ${blockSeparator}
            ${isStage ? `
                <block type="control_create_clone_of">
                    <value name="CLONE_OPTION">
                        <shadow type="control_create_clone_of_menu"/>
                    </value>
                </block>
                 <block type="control_start_as_clone"/>
                <block type="control_create_clone_of">
                    <value name="CLONE_OPTION">
                        <shadow type="control_create_clone_of_menu"/>
                    </value>
                </block>
                <block type="control_delete_this_clone"/>
            ` : `
               
            `}
        ` : null}
        ${categorySeparator}
    </category>
    `;
};

const uploadControl = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="jr_upload_control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="jr_upload_control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="jr_upload_forever" type="control_forever"/>
     
        ${blockSeparator}
        <block type="jr_upload_control_stop"/>
        ${blockSeparator}
       
        ${categorySeparator}
    </category>
    `;
};

const sensorler = function () {
    return `
    <category
        name="%{BKY_CATEGORY_SENSING}"
        id="Sensorler_CATEGORY"
        colour="#fc4e15"
        secondaryColour="#FF4D6A"
        >

        <block type="dugme_basildimi"/>

        <block type="cihaz_sallaniyormu"/>
        <block type="algilanan_renk"/>
        <block type="onundeki_engeller"/>

        <block type="ses_siddeti"/>
        <block type="sallanma_siddeti"/>
        <block type="pil_seviyesi"/>
        <block type="egim_acisi"/>

        <block type="yatis_acisi"/>
        

        
        <block type="start_motion_sensor"/>
        <block type="cihaz_egik_mi"/>
        <block type="egim_acisi_durumlu"/>
        <block type="x_y_z_acisi_durumlu"/>
        <block type="x_y_z_hiz_durumlu"/>
       
       
        <block type="disli_potansiyometre_degeri"/>

        <block type="read_line_new"/>

        <block type="ButtonPressedZbot"/>

        <block type="UltrasonikInitZbot"/>

        <block type="DistanceValueUltrasonikZbot"/>
        
        <block type="EnvironmentLightIntensityZbot"/>
      
        <block type="RedLightIntensityZbot"/>
        <block type="BlueLightIntensityZbot"/>
        <block type="GreenLightIntensityZbot"/>
        <block type="StartMotionSensorZbot"/>
        <block type="TiltDirectionZbot"/>


        <block type="soundIntensityZbot"/>

        
    </category>
    `;
};
const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate('SENSING_ASK_TEXT', 'What\'s your name?');
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#ff661a"  secondaryColour="#A9A9A9">
        ${isStage ? '' : `

        <block type="dugme_basildimi"/>

        <block type="cihaz_sallaniyormu"/>
        <block type="algilanan_renk"/>
        <block type="onundeki_engeller"/>
        <block type="cizgi_algilama_renk"/>

        
        <block type="ses_siddeti"/>
        <block type="sallanma_siddeti"/>
        <block type="ortam_isik_siddeti"/>

        <block type="pil_seviyesi"/>
        <block type="egim_acisi"/>

        <block type="yatis_acisi" id="yatis_acisi"/>

            ${blockSeparator}
        `}
      
        ${categorySeparator}
    </category>
    `;
};


const entertainment = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate('SENSING_ASK_TEXT', 'What\'s your name?');
    return `
    <category name="%{BKY_CATEGORY_ENTERTAINMENT}" id="entertainment" colour="#E04241" secondaryColour="#2E8EB8">
        ${isStage ? '' : `
        <block type="feeling"/>

        <block type="dance"/>

        <block type="songshow"/>
        <block type="lightshow"/>
        `}
      
        ${categorySeparator}
    </category>
    `;
};

const motion2 = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${isStage ? `
        <label text="${stageSelected}"></label>
        ` : `
        <block type="motion_movesteps">
            <value name="STEPS">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_turnright">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>
        <block type="motion_turnleft">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_goto">
            <value name="TO">
                <shadow type="motion_goto_menu">
                </shadow>
            </value>
        </block>
        <block type="motion_gotoxy">
            <value name="X">
                <shadow id="movex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="movey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="motion_glideto" id="motion_glideto">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="motion_glideto_menu">
                </shadow>
            </value>
        </block>
        <block type="motion_glidesecstoxy">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="X">
                <shadow id="glidex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="glidey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_pointindirection">
            <value name="DIRECTION">
                <shadow type="math_angle">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>
        <block type="motion_pointtowards">
            <value name="TOWARDS">
                <shadow type="motion_pointtowards_menu">
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_changexby">
            <value name="DX">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_setx">
            <value name="X">
                <shadow id="setx" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="motion_changeyby">
            <value name="DY">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_sety">
            <value name="Y">
                <shadow id="sety" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_ifonedgebounce"/>
        ${blockSeparator}
        <block type="motion_setrotationstyle"/>
        ${blockSeparator}
        <block id="${targetId}_xposition" type="motion_xposition"/>
        <block id="${targetId}_yposition" type="motion_yposition"/>
        <block id="${targetId}_direction" type="motion_direction"/>`}
        ${categorySeparator}
    </category>
    `;
};

/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?object} device - Full data of current selected deivce.
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?boolean} isRealtimeMode - Current program mode.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
 const getXML = function (
    isInitialSetup,
    isStage,
    targetId,
    isRealtimeMode,
    costumeName,
    backdropName,
    soundName
) {
    const gap = [categorySeparator];


    const motionXML = motion(isInitialSetup, isStage);
    const motion2XML = motion2(isInitialSetup, isStage);

    const lightsXML =  lights(isInitialSetup, isStage, targetId);
    const looksXML =  looks(isInitialSetup, isStage, targetId, costumeName, backdropName);
    const soundXML =  sound(isRealtimeMode,isInitialSetup, isStage, targetId, soundName);
    const eventsXML =  events(isInitialSetup, isStage, targetId);
    const eventsRealTimeXML =  eventsRealTime(isInitialSetup, isStage, targetId);
    const sensingXML = sensing(isInitialSetup, isStage, targetId);
    const controlXML =  control(isInitialSetup, isStage, targetId,isRealtimeMode);
    const sensingUploadXML = sensorler(isInitialSetup, isStage, targetId);
    const entertainmentXML =  entertainment(isInitialSetup, isStage, targetId);
    const variablesXML =variables(isInitialSetup, isStage, targetId);
    const myBlocksXML = myBlocks(isInitialSetup, isStage, targetId);
    const operatorsXML = operators(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    let baseToolboxXml = [];

    if (isRealtimeMode === true) {
        baseToolboxXml = [
            eventsRealTimeXML, gap,
            entertainmentXML, gap,
            motionXML, gap,
           

            lightsXML, gap,
            looksXML, gap,
            soundXML, gap,
            sensingXML, gap,
           
            controlXML, gap,
            operatorsXML,gap,
            variablesXML, gap,
            myBlocksXML
        ];
    } else {
        baseToolboxXml = [
            eventsXML, gap,
            entertainmentXML, gap,
            motionXML, gap,
            lightsXML, gap,
            looksXML, gap,
            soundXML, gap,
            sensingXML, gap,
           
            controlXML, gap,
            operatorsXML,gap,
            variablesXML, gap,
            myBlocksXML
        ];
    }

    return baseToolboxXml;
};


export default getXML;
