const Buffer = require('buffer').Buffer;

const Serialport = require('../../io/serialport');
const Base64Util = require('../../util/base64-util');
const BLE = require('../../io/ble');
const DeviceReadData = require('../../util/deviceDataRead');
const util = require('util');

const BLEUUID = {
    service: '6E400001B5A3F393E0A9E50E24DCCA9E',
    rxChar: '6E400002B5A3F393E0A9E50E24DCCA9E',
    txChar: '6E400003B5A3F393E0A9E50E24DCCA9E'
};

const MAX_CONSOLE_LENGTH = 200;

/**
 * Manage communication with a common peripheral over a OpenBlock Link client socket.
 */
class CommonPeripheralBle{

    /**
     * Construct a common communication object.
     * @param {Runtime} runtime - the OpenBlock runtime
     * @param {string} deviceId - the id of the peripheral
     * @param {string} originalDeviceId - the original id of the peripheral, like xxx_arduinoUno
     * @param {object} pnpidList - the pnp id of the peripheral
     * @param {object} serialConfig - the serial config of the peripheral
     * @param {object} diveceOpt - the device optione of the peripheral
     */
    constructor (runtime, deviceId, originalDeviceId, pnpidList, serialConfig, diveceOpt) {
        /**
         * The OpenBlock runtime used to trigger the green flag button.
         * @type {Runtime}
         * @private
         */
        this._runtime = runtime;
        this.pnpidList = pnpidList;
        this.serialConfig = serialConfig;
        this.diveceOpt = diveceOpt;

        /**
         * The serialport connection socket for reading/writing peripheral data.
         * @type {SERIALPORT}
         * @private
         */
        this._ble = null;
        this._serialport = null;
        this._runtime.registerPeripheralExtension(deviceId, this);
        this._runtime.setRealtimeBaudrate(this.serialConfig.baudRate);

        /**
         * The id of the peripheral this peripheral belongs to.
         */
        this._deviceId = deviceId;

        this._originalDeviceId = originalDeviceId;

        /**
        * Pending data list. If busy is set when send, the data will push into this array to
        * waitting to be sended.
        */
        this._pendingData = [];
        this._recivceBuffer = new Uint8Array(0);

        this.reset = this.reset.bind(this);
        this._onConnect = this._onConnect.bind(this);
        this._onMessage = this._onMessage.bind(this);

        this._readDeviceData = new DeviceReadData(this._runtime);

    }

    /**
     * Called by the runtime when user wants to upload code to a peripheral.
     * @param {string} code - the code want to upload.
     */
    upload (code) {
        const base64Str = Buffer.from(code).toString('base64');
        const utf8Str = Buffer.from(code).toString('utf-8');
       // this._ble.upload(base64Str, this.diveceOpt, 'base64');
        this._ble.upload(BLEUUID.service,BLEUUID.txChar,utf8Str,'utf-8',true);
    }

    /**
     * Called by the runtime when user wants to upload realtime firmware to a peripheral.
     */
    uploadFirmware () {
        console.log(this.diveceOpt);
        //alert("upload firmware");
        this._ble.uploadFirmware(BLEUUID.service,BLEUUID.txChar,'utf-8',true);
    }

    uploadComputerlessCoding () {
        console.log(this.diveceOpt);
        //alert("upload firmware");
        this._ble.uploadComputerlessCoding(BLEUUID.service,BLEUUID.txChar,'utf-8',true);
    }

    /**
     * Called by the runtime when user wants to scan for a peripheral.
     * @param {Array.<string>} pnpidList - the array of pnp id list
     * @param {bool} listAll - wether list all connectable device
    scan (pnpidList, listAll) {
        if (this._serialport) {
            this._serialport.disconnect();
        }
        this._serialport = new Serialport(this._runtime, this._originalDeviceId, {
            filters: {
                pnpid: listAll ? ['*'] : (pnpidList ? pnpidList : this.pnpidList)
            }
        }, this._onConnect, this.reset);
    }
     */

    scan () {
       // alert("scana girdi");
        if (this._ble) {
            this._ble.disconnect();
        }
        this._ble = new BLE(this._runtime, this._originalDeviceId, {
            filters: [
                {services: [BLEUUID.service]}
            ]
        }, this._onConnect, this.reset);
    }

    /**
     * Called by the runtime when user wants to connect to a certain peripheral.
     * @param {number} id - the id of the peripheral to connect to.
     * @param {?number} baudrate - the baudrate.
    connect (id, baudrate = null) {
        const config = Object.assign({}, this.serialConfig);
        if (baudrate) {
            config.baudRate = baudrate;
        }
        if (this._serialport) {
            this._serialport.connectPeripheral(id, {config: config});
        }
    }
     */

    connect (id) {
        if (this._ble) {
            this._runtime.setRunColorValueReset();
            this._ble.connectPeripheral(id);
        }
    }

    /**
     * Disconnect from the peripheral.
    disconnect () {
        if (this._serialport) {
            this._serialport.disconnect();
        }

        this.reset();
    }
     */

    disconnect () {
        if (this._ble) {

            this._ble.disconnect();
        }

        this.reset();
    }

    /**
     * Reset all the state and timeout/interval ids.
     */
    reset () {
        if (this._timeoutID) {
            window.clearTimeout(this._timeoutID);
            this._timeoutID = null;
        }
    }

    /**
     * Return true if connected to the peripheral.
     * @return {boolean} - whether the peripheral is connected.
    isConnected () {
        let connected = false;
        if (this._serialport) {
            connected = this._serialport.isConnected();
        }
        return connected;
    }
     */

    isConnected () {
        let connected = false;
        if (this._ble) {
            connected = this._ble.isConnected();
        }
        return connected;
    }

    /**
     * Set baudrate of the peripheral serialport.
     * @param {number} baudrate - the baudrate.
     */
    setBaudrate (baudrate) {
      //  this._serialport.setBaudrate(baudrate);
    }

    /**
     * Write data to the peripheral serialport.
     * @param {string} data - the data to write.
     */
    async write (data) {
        if (!this.isConnected()) return;
        DeviceReadData.setSendStep(true);
        await new Promise(resolve => setTimeout(resolve, 50));

        const utf8Str = Buffer.from(data).toString('utf-8');
        
       // this._serialport.write(base64Str, 'base64');
        var kalan = await this._ble.write(BLEUUID.service,BLEUUID.txChar,utf8Str,'utf-8',true);
        //this._ble.read(BLEUUID.service, BLEUUID.txChar, true,this._onMessage);
        //console.log("SLEEP CIKTI");
        await new Promise(resolve => setTimeout(resolve, 50));

        DeviceReadData.setSendStep(false);

    }

     sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      }

    /**
     * Send a message to the peripheral Serialport socket.
     * @param {Uint8Array} message - the message to write
     */
    send (message) {
        if (!this.isConnected()) return;

        const data = Base64Util.uint8ArrayToBase64(message);
        this._serialport.write(data, 'base64');
    }

    /**
     * Starts reading data from peripheral after serialport has connected to it.
     * @private
    _onConnect () {
        this._serialport.read(this._onMessage);
    }
     */

    async _onConnect () {
      //  this._ble.write(BLEUUID.service,BLEUUID.txChar,'emre','utf-8',true);
       // this._ble.read(BLEUUID.service, BLEUUID.rxChar, true);
    
      var code = 'print("emre bleden geldi")';
   //   const base64Str = Buffer.from(code).toString('base64');
    //  this._ble.upload(base64Str, this.diveceOpt, 'base64');
        await this._ble.read(BLEUUID.service, BLEUUID.txChar, true,this._onMessage);
        //this._runtime.startHats('game_when_robot_run');

        await this.upload("");
 
      //  await this.uploadFirmware();
        
       /*
        this._timeoutID = window.setTimeout(
            () => this._ble.handleDisconnectError(BLEDataStoppedError),
            5000
        );
        */
        
    }

    /**
     * Process the sensor data from the incoming serialport characteristic.
     * @param {object} base64 - the incoming serialport data.
     * @private
     */
    _onMessage (base64) {
     console.log("base 64 onmessagae COMMON BLEEEEEEEEEEEEEE ON MESSAGE");
        const consoleData = Buffer.from(base64, 'base64');
      //  console.log(base64);

        this._runtime.emit(this._runtime.constructor.PERIPHERAL_RECIVE_DATA, consoleData);

        if (this._recivceBuffer.byteLength + consoleData.byteLength >= MAX_CONSOLE_LENGTH) {
            this._recivceBuffer = this._recivceBuffer.slice(
                this._recivceBuffer.byteLength + consoleData.byteLength - MAX_CONSOLE_LENGTH);
               
        }
        this._recivceBuffer = this.appendBuffer(this._recivceBuffer, consoleData);
      //  var tt="<< read_all None None None None None 0 1 0 1 -0.005 2.069 9.44 0.013 -0.12 0.057 32.473 32 1 80 0 0 4.15 95.29 >>";
        var tt = base64;
        var stateData = [];
       // console.log(tt);

        // << RA 9 9 9 9 9 0 1 0 1 -0.16 2.08 9.94 0.01 -0.08 -0.06 34.48 32 0 0 77 -89 87 0 3 50 4.17 97.5 >>

        stateData += tt.split('\n');
        //console.log(stateData.length.toString());
       // console.log(stateData);
        //console.log(stateData.includes('<<'));
        //console.log(stateData.includes('>>'));

        /*
        dwbs =format(self.ıamstart, "read_all", self.Button.detect_x(), self.Button.detect_z(), self.Button.detect_circle(),self.Button.detect_square(), self.Button.detect_triangle(), self.Line.is_right_black(),self.Line.is_right_white(), self.Line.is_left_black(), self.Line.is_left_white(), acc_x, acc_y, acc_z,gyr_x, gyr_y, gyr_z, self.LSM6.read_temperature(), self.LSM6.detect_6d_orientation(),self.LSM6.detect_single_tap(), self.LSM6.shaking_intensity(), self.LSM6.direct_tilt(),self.LSM6.side_tilt(), self.Light.detect_intenstiy(), self.Barrier.detect_barrier(),self.Sound.detect_sound(), self.Plyr.show_level(), round(self.Battery.get_voltage(), 2),round(self.Battery.get_percent(), 2), self.ıamstop)
        */
        if (stateData.includes('<<') && stateData.includes(">>")){
            var deneme = stateData.substring(stateData.indexOf("<<") + 2);
           

            deneme = deneme.substring(0, deneme.indexOf(">>"));
            var lineArray= deneme.split(' ');
            if(lineArray[1]== 'errdouble'){
                // console.log(stateData);
                 //console.log(lineArray[25]);
                 //alert('double');
                 this._readDeviceData.setStepRun(false);
                 setTimeout(() => {
                    this._readDeviceData.setStepRun(false);
                 }, 100);
                 setTimeout(() => {
                    this._readDeviceData.setStepRun(false);
                 }, 600);
                 this._recivceBuffer = new Uint8Array(0);
             }
            if(lineArray[1]== 'devam'){
                 console.log("devam geldiiiiiiiiiiiiiiiiiiiii");
                 //console.log(lineArray[25]);
                 this._readDeviceData.setStepRun(false);
                 this._recivceBuffer = new Uint8Array(0);
             }
            if(lineArray[1]== 'RA'){
                console.log(stateData);
                //console.log(lineArray[25]);
                this._readDeviceData.setData(lineArray);
                this._recivceBuffer = new Uint8Array(0);
            }
        }
       
    }

    appendBuffer (arr1, arr2) {
        const arr = new Uint8Array(arr1.byteLength + arr2.byteLength);
        arr.set(arr1, 0);
        arr.set(arr2, arr1.byteLength);
        return arr;
    }
}

module.exports = CommonPeripheralBle;
