import classNames from "classnames";
import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "../../../mymodule/ztech-vm";
import url from "url";

import Modal from "../../containers/modal.jsx";
import { setjrMode, setNormalMode } from "../../reducers/jrchange";
import { setDestroyJr, setDestroyNormal } from "../../reducers/game-destroy";
import deviceChange, {
    setDeviceTrue,
    setDeviceFalse,
} from "../../reducers/device-change";

import { FormattedMessage } from "react-intl";
import styles from "./downloadZconnect.css";
import logo from "./ZconnectLogo.png";


import zcodelogo from "./logo.png";
import MyWorkspaceClass from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass";
const ReadDeviceData = require('../../../mymodule/ztech-vm/src/util/deviceDataRead');

class DownloadZconnectComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClose",
            "onAddBtnClickVertical",
            
        ]);
        this.state = {
            loaded: false,
        };
    }
    componentDidMount() {
       
    }
    componentDidUpdate(prevProps, prevState) {}

    handleClose() {

        this.props.onRequestClose();
    }

     onAddBtnClickVertical() {
       
    
    }

    

   
   

    render() {
        return (
            <Modal

                className={styles.modalContent}
                headerClassName={styles.header}
                contentLabel={this.props.title}

                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                <div className={classNames(styles.body)}
                >
                    <div className={classNames(styles.arka)}
                    onClick={() => {
                       
                        }}
                    >
                   
                      
                    </div>

                    <div>
                        <img
                            className={
                            styles.logo
                        }
                            src={logo}
                        />
                    </div>
                   
                    <div className={classNames(styles.flex22)}>
                           <div className={classNames(styles.text)}>
                                {this.props.text}
                               
                           </div>
                        
                        </div>
                   
                   <div  className={classNames(styles.flex)} onClick={() => {
                    const userAgent = window.navigator.userAgent,
                        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
                        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                    let os = null;

                    if (macosPlatforms.indexOf(platform) !== -1) {
                        os = 'macOS';
                    } else if (iosPlatforms.indexOf(platform) !== -1) {
                        os = 'iOS';
                    } else if (windowsPlatforms.indexOf(platform) !== -1) {
                        os = 'Windows';
                    } else if (/Android/.test(userAgent)) {
                        os = 'Android';
                    } else if (/Linux/.test(platform)) {
                        os = 'Linux';
                    }

                    console.log("platform: "+os);
                    
                        if(os == 'Windows'){
                          //  document.location.href = 'https://drive.google.com/u/0/uc?id=1MZ-Mprc4yUIR_x3LcLSPMhHfx4IHOWEp&export=download&confirm=t&uuid=7d630d01-8549-4022-93ed-dd86403d7386&at=AB6BwCCagwVP3VjLpU3O5xanC3dT:1702066201692';
                          
                            
                          //  var adr =   'https://edef8.pcloud.com/cfZyGfmhBZdDdVclZHKimZZvEIvykZ2ZZHB0ZZ6vuWuZTFZkVZRFZD0Zw0Z6XZmVZuFZyJZcJZVFZBJZiVZ8JZezen2KIuktFv5vpFGp1W2zys33nk/ZConnect%20Setup%201.0.0.exe';
                          //  a.href = adr;
                        //  window.location = adr;
                        
                          window.open(
                            'https://storage.googleapis.com/ztech-zconnect/ZConnect%20Setup%201.0.2.exe',
                                '_blank' 
                                );
                       
                        }else{
                            //var adr = 'https://zconnect.vercel.app/mac/ZConnect-1.0.0-mac.zip';
                            window.open(
                                'https://storage.googleapis.com/ztech-zconnect/mac/ZConnect-1.0.1-mac.zip',
                                '_blank' 
                                );
                        }
                      
                        

                    }}>
                   <button className={classNames(styles.button)}>{this.props.downloadtext}</button>

                   </div>

                </div>
            </Modal>
        );
    }
}

DownloadZconnectComponent.propTypes = {
  
    vm: PropTypes.instanceOf(VM).isRequired,
};

DownloadZconnectComponent.defaultProps = {
    autoClose: true,
    isUnloadble: false,
    filterable: true,
    showPlayButton: false,
};
const mapStateToProps = (state, ownProps) => {
    return {
        vm: state.scratchGui.vm,

        isjr: state.scratchGui.isjr,
        gameDestroy: state.scratchGui.destroyGame,
    };
};
const mapDispatchToProps = (dispatch) => ({
    
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(DownloadZconnectComponent);
