const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameMotionBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

var runtimefonk1= false;
var emre =0;
class GameMotionBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            hareket_speed:  this.hareket_speed,
            hareket_dur:  this.hareket_dur,
            hareket_ileri_sonsuz:  this.hareket_ileri_sonsuz,
            hareket_geri_sonsuz: this.hareket_geri_sonsuz,
            hareket_ileri:  this.hareket_ileri,
            hareket_geri: this.hareket_geri,
            hareket_saga_don: this.hareket_saga_don,
            hareket_sola_don: this.hareket_sola_don,
            click_Play: this.click_Play,
            bot_sound_volume: this.bot_sound_volume,
            hareket_cizgi_izleme:this.hareket_cizgi_izleme,
            //////DİKEY BASLANGIC
            left_right_clock_second:this.left_right_clock_second,
            left_right_clock_power:this.left_right_clock_power,
            forward_second:this.forward_second,
            backward_second:this.backward_second,
            turnleft_until:this.turnleft_until,
            turnright_until:this.turnright_until,
            hareket_menu_git:this.hareket_menu_git,
            hareket_sol_sag_guc:this.hareket_sol_sag_guc,
            hareket_durdur:this.hareket_durdur

        };
    }

    async hareket_ileri_sonsuz (args,util) {

        const steps = Cast.toNumber(args.SPEED);
        console.log('step ileri gelen: '+steps);
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
       var device = ConnectedDeviceClass.getdev();     
       await device.write(`<< 9 d 20 >>\n`);
      
       await currentClass.listForwordForeverPush();

     }

     async  hareket_geri_sonsuz (args,util) {

        const steps = Cast.toNumber(args.repeat);
        console.log('step hareket_geri gelen: '+steps);

        var currentClass =  Oyun.getClass();

        var device = ConnectedDeviceClass.getdev();     
        await device.write(`<< 9 d 21 >>\n`);
        await currentClass.listBackwardForeverPush();

    }


    async hareket_speed (args,util) {
        const steps = Cast.toNumber(args.VALUE);
        console.log('hız ileri gelen: '+steps);
        
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
 
     
       var device = ConnectedDeviceClass.getDevice();     
       
       if(steps == 1){
        await device.write("<< 9 d 17 1 >>\n");
       }
       else if(steps == 2){
        await device.write("<< 9 d 17 2 >>\n");
       }
       else if(steps == 3){
        await device.write("<< 9 d 17 3 >>\n");
       }
       else if(steps == 4){
        await device.write("<< 9 d 17 4 >>\n");
       }

     }
  
    

     async hareket_dur (args,util) {

        console.log('hız dur');
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
        await currentClass.moveStop();
  

       var device = ConnectedDeviceClass.getdev();     
       await device.write("<< 9 d 16  >>\n");
     }
   
     async hareket_ileri (args,util) {

       var steps = Cast.toNumber(args.SPEED);
       console.log('step ileri gelen: '+steps);
       var currentGame =  Oyun.getGame();
       var currentClass =  Oyun.getClass();

     //  var device = ConnectedDeviceClass.getDevice();
      // await device.write("<155>");
      var device = ConnectedDeviceClass.getDevice();     

       if(steps == 1){
        await device.write("<< 9 d 20 150 1 >>\n");

          await currentClass.downRightPush();
       }else{
        if(steps>40){
            steps=40;
        }
        for (let i = 0; i < steps; i++) {
            await device.write("<< 9 d 20 150 1 >>\n");
            await currentClass.downRightPush();

          }
       }
    }

    

   

    async  hareket_geri (args,util) {

        var steps = Cast.toNumber(args.repeat);
        console.log('step hareket_geri gelen: '+steps);

        var currentGame = Oyun.getGame();

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        if(steps == 1){
            await device.write("<< 9 d 21 150 1 >>\n");
            await currentClass.upLeftPush();
        }else{
            if(steps>40){
                steps=40;
            }
            for (let i = 0; i < steps; i++) {
                await device.write("<< 9 d 21 150 1 >>\n");
                await currentClass.upLeftPush();

              }
        }
    }

    async hareket_saga_don (args,util) {

        var steps = Cast.toNumber(args.repeat);
       console.log('step hareket_saga_don gelen: '+steps);

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        if(steps == 1){
            await device.write("<< 9 d 18 1 90 >>\n");

            await currentClass.downLeftPush();
        }else{
            if(steps>80){
                steps=80;
            }
            for (let i = 0; i < steps; i++) {
                await device.write("<< 9 d 18 1 90 >>\n");
                await currentClass.downLeftPush();
              }
        }
       
    }

    async hareket_sola_don (args,util) {
        var steps = Cast.toNumber(args.repeat);
        console.log('step hareket_sola_don gelen: '+steps);

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        if(steps == 1){
            await device.write("<< 9 d 18 0 90 >>\n");

            await currentClass.upRightPush();
        }else{
            if(steps>80){
                steps=80;
            }
            for (let i = 0; i < steps; i++) {
                await device.write("<< 9 d 18 0 90 >>\n");
                await currentClass.upRightPush();

              }
        }

    }
    
    async left_right_clock_second (args,util) {
        console.log(args);
        const direction = args.DIRECTION;
        const clock = args.CLOCK;
        const seconds = args.X;
        var data=1;
        var direct=1;

        console.log(direction);
        console.log(clock);
        console.log(seconds);
        var device = ConnectedDeviceClass.getdev();   
        if(clock=="clockwise"){
            data=0;
        }else{
            data=1;
        }
        if(direction=="left"){
            direct=0;
        }else{
            direct=1;
        }
        await device.write(`<< 9 d 24 ${direct} ${data} ${seconds} >>\n`);
    }

    async left_right_clock_power(args,util) {
        console.log(args);
        const direction = args.DIRECTION;
        const clock = args.CLOCK;
        const power = args.X;
        var data=1;
        var direct=1;

        var device = ConnectedDeviceClass.getDevice();   
        if(clock=="clockwise"){
            data=0;
        }else{
            data=1;
        }

        if(direction=="left"){
            direct=0;
        }else{
            direct=1;
        }

      
        await device.write(`<< 9 d 25 ${direct} ${data} ${power} >>\n`);
    }

    async forward_second(args,util) {
        console.log(args);
        var power = Cast.toNumber(args.power);
        const seconds = Cast.toNumber(args.Y);
        var device = ConnectedDeviceClass.getDevice();     
        if(power>100){
            power=100;
        }
      
        if(power< 0){
            power= Math.abs(power);

            if(power>100){
                power=100;
            }
            await device.write(`<< 9 d 27 ${power} ${seconds} >>\n`);
        }else{
            await device.write(`<< 9 d 26 ${power} ${seconds} >>\n`);
        }
    }

    async backward_second(args,util) {
        console.log(args);
        var power = Cast.toNumber(args.X);
        const seconds = Cast.toNumber(args.Y);
        var device = ConnectedDeviceClass.getDevice();     
        if(power>100){
            power=100;
        }
        if(power< 0){
            power= Math.abs(power);
            if(power>100){
                power=100;
            }
            await device.write(`<< 9 d 26 ${power} ${seconds} >>\n`);
        }else{
            await device.write(`<< 9 d 27 ${power} ${seconds} >>\n`);
        }
    }

    async turnleft_until(args,util) {
        console.log(args);
        const angle = Cast.toNumber(args.X);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d 18 0 ${angle} >>\n`);
    }

    async turnright_until(args,util) {
        console.log(args);
        const angle = Cast.toNumber(args.X);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d 18 1 ${angle} >>\n`);
    }

    async hareket_menu_git (args,util) {
        console.log(args);

        const direction = args.VALUE;
        var power = Cast.toNumber(args.SPEED);

        var device = ConnectedDeviceClass.getdev();   
        if(direction=="forward"){
            if(power>100){
                power=100;
            }
            if(power< 0){
                power= Math.abs(power);
                if(power>100){
                    power=100;
                }
                await device.write(`<< 9 d 27 ${power} >>\n`);
            }else{
                await device.write(`<< 9 d 26 ${power} >>\n`);
            }
        }else{
            if(power>100){
                power=100;
            }
            if(power< 0){
                power= Math.abs(power);
                if(power>100){
                    power=100;
                }
                await device.write(`<< 9 d 26 ${power} >>\n`);
            }else{
                await device.write(`<< 9 d 27 ${power} >>\n`);
            }
        }
     }

    async hareket_sol_sag_guc(args,util) {
        console.log(args);
        var left = Cast.toNumber(args.X);
        var right = Cast.toNumber(args.Y);
        var device = ConnectedDeviceClass.getDevice();   

        if(left< 0){
            left= Math.abs(left);
            if(left>100){
                left=100;
            }
            await device.write(`<< 9 d 28 0 ${right} ${left} >>\n`);
        }

        if(right< 0){
            right= Math.abs(right);
            if(right>100){
                right=100;
            } 
            await device.write(`<< 9 d 28 0 ${right} ${left} >>\n`);
        }

       
        if(left>100){
            left=100;
        }
        if(right>100){
            right=100;
        }  
        console.log(left);
        console.log(right);

        await device.write(`<< 9 d 28 1 ${right} ${left} >>\n`);
    }

    async hareket_durdur(args,util) {
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d 16  >>\n`);
    }


    async click_Play (args,util) {
       //console.log('event_whenflagclicked');
        var currentClass =  Oyun.getClass();
        currentClass.playPressed();

    }

    async bot_sound_volume (args,util) {
        console.log("bot_sound_volume 40 okuyor sensör");
        return 40;
 
     }

     async hareket_cizgi_izleme (args,util) {
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 18 d 130 >>\n`);
 
     }
     
   
}

module.exports = GameMotionBlocks;
