import React from "react";
import classNames from "classnames";
import styles from "../stage-modal.css";
import { FormattedMessage } from "react-intl";

const MainPage = ({ isjr, selected, handleSubMenu, changeStage, closeModal }) => {
    console.log("selected", selected)
    return (
        <div className={classNames(styles.container, styles["container"+selected+1], {
            [styles.isjrMenu]: isjr,
        })}>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    closeModal();
                    console.log("boş harita açılacak");
                    changeStage("oyun-alanı");
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {selected===0?  <FormattedMessage
                    defaultMessage="Free Map"
                    description="Kids menu title"
                    id="gui.game.modal.freemap"
                /> :`Alan ${(selected-1)*5+4}`}
                </p>
            </div>
            { isjr ? (
                <>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                  handleSubMenu("kids")
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {selected===0?
                    <FormattedMessage
                    defaultMessage="Kids Beginner"
                    description="Kids menu title"
                    id="gui.game.modal.kidsBeginner"
                /> : `Alan ${(selected-1)*5+5}`}
                </p>
            </div>
            <div
            className={classNames(styles.selectBtn, styles.menuItemDouble)}
            onClick={() => {
              handleSubMenu("ilerleme")
            }}
            >
            <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                defaultMessage="Kids Intermediate"
                description="Kids menu Intermediate title"
                id="gui.game.modal.kidsIntermediate"
                /> 
            </p>
        </div> 
        </>
        )
            :
                <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                  handleSubMenu("ilerleme")
                }}
                >
                <p className={classNames(styles.selectBtnText)}>
                    {selected===0?"İlerleme":`Alan ${(selected-1)*5+5}`}
                </p>
            </div> 
            }
            {!isjr&&<div
                className={classNames(styles.selectBtn, styles.menuItemTriple)}
                onClick={() => {
                    closeModal();
                    console.log("Alan 1 açılacak")
                    changeStage("first-map");
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {`Alan ${(selected-1)*5+6}`}
                </p>
            </div>}
            {!isjr&&<div
                className={classNames(styles.selectBtn, styles.menuItemTriple)}
                onClick={() => {
                    closeModal();
                    console.log("Alan 2 açılacak")
                    changeStage("water-map");
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {`Alan ${(selected-1)*5+7}`}
                </p>
            </div>}
            {!isjr&&<div
                className={classNames(styles.selectBtn, styles.menuItemTriple)}
                onClick={() => {
                    console.log("Alan 3 açılacak")
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    {`Alan ${(selected-1)*5+8}`}
                </p>
            </div>}
            {/* <p className={styles.title}>Sayfa {selected + 1}</p> */}
        </div>
    );
};

export default MainPage;
