const TRUE_NEW_PROJECT = 'scratch-gui/TRUE_NEW_PROJECT';
const FALSE_NEW_PROJECT = 'scratch-gui/FALSE_NEW_PROJECT';


const initialState = false;

const reducer = function (state, action) {
    

    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case TRUE_NEW_PROJECT:
        return true;
    case FALSE_NEW_PROJECT:
            return false;
    default:
        return state;
    }
};

const truenewProject = () => ({
    type: TRUE_NEW_PROJECT
});

const falsenewProject = () => ({
    type: FALSE_NEW_PROJECT
});


export {
    reducer as default,
    initialState as newProjectInitialState,
    truenewProject,
    falsenewProject
};
