const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameEntertainmentsBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameEntertainmentsBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            feeling:  this.feeling,
            dance:this.dance,
            songshow:this.songshow,
            lightshow:this.lightshow
        };
    }

    async feeling (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(data == "bilgin"){
            await device.write("<< 17 d 129 bilgin >>\n");
        }   
        if(data == "havali"){
            await device.write("<< 17 d 129 havali >>\n");
        }   
        if(data == "merakli"){
            await device.write("<< 17 d 129 merakli >>\n");
        }   
        if(data == "mutlu"){
            await device.write("<< 17 d 129 mutlu >>\n");
        }   
        if(data == "sevgi"){
            await device.write("<< 17 d 129 sevgi >>\n");
        }   
        if(data == "uykulu"){
            await device.write("<< 17 d 129 uykulu >>\n");
        }   
        if(data == "kizgin"){
            await device.write("<< 17 d 129 kizgin >>\n");
        }   
        if(data == "korku"){
            await device.write("<< 17 d 129 korku >>\n");
        } 
        if(data == "neseli"){
            await device.write("<< 17 d 129 neseli >>\n");
        } if(data == "saskin"){
            await device.write("<< 17 d 129 saskin >>\n");
        } if(data == "utangac"){
            await device.write("<< 17 d 129 utangac >>\n");
        } 
        if(data == "üzgün"){
            await device.write("<< 17 d 129 üzgün >>\n");
        }   
       
     }

     async dance (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  

        if(data == "tango"){
            await device.write("<< 15 d 62 >>\n");
        }   
        if(data == "samba"){
            await device.write("<< 15 d 63 >>\n");
        }  
        if(data == "hip_hop"){
            await device.write("<< 15 d 64 >>\n");
        }   
        if(data == "slow"){
            await device.write("<< 15 d 65 >>\n");
        } 
       
     }

     async songshow (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d 48 hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d 48 at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d 48 ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d 48 bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d 48 hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d 48 oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d 48 puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d 48 miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d 48 siren >>\n");
        }
        
     }

     async lightshow (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(data == "rainbow"){
            await device.write("<< 16 d 61 >>\n");
        }   
        if(data == "northern_lights"){
            await device.write("<< 16 d 60 >>\n");
        }  
        if(data == "comet"){
            await device.write("<< 16 d 59 >>\n");
        }   
        if(data == "meteor_shower"){
            await device.write("<< 16 d 55 >>\n");
        }   
        if(data == "police_car"){
            await device.write("<< 16 d 58 >>\n");
        }   
        if(data == "traffic_light"){
            await device.write("<< 16 d 57 >>\n");
        }  
        if(data == "show_light"){
            await device.write("<< 16 d 56 >>\n");
        }   
        if(data == "camp_fire"){
            await device.write("<< 16 d 54 3 >>\n");
        }   
        
       
       
     }
  
   
}

module.exports = GameEntertainmentsBlocks;
