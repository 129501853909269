import classNames from "classnames";
import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import React, { Component } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "../../../mymodule/ztech-vm";
import url from "url";
import closeIcon from '../connection-modal/icons/close.svg';

import Modal from "../../containers/modal.jsx";
import { setjrMode, setNormalMode } from "../../reducers/jrchange";
import { setDestroyJr, setDestroyNormal } from "../../reducers/game-destroy";
import deviceChange, {
    setDeviceTrue,
    setDeviceFalse,
} from "../../reducers/device-change";

import { FormattedMessage } from "react-intl";
import styles from "./feedback.css";
import { useFetch } from "../../../mymodule/ztech-vm/src/util/firebase.js";

/*
import storage from './firebase';
*/


class FeedbackModalComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClose",
            "onAddBtnClickVertical",
            
        ]);
        this.state = {
            image: '',
            nameValue:'',
            mailValue:'',
            feedbackValue:'',
            isLoading:false

        };
    }
    componentDidMount() {
       
    }
    componentDidUpdate(prevProps, prevState) {}

    handleClose() {

        this.props.onRequestClose();
    }

     onAddBtnClickVertical() {
       
    
    }

    
    upload ()  {
        if (image == null)
            return;
        storage.ref(`/images/${image.name}`).put(this.state.image);
        
    }
   
    updateNameValue(evt) {
        const val = evt.target.value;
        
        this.setState({
            nameValue: val
        });
      }
   
    updateMailValue(evt) {
        const val = evt.target.value;
        
        this.setState({
            mailValue: val
        });
    }

    updateFeedbackValue(evt) {
        const val = evt.target.value;
        
        this.setState({
            feedbackValue: val
        });
    }



    render() {
        return (
            <Modal

                className={styles.modalContent}
                headerClassName={styles.header}
                contentLabel={this.props.title}

                id="feedbackModal"
                onRequestClose={this.handleClose}
            >
            {this.state.isLoading ? 
            
                <div className={classNames(styles.body)}
                >
                  <div className={classNames(styles.formCenter)}>
                  
            
          
           
                   <button  className={classNames(styles.button)} onClick={async ()=>{
                   
                   }} >

                        {this.props.sendLoadingText}
                    
                    </button>

                    <br></br>
                    <ClipLoader
                            color="#000000"
                            loading={true}
                        
                            size={80}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                    />

                   </div>
                </div> 
                
                :


                <div className={classNames(styles.body)}
                >
                  <div className={classNames(styles.formCenter)}>
                  <input className={classNames(styles.input)} value={this.state.nameValue}  onChange={evt => this.updateNameValue(evt)} type="text" name="name"  placeholder={this.props.name} />
                    <input  className={classNames(styles.input)} value={this.state.mailValue}  onChange={evt => this.updateMailValue(evt)}  type="email" name="name"  placeholder={this.props.mail} multiple={true} height="300"/>
                    <textarea  className={classNames(styles.textarea)} placeholder={this.props.feedback} value={this.state.feedbackValue}  onChange={evt => this.updateFeedbackValue(evt)} name="Text1"  rows="8" id="textarea"></textarea>
                    <center>
                <input className={classNames(styles.fileselect)} id="selectedFile" type="file" onChange={(e) => 
                { 
                    this.setState({image: e.target.files[0]});
                }} />
               
            </center>
            {
                this.state.image != '' ? 
                    <div className={classNames(styles.divimage)}>
                        <p className={classNames(styles.p1)} >{this.state.image.name}</p>
                    <img   
                    onClick={()=>{
                        this.setState({image: ''});
                    }}
                    className={classNames(styles.closeIcon)} 
                        src={closeIcon}
                    />
                    </div> :
                   <button  className={classNames(styles.Uploadbutton)} onClick={async ()=>{
                    document.getElementById('selectedFile').click();
            }} > {this.props.uploadButton} </button>
            }
          
            <br></br>
            <br></br>
                   <button  className={classNames(styles.button)} onClick={async ()=>{
                    this.setState({
                        isLoading:true
                    });
                        await useFetch(this.state.nameValue,this.state.mailValue,this.state.feedbackValue,this.state.image);
                        
                        this.setState({image: '',nameValue:'', mailValue:'',feedbackValue:''});

                    this.setState({
                        isLoading:false
                    });

                   }} >

                        {this.props.sendButton}
                    
                    </button>

                   </div>
                </div>
            }
               
            </Modal>
        );
    }
}

FeedbackModalComponent.propTypes = {
  
    vm: PropTypes.instanceOf(VM).isRequired,
};

FeedbackModalComponent.defaultProps = {
    autoClose: true,
    isUnloadble: false,
    filterable: true,
    showPlayButton: false,
};
const mapStateToProps = (state, ownProps) => {
    return {
        vm: state.scratchGui.vm,
        
        isjr: state.scratchGui.isjr,
        gameDestroy: state.scratchGui.destroyGame,
    };
};
const mapDispatchToProps = (dispatch) => ({
    
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(FeedbackModalComponent);
